import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 4",
    id: "WB5-2024-U4-P29-E1",
    // audio: "Audios/Page59/Track 086.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page29/Key/E1answerKey.png",
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "dash",
    },
    inputSize: 100,
    titleQuestion: [
      {
        num: "1",
        title: "Circle.",
        color: "#2d408e",
        left: -40,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 5px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
          borderRadius: "50%",
        },
        selectWordStyle: {
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "was / were",
          "was / were",
          "wasn't / weren't",
          "was / were",
          "was / were",
          "wasn't / weren't",
        ],
        answers: ["0-0", "1-4", "2-4", "3-0", "4-4", "5-0"],
        initialValue: ["0-0"],
      },
      Layout: `
      <div style='display:flex; flex-direction:column; gap:5px'>
        <span><b>1. </b>There <input id=0 type='Circle'/> a hotel in our town one hundred years ago.</span>
        <span><b>2. </b>There <input id=1 type='Circle'/> trolleys in our town one hundred years ago.</span>
        <span><b>3. </b>There <input id=2 type='Circle'/> any buses in our town one hundred years ago.</span>
        <span><b>4. </b>There <input id=3 type='Circle'/> a park in our town one hundred years ago.</span>
        <span><b>5. </b>There <input id=4 type='Circle'/> lots of bikes in our town one hundred years ago.</span>
        <span><b>6. </b>There <input id=5 type='Circle'/> a museum in our town one hundred years ago.</span>
      </div>
      `,
    },
  },
  2: {
    id: "WB5-2024-U4-P29-E2",
    // audio: "Audios/03-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page29/Key/E2answerKey.png",
    stylesTextInput: {
      width: 200,
    },
    textAlign: "center",
    titleQuestion: [
      {
        num: "2",
        title: "Write was, were, wasn't, or weren't.",
        color: "#2d408e",
        left: 50,
      },
    ],
    component: T6,
    questions: [
      {
        title: `
        <img src='img/FriendsPlus/Page29/E2/1.jpg' style='width: 25cm'/>
          <div style='width: 25cm; display: flex; flex-direction: column; gap: 5px'>
            <span><b>1. </b>There <u style='color:gray; text-underline-offset: 5px'>&nbspweren't&nbsp</u> any books at seven o'clock.</span>
            <span><b>2. </b>There # some flowers at ten o'clock.</span>
            <span><b>3. </b>There # a camera at seven o'clock.</span>
            <span><b>4. </b>There # a computer at ten o'clock.</span>
            <span><b>5. </b>There # some books at ten o'clock.</span>
            <span><b>6. </b>There # any pens at seven o'clock.</span>
            <span><b>7. </b>There # a banana at ten o'clock.</span>
          </div>        
        `,
        answer: ["were", "wasn't", "was", "were", "weren't", "was"],
      },
    ],
  },
};

export default json;
