import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 12",
    id: "WB5-2024-U12-P82-E1",
    exerciseKey: "img/FriendsPlus/Page82/Key/E1answerKey.png",
    // audio: "Audios/33-audio.mp3",
    video: "",
    inputSize: 200,
    maxLength: 30,
    textAlign: "center",
    titleQuestion: [
      {
        num: "1",
        title: "Read and write.",
        color: "#203c8f",
        left: 55,
      },
    ],
    styleHint: {
      border: "2px solid",
      justifyContent: "center",
      paddding: "0 10px",
      paddingBlock: "2px",
      borderColor: "#253f8e",
      color: "#57585a",
      marginLeft: -30,
      zIndex: 1,
      position: "relative",
      styleElementHintBox: {
        margin: "0px 24px",
        fontWeight: "normal",
      },
    },
    hintBox: [
      {
        src: [
          "are",
          "<span class='strikediag'>do</span>",
          "is",
          "going",
          "not",
          "they",
          "to",
          "watch",
        ],
        width: "max-content",
        inline: true,
      },
    ],
    component: T6,
    // hideBtnFooter: true
    // stylesTextInput: {
    //   background: "none",
    //   border: "none",
    //   width: 30,
    //   height: 30,
    //   padding: 5,
    // },
    questions: [
      {
        InputRong: true,
        title: `
        <hintbox id=0></hintbox>
        <div style='margin-top: 20px; width: 25cm; display: flex; flex-direction: column; gap: 10px; position: relative'>
          <span><b>1. A: </b>What are you going to <sup>1</sup><u style='color:gray; text-underline-offset: 5px'>&nbspdo&nbsp</u> this summer?</span>
          <span style='margin-left: 25px'><b>B: </b>I'm <sup>2</sup># to go to Cambodia on vacation.</span>
          <span><b>2. A: </b><sup>3</sup># you going to study tonight?</span>
          <span style='margin-left: 25px'><b>B: </b>No, I'm <sup>4</sup># .</span>
          <span><b>3. A: </b>What <sup>5</sup># Mom going to watch?</span>
          <span style='margin-left: 25px'><b>B: </b>She's going to <sup>6</sup># her favorite TV show.</span>
          <span><b>4. A: </b>Are they going <sup>7</sup># play soccer on Saturday?</span>
          <span style='margin-left: 25px'><b>B: </b>Yes, <sup>8</sup># are.</span>
          <img src='img/FriendsPlus/Page82/E1/1.png' style='position: absolute; width: 9.5cm; top: -130px; right: -50px'>
        </div>
        `,
        answer: ["going", "Are", "not", "is", "watch", "to", "they"],
      },
    ],
  },
  2: {
    unit: "Unit 12",
    id: "WB5-2024-U12-P82-E2",
    exerciseKey: "img/FriendsPlus/Page82/Key/E2answerKey.png",
    // audio: "Audios/33-audio.mp3",
    video: "",
    inputSize: 200,
    maxLength: 100,
    // textAlign: "center",
    titleQuestion: [
      {
        num: "2",
        title: "Look, read, and write.",
        color: "#203c8f",
        left: 55,
      },
    ],
    // styleHint: {
    //   border: "2px solid",
    //   justifyContent: "center",
    //   paddding: "0 10px",
    //   paddingBlock: "2px",
    //   borderColor: "#253f8e",
    //   color: "#57585a",
    //   marginLeft: -30,
    //   zIndex: 1,
    //   position: "relative",
    //   styleElementHintBox: {
    //     margin: "0px 24px",
    //     fontWeight: "normal",
    //   },
    // },
    // hintBox: [
    //   {
    //     src: [
    //       "are",
    //       "<span class='strikediag'>do</span>",
    //       "is",
    //       "going",
    //       "not",
    //       "they",
    //       "to",
    //       "watch",
    //     ],
    //     width: "max-content",
    //     inline: true,
    //   },
    // ],
    component: T6,
    // hideBtnFooter: true
    // stylesTextInput: {
    //   background: "none",
    //   border: "none",
    //   width: 30,
    //   height: 30,
    //   padding: 5,
    // },
    questions: [
      {
        InputRong: true,
        title: `
        <div style='width: 25cm; display: flex; flex-direction: column; gap: 10px; position: relative'>
          <span>What are you going to do?</span>
          <span><b>1. </b>(<b>✓</b>) watch TV <u style='color:gray; text-underline-offset: 5px'>&nbspI'm going to watch TV.&nbsp</u></span>
          <span><b>2. </b>(<b>✗</b>) do my homework <input width='667px'/></span>
          <span><b>3. </b>(<b>✗</b>) go to school <input width='728px'/></span>
          <span><b>4. </b>(<b>✓</b>) play outside <input width='729px'/></span>
          <span><b>5. </b>(<b>✓</b>) ride my bike <input width='727px'/></span>
        </div>
        `,
        answer: [
          " I'm not going to do my homework.",
          "I'm not going to go to school.",
          "I'm going to play outside.",
          "I'm going to ride my bike.",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 12",
    id: "WB5-2024-U12-P82-E3",
    exerciseKey: "img/FriendsPlus/Page82/Key/E3answerKey.png",
    // audio: "Audios/33-audio.mp3",
    video: "",
    inputSize: 200,
    maxLength: 100,
    // textAlign: "center",
    titleQuestion: [
      {
        num: "3",
        title: "Read and write answers for you.",
        color: "#203c8f",
        left: 55,
      },
    ],
    // styleHint: {
    //   border: "2px solid",
    //   justifyContent: "center",
    //   paddding: "0 10px",
    //   paddingBlock: "2px",
    //   borderColor: "#253f8e",
    //   color: "#57585a",
    //   marginLeft: -30,
    //   zIndex: 1,
    //   position: "relative",
    //   styleElementHintBox: {
    //     margin: "0px 24px",
    //     fontWeight: "normal",
    //   },
    // },
    // hintBox: [
    //   {
    //     src: [
    //       "are",
    //       "<span class='strikediag'>do</span>",
    //       "is",
    //       "going",
    //       "not",
    //       "they",
    //       "to",
    //       "watch",
    //     ],
    //     width: "max-content",
    //     inline: true,
    //   },
    // ],
    component: T6,
    hideBtnFooter: true,
    stylesTextInput: {
      background: "none",
      marginLeft: 25,
      width: "24cm",
    },
    questions: [
      {
        InputRong: true,
        title: `
        <div style='width: 25cm; display: flex; flex-direction: column; gap: 10px; position: relative'>
          <span><b>1. </b>What are you going to do this summer?</span> #
          <span><b>2. </b>Are you going to watch a movie on the weekend?</span> #
          <span><b>3. </b>What are your parents going to do on Saturday?</span> #
          <span><b>4. </b>Are your parents going to play video games tomorrow?</span> #
        </div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
