import { Button, Modal } from "antd";
import React from "react";

export default function InstructionModal({
  btnText,
  btnStyle,
  title,
  children,
}) {
  // const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    Modal.info({
      title: <h3>{title || "Instructions"}</h3>,
      content: children,
      centered: true,
      zIndex: 1100,
      width: "max-content",
      style: { maxWidth: "80%" },
      // onOk: handleOk,
    });
  };

  // const handleOk = () => {
  //   setIsModalOpen(false);
  // };

  return (
    <>
      <Button
        className="my-auto ml-3 d-inline-block"
        type="primary"
        onClick={showModal}
        style={{
          ...btnStyle,
          backgroundColor: "#2dce89",
          borderColor: "#2dce89",
          fontWeight: "bold",
          transform: "translateY(-5px)",
        }}
      >
        {btnText || "Instructions"}
      </Button>
      {/* <Modal
        title="Basic Modal"
        open={isModalOpen}
        onOk={handleOk}
        zIndex={1100}
        onCancel={handleOk}
      >
        {children}
      </Modal> */}
    </>
  );
}
