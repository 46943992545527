import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import Select_Image from "../../components/ExcerciseTypes/Select/Select_Image";

const json = {
  1: {
    unit: "Unit 9",
    id: "WB5-2024-U9-P63-E1",
    video: "",
    question: [],
    titleQuestion: [
      {
        num: "1",
        title: "Draw dots.",
        color: "#203c8f",
        left: -30,
      },
    ],
    component: Select_Image,
    exerciseKey: "img/FriendsPlus/Page63/Key/E1answerKey.png",

    selectStyle: {
      height: 35,
      border: "none",
      color: "transparent",
    },
    selectBorder: false,
    selectAllowClear: true,
    selectOnlyChangeTrueColor: true,
    styleImgSelect: [
      { height: 32, width: 35 },
      { height: 32, width: 35 },
      { height: 32, width: 35 },
      { height: 32, width: 35 },
      { height: 32, width: 35 },
      { height: 32, width: 35 },
      { height: 32, width: 35 },
      { height: 32, width: 35 },
      { height: 32, width: 35 },
      { height: 32, width: 35 },
      { height: 32, width: 35 },
      { height: 32, width: 35 },
      { height: 32, width: 35 },
      { height: 32, width: 35 },
      { height: 32, width: 35 },
      { height: 32, width: 35 },
      { height: 32, width: 35 },
    ],
    selectGroupOption: [
      [`img/FriendsPlus/Page63/E1/z1.jpg`, `img/FriendsPlus/Page63/E1/z2.jpg`],
      [`img/FriendsPlus/Page63/E1/z1.jpg`, `img/FriendsPlus/Page63/E1/z2.jpg`],
      [`img/FriendsPlus/Page63/E1/z1.jpg`, `img/FriendsPlus/Page63/E1/z2.jpg`],
      [`img/FriendsPlus/Page63/E1/z1.jpg`, `img/FriendsPlus/Page63/E1/z2.jpg`],
      [`img/FriendsPlus/Page63/E1/z1.jpg`, `img/FriendsPlus/Page63/E1/z2.jpg`],
      [`img/FriendsPlus/Page63/E1/z1.jpg`, `img/FriendsPlus/Page63/E1/z2.jpg`],
      [`img/FriendsPlus/Page63/E1/z1.jpg`, `img/FriendsPlus/Page63/E1/z2.jpg`],
      [`img/FriendsPlus/Page63/E1/z1.jpg`, `img/FriendsPlus/Page63/E1/z2.jpg`],
      [`img/FriendsPlus/Page63/E1/z1.jpg`, `img/FriendsPlus/Page63/E1/z2.jpg`],
      [`img/FriendsPlus/Page63/E1/z1.jpg`, `img/FriendsPlus/Page63/E1/z2.jpg`],
      [`img/FriendsPlus/Page63/E1/z1.jpg`, `img/FriendsPlus/Page63/E1/z2.jpg`],
      [`img/FriendsPlus/Page63/E1/z1.jpg`, `img/FriendsPlus/Page63/E1/z2.jpg`],
      [`img/FriendsPlus/Page63/E1/z1.jpg`, `img/FriendsPlus/Page63/E1/z2.jpg`],
      [`img/FriendsPlus/Page63/E1/z1.jpg`, `img/FriendsPlus/Page63/E1/z2.jpg`],
      [`img/FriendsPlus/Page63/E1/z1.jpg`, `img/FriendsPlus/Page63/E1/z2.jpg`],
      [`img/FriendsPlus/Page63/E1/z1.jpg`, `img/FriendsPlus/Page63/E1/z2.jpg`],
      [`img/FriendsPlus/Page63/E1/z1.jpg`, `img/FriendsPlus/Page63/E1/z2.jpg`],
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display: grid; grid-template-columns: 1fr 1fr; grid-gap: 10px 50px; width: 25cm; font-size: 30px'>
            <span style='position: relative; margin-top: 40px'>1. Walk slowly.
              <div style='position: absolute; top:-40px; left: 45px'><select id='0' group='0' width=35 height=35></div>
              <div style='position: absolute; top:-40px; left: 120px'><select id='1' group='1' width=35 height=35></div>
              <div style='position: absolute; top:-40px; left: 165px'><select id='2' group='2' width=35 height=35></div>
            </span>
            <span style='position: relative; margin-top: 40px'>2. Swim quickly.
              <div style='position: absolute; top:-40px; left: 55px'><select id='3' group='3' width=35 height=35></select></div>
              <div style='position: absolute; top:-40px; left: 130px'><select id='4' group='4' width=35 height=35></select></div>
              <div style='position: absolute; top:-40px; left: 185px'><select id='5' group='5' width=35 height=35></select></div>
            </span>
            <span style='position: relative; margin-top: 40px'>3. Run fast.
              <div style='position: absolute; top:-40px; left: 45px'><select id='6' group='6' width=35 height=35></select></div>
              <div style='position: absolute; top:-40px; left: 105px'><select id='7' group='7' width=35 height=35></select></div>
            </span>
            <span style='position: relative; margin-top: 40px'>4. Talk quietly.
              <div style='position: absolute; top:-40px; left: 45px'><select id='8' group='8' width=35 height=35></select></div>
              <div style='position: absolute; top:-40px; left: 115px'><select id='9' group='9' width=35 height=35></select></div>
              <div style='position: absolute; top:-40px; left: 165px'><select id='10' group='10' width=35 height=35></select></div>
            </span>
            <span style='position: relative; margin-top: 40px'>5. Sing loudly.
              <div style='position: absolute; top:-40px; left: 45px'><select id='11' group='11' width=35 height=35></select></div>
              <div style='position: absolute; top:-40px; left: 110px'><select id='12' group='12' width=35 height=35></select></div>
              <div style='position: absolute; top:-40px; left: 160px'><select id='13' group='13' width=35 height=35></select></div>
            </span>
            <span style='position: relative; margin-top: 40px'>6. Eat politely.
              <div style='position: absolute; top:-40px; left: 40px'><select id='14' group='14' width=35 height=35></select></div>
              <div style='position: absolute; top:-40px; left: 110px'><select id='15' group='15' width=35 height=35></select></div>
              <div style='position: absolute; top:-40px; left: 160px'><select id='16' group='16' width=35 height=35></select></div>
            </span>
          </div>
        `,
        answer: [
          `img/FriendsPlus/Page63/E1/z2.jpg`,
          `img/FriendsPlus/Page63/E1/z1.jpg`,
          `img/FriendsPlus/Page63/E1/z2.jpg`,
          `img/FriendsPlus/Page63/E1/z2.jpg`,
          `img/FriendsPlus/Page63/E1/z1.jpg`,
          `img/FriendsPlus/Page63/E1/z2.jpg`,
          `img/FriendsPlus/Page63/E1/z2.jpg`,
          `img/FriendsPlus/Page63/E1/z1.jpg`,
          `img/FriendsPlus/Page63/E1/z2.jpg`,
          `img/FriendsPlus/Page63/E1/z1.jpg`,
          `img/FriendsPlus/Page63/E1/z2.jpg`,
          `img/FriendsPlus/Page63/E1/z2.jpg`,
          `img/FriendsPlus/Page63/E1/z1.jpg`,
          `img/FriendsPlus/Page63/E1/z2.jpg`,
          `img/FriendsPlus/Page63/E1/z2.jpg`,
          `img/FriendsPlus/Page63/E1/z1.jpg`,
          `img/FriendsPlus/Page63/E1/z2.jpg`,
        ],
        initialValue: [
          `img/FriendsPlus/Page63/E1/z2.jpg`,
          `img/FriendsPlus/Page63/E1/z1.jpg`,
          `img/FriendsPlus/Page63/E1/z2.jpg`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 9",
    id: "WB5-2024-U9-P63-E2",
    exerciseKey: "img/FriendsPlus/Page63/Key/E2answerKey.png",
    audio: "Audios/27-audio.mp3",
    video: "",
    component: Circle_Write,
    // recorder: true,
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and underline the words that sound stronger. <span style='color: white'><headphone name='&nbsp 27' typeimg=sound src='Audios/27-tieude.mp3'></headphone></span>",
        color: "#203c8f",
      },
    ],
    styleContent: {
      width: "90%",
      marginLeft: 50,
    },
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        colorLineTo: "gray",
        // newPointDot: true,
        // path: true,
        // notOverlapse: true,
        circlePadding: "5 1",
        margin: "5px 2px",
        // circleStringArray: ["modelsalivescreambonesdinosaursroarscarydead"],
        boxMatch: [],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        circleInput: true,
        inputStyle: {
          width: 457,
          paddingBottom: 3,
          fontSize: 23,
          borderBottom: "none",
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        newSplit: true,
        initialWordStyle: {
          padding: "1px 3px",
          border: "none",
          background: "none",
          fontSize: 23,
          // fontWeight: "bold",
        },
        selectWordStyle: {
          color: "black",
          borderBottom: "2px solid #00aeef",
        },
        // limitSelect: 1,
        listWords: [
          "Monkeys run quickly",
          "And shout loudly in the trees",
          "Crocodiles swim quietly",
          "And wait politely for lunch",
          "They eat loudly",
          "SNAP",
        ],
        answers: ["5-0", "0-0", "0-2", "1-2", "2-0", "2-2", "3-2", "4-2"],
        initialValue: ["0-0", "0-2"],
      },
      Layout: `
        <div style='position: relative'>
          <img style='width: 25cm' src='img/FriendsPlus/Page63/E2/1.jpg'/>
          <div style='position: absolute; left: 20px; top: 20px; display: flex; flex-direction: column; gap: 7px'>
            <span><input id=0 type='Circle'/>,</span>
            <span><input id=1 type='Circle'/>.</span>
            <span><input id=2 type='Circle'/>.</span>
            <span><input id=3 type='Circle'/>.</span>
            <span><input id=4 type='Circle'/>.</span>
            <span><input id=5 type='Circle'/>!</span>
          </div>
        </div>
        `,
    },
  },
  3: {
    unit: "Unit 9",
    id: "WB5-2024-U9-P63-E3",
    exerciseKey: "img/FriendsPlus/Page63/Key/E3answerKey.png",
    // audio: "Audios/27-audio.mp3",
    video: "",
    component: Circle_Write,
    // recorder: true,
    titleQuestion: [
      {
        num: "3",
        title: "Say the sentences. Underline the stronger sounds.",
        color: "#203c8f",
      },
    ],
    styleContent: {
      width: "90%",
      marginLeft: 50,
    },
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        colorLineTo: "gray",
        // newPointDot: true,
        // path: true,
        // notOverlapse: true,
        circlePadding: "5 1",
        margin: "5px 2px",
        // circleStringArray: ["modelsalivescreambonesdinosaursroarscarydead"],
        boxMatch: [],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        circleInput: true,
        inputStyle: {
          width: 457,
          paddingBottom: 3,
          fontSize: 23,
          borderBottom: "none",
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        // newSplit: true,
        initialWordStyle: {
          padding: "1px 0px",
          border: "none",
          background: "none",
          fontSize: 23,
          // fontWeight: "bold",
        },
        selectWordStyle: {
          color: "black",
          borderBottom: "2px solid #00aeef",
        },
        limitSelect: 1,
        listWords: [
          "I eat my dinner slow|ly",
          "I speak polite|ly to my teacher",
          "I do my homework neat|ly",
          "I don’t speak loud|ly in the library",
          "I talk quiet|ly in class",
          "I go to school quick|ly",
        ],
        answers: ["0-8", "1-4", "2-8", "3-6", "4-4", "5-8"],
        initialValue: ["0-8"],
      },
      Layout: `
          <div style='display: grid; grid-template-columns: 1fr 1fr; grid-gap: 5px 50px'>
            <span><b>1. </b><input id=0 type='Circle'/>.</span>
            <span><b>4. </b><input id=3 type='Circle'/>.</span>
            <span><b>2. </b><input id=1 type='Circle'/>.</span>
            <span><b>5. </b><input id=4 type='Circle'/>.</span>
            <span><b>3. </b><input id=2 type='Circle'/>.</span>
            <span><b>6. </b><input id=5 type='Circle'/>.</span>
          </div>
        </div>
        `,
    },
  },
};

export default json;
