import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
const json = {
  1: {
    unit: "Starter",
    id: "WB5-2024-Starter-P4-E1",
    // audio: "Audios/21-audio.mp3",
    exerciseKey: "img/FriendsPlus/Page4/Key/E1answerKey.png",
    video: "",
    component: T6,
    // maxLength: 5,
    textAlign: "center",
    textareaStyle: {
      width: 700,
      paddingTop: 5,
    },

    stylesTextInput: { background: "none" },
    inputSize: 150,
    titleQuestion: [
      {
        num: "1",
        title: "Complete the text message.",
        color: "#2d408e",
        left: 80,
      },
    ],
    styleHint: {
      // border: "none",
      justifyContent: "center",
      paddingInline: 3,
      paddingBlock: "0px",
      borderColor: "#2bbbf1",
      color: "#57585a",
      // styleElementHintBox: {
      //   // background: "#fee7d2",
      //   marginRight: 20,
      //   padding: "2px 5px",
      //   borderRadius: "10px",
      // },
    },
    hintBox: [
      {
        src: ["art", "math", "P.E.", "lunch"],
        borderColor: "black",
        width: 400,
      },
    ],
    questionImage: [],
    // isHello: true,
    questions: [
      {
        title: `
        <hintbox id=0 ></hintbox>
				<div style='position:relative; margin-top: 16px'>
          <img style='height:21cm' src='img/FriendsPlus/Page4/E1/1.jpg'/>
          <div style='position:absolute;top:193px;left:673px'>#</div>
                    <div style='position:absolute;top:281px;left:545px'>#</div>
          <div style='position:absolute;top:374px;left:488px'>#</div>
          <div style='position:absolute;top:510px;left:179px'>#</div>
        </div>
        
        `,
        answer: ["P.E", "math", "lunch", "Art"],
      },
    ],
  },
  // Câu 2 đang chờ viết component mới
  2: {
    unit: "Starter",
    id: "WB5-2024-Starter-P4-E2",
    // audio: "Audios/22-audio.mp3",
    // video: "",

    exerciseKey: "img/FriendsPlus/Page4/Key/E2answerKey.png",
    // maxLength: 5,
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "dash",
    },
    inputSize: 100,
    titleQuestion: [
      {
        num: "2",
        title: "Find and circle the words",
        color: "#2d408e",
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "580px",
              width: "30px",
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "350px",
              width: "30px",
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "580px",
              width: "30px",
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "171px",
              left: "350px",
              width: "30px",
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 4
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          display: "block",
          height: "100%",
          width: "100%",
          textAlign: "center",
          border: "2px solid",
          borderColor: "transparent",
          color: "transparent",
          borderRadius: "50%",
        },
        selectWordStyle: {
          borderColor: "#a8a9ad",
          color: "transparent",
        },
        limitSelect: 1,
        listWords: ["✓", "✓", "✓", "✓"],
        answers: ["0-0", "1-0", "2-0", "3-0"],
        initialValue: [],
      },
      Layout: `
      
      <div style='display:flex; margin-top: 10px; position: relative'>
        <img style='width: 15cm' src='img/FriendsPlus/Page4/E2/1.jpg'/>
        <div style='position: absolute;height: 40px; width: 90px; top: 95px; left: 80px; transform: rotate(350deg)'><input id=0 type='Circle' /></div>
        <div style='position: absolute;height: 40px; width: 127px; top: 65px; left: 165px; transform: rotate(348deg)'><input id=1 type='Circle' /></div>
        <div style='position: absolute;height: 40px; width: 50px; top: 60px; left: 294px; transform: rotate(16deg)'><input id=2 type='Circle' /></div>
        <div style='position: absolute;height: 55px; width: 201px; top: 71px; left: 342px; transform: rotate(7deg)'><input id=3 type='Circle' /></div>
      </div>
      `,
    },
  },

  3: {
    unit: "Starter",
    id: "WB5-2024-Starter-P4-E3",
    audio: "",
    video: "",

    exerciseKey: "img/FriendsPlus/Page4/Key/E3answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title: "Look and match.",
        color: "#203c8f",
        // numberImg:"b",
        left: -23,
      },
    ],
    component: MatchDots,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "34px",
              left: "120px",
              width: "217px",
              height: "51px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "153px",
              left: "406px",
              width: "275px",
              height: "51px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "457px",
              left: "183px",
              width: "166px",
              height: "51px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "248px",
              left: "106px",
              width: "173px",
              height: "51px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "312px",
              left: "472px",
              width: "280px",
              height: "51px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "226px",
              left: "790px",
              width: "189px",
              height: "51px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 5
        ],
        answers: ["2-5", "1-4", "0-3"],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",

          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          // borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "school_trip / boat_trip.",
          "movie_theater / museum.",
          "train / bus.",
          "four / five.",
          " postcard / dinosaur_model.",
        ],
        answers: ["1-4", "3-4", "2-0", "0-0", "4-6"],
        initialValue: ["0-0"],
      },
      Layout: `
      
        <input id='0' type='boxMatch' />
        <input id='1' type='boxMatch' />
        <input id='2' type='boxMatch' />
        <input id='3' type='boxMatch' />
        <input id='4' type='boxMatch' />
        <input id='5' type='boxMatch' />
        <img style='height:13cm' src='img/FriendsPlus/Page4/E3/1.jpg'/>
      `,
    },
  },
  5: {
    unit: "Starter",
    id: "WB5-2024-Starter-P4-E5",
    audio: "",
    video: "",

    component: T6,
    // maxLength: 5,
    // textAlign: "center",
    textareaStyle: {
      width: 700,
      paddingTop: 5,
    },
    inputSize: 720,
    titleQuestion: [
      {
        num: "5",
        title: "Would you like to visit a cave? Why / Why not?",
        color: "#203c8f",
        left: 80,
      },
    ],
    questionImage: [],
    isHello: true,
    questions: [
      {
        title: `
				<textarea id='0' rows=7 ></textarea>
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
