import { Form } from "antd";
import moment from "moment";
import "moment/locale/vi";
import React from "react";
import ReactHtmlParser from "react-html-parser";
import { Button, Container, Row } from "reactstrap";
import {
  BigPlayButton,
  ClosedCaptionButton,
  ControlBar,
  CurrentTimeDisplay,
  ForwardControl,
  Player,
  ReplayControl,
} from "video-react";
import FooterModal from "../../FooterModal";
import TitleQuestion from "../../TitleQuestion";
import HintBox from "../../Utilities/HintBox";
import Circle, { calculateResultCircle } from "./Circle";
import StarModal from "./StarModal";
import Write, { calculateResultWrite, toFormValues } from "./Write";
import styles from "./styles.module.css";
import PointDot, { RenderLineTo, calculateResultLine } from "./PointDot";
import Recorder from "../../Recorder";
import Listen from "../../Listening";
import PointDotv2, { DrawCircle, DrawCircleArray } from "./PointDotv2";
moment.locale("vi");

function Circle_Write(props) {
  const countInput = count(); // initialize the count function
  const [form] = Form.useForm();
  const [valueInput, setValueInput] = React.useState("");
  const [noSpace, setNoSpace] = React.useState([]);
  const [noSpaceAnswer, setNoSpaceAnswer] = React.useState([]);
  const [state, setState] = React.useState({
    booleanArray: [],
    isDisabledRetry: true,
    isDisabledSubmit: true,
  });
  const [Question, setQuestion] = React.useState(null);
  const [modalStar, setModalStar] = React.useState(false);
  const [firstId, setFirstId] = React.useState(null);
  const [isMoving, setIsMoving] = React.useState(false);
  const [userSelect, setUserSelect] = React.useState(
    Question?.DrawLines?.userSelect ?? []
  );
  //
  React.useEffect(() => {
    const clonedProps = JSON.parse(JSON.stringify(props));

    const question = clonedProps.question;
    clonedProps.question.Layout = question.Layout.replaceAll("#", "<input/>");
    //initialDrawLines
    question.DrawLines.isDoing = true;
    question.DrawLines.userAnswers = Array.from(
      question.DrawLines.initialValue
    );
    question.DrawLines.newPointDot &&
      question.DrawLines.circleStringArray &&
      question.DrawLines.circleStringArray.forEach((string, strIndex) => {
        question.DrawLines.boxMatch.push(
          ...string.split("").map((item, itIndex) => ({
            id: `${strIndex}_${itIndex}`,
            children: item,
          }))
        );
      });

    //initialCircle
    question.Circle.isDoing = true;
    question.Circle.userAnswers = Array.from(question.Circle.initialValue);
    //initialWrite
    question.Write.isDoing = true;
    question.Write.userAnswers = Array.from(question.Write.initialValue);
    form.resetFields();
    form.setFieldsValue(toFormValues(question.Write.userAnswers)); // nhập giá trị cho form
    //
    const audioUrl = clonedProps.audio;
    const videoUrl = clonedProps.video;
    //update
    setState((prevState) => ({ ...prevState, question, audioUrl, videoUrl }));
    setQuestion(question);
  }, [form, props]);
  // hiển thị ngôi sao
  const onModalStar = () => {
    setModalStar(true);
    setTimeout(() => {
      setModalStar(false);
    }, 3000);
  };
  //
  const onSubmit = React.useCallback(() => {
    const { Circle, Write, DrawLines } = Question;
    //Circle
    Circle.isDoing = false; //
    Circle.resultCircle = calculateResultCircle(
      Circle.userAnswers,
      Circle.answers,
      Circle.listWords,
      Circle.limitSelect
    );
    //Write

    Write.isDoing = false; //
    const values = form.getFieldsValue();
    Write.userAnswers = Object.values(values);
    const character = props.character;
    Write.resultWrite = calculateResultWrite(
      Write.userAnswers,
      Write.answers,
      Write.checkUppercase,
      character
    );
    let WriteUserAnswers = [];
    if (Write.userAnswers) {
      WriteUserAnswers = Write.userAnswers.map((i) => (i ? i : ""));
    } else {
      const checkUseAnswer = Write.answers.map(() => "");
      WriteUserAnswers = Write.userAnswers ?? checkUseAnswer;
    }
    const listStudentAnswer = WriteUserAnswers.map((str) =>
      str.split(character)
    );

    const listAnswers = Write.answers.map((item) => item.split(character));
    //
    const notSpacesAnswer = listAnswers.map((i) =>
      i.map((a) =>
        a
          .trim()
          .replace(/\s|[\.\?]$/g, "")
          .toLowerCase()
      )
    );
    setNoSpaceAnswer(notSpacesAnswer);
    const notSpaces = listStudentAnswer.map((item) =>
      item.map((a) =>
        a
          .trim()
          .replace(/\s|[\.\?]$/g, "")
          .toLowerCase()
      )
    );
    setNoSpace(notSpaces);
    DrawLines.isDoing = false; //
    DrawLines.resultLines = calculateResultLine(
      DrawLines.userAnswers,
      DrawLines.answers
    );
    //State
    const ArrayDrawline = DrawLines.resultLines.booleanArray;
    const ArrayCircle = Circle.resultCircle.booleanArray;
    const ArrayWrite = Write.resultWrite.booleanArray;
    const { star, resultString, percent, booleanArray } = calculateResult(
      ArrayCircle,
      ArrayWrite,
      ArrayDrawline
    );

    const studentAnswer = {
      Write: Write.userAnswers,
      Circle: Circle.userAnswers,
      DrawLines: DrawLines.userAnswers,
    };
    const params = {
      score: percent,
      unit: props.unit,
      results: resultString,
      exerciseId: props.id,
      sreatedDate: moment().format(),
      studentAnswer: JSON.stringify(studentAnswer),
    };
    props.postAnswerToApi(params);
    //update
    setState((pre) => ({
      ...pre,
      isDisabledRetry: false,
      isDisabledSubmit: true,
      booleanArray,
      star,
      percent,
    }));

    setQuestion((pre) => ({ ...pre, Circle, Write, DrawLines }));
    onModalStar();
  }, [Question, form, props]);
  const onRetry = React.useCallback(() => {
    const { Circle, Write, DrawLines } = Question;
    const resultCircleArray = Circle.userAnswers.filter((value) =>
      Circle.answers.includes(value)
    );
    //initialCircle

    let resultWrite = [];

    resultWrite = noSpace.map((cuacua, cococ) => {
      const vailua = cuacua.some((e) => !noSpaceAnswer[cococ].includes(e));
      return vailua !== true ? Write.userAnswers[cococ] : "";
    });

    const ResultArray = DrawLines.userAnswers.filter((value) =>
      DrawLines.answers.includes(value)
    );
    //initialCircle
    DrawLines.isDoing = true; //
    DrawLines.userAnswers = Array.from(ResultArray);
    Circle.isDoing = true; //
    Circle.userAnswers = Array.from(resultCircleArray);
    //initialWrite
    Write.isDoing = true;
    Write.userAnswers = Array.from(resultWrite);
    form.resetFields();
    form.setFieldsValue(toFormValues(Write.userAnswers)); // nhập giá trị cho form
    //initialState
    const booleanArray = [];
    //update
    setState((pre) => ({
      ...pre,
      booleanArray,
      isDisabledSubmit: true,
      isDisabledRetry: true,
    }));
    setQuestion((pre) => ({ ...pre, Circle, Write, DrawLines }));
  }, [Question, form]);

  const transform = React.useCallback(
    (node, index) => {
      if (
        node.type === "tag" &&
        node.name === "input" &&
        node.attribs.type === "Circle"
      ) {
        //render tag input Circle
        const id_circle = node.attribs.id;
        return (
          <Circle
            key={index}
            dontChangeColor={props.question.Circle.dontChangeColor}
            onlyBorderTop={props.question.Circle.onlyBorderTop}
            attribs={node.attribs}
            circleInput={props.question.Write.circleInput}
            UnderlineStyle={props.question.Circle.UnderlineStyle}
            ThSmile={props.question.ThSmile}
            id={id_circle}
            isHello={props.isHello}
            data={Question.Circle}
            newSplit={props.question.Circle.newSplit}
            setData={(data) => setQuestion((pre) => ({ ...pre, Circle: data }))}
            elementBefore={props.question.Circle.elementBefore}
            selectElementBefore={props.question.Circle.selectElementBefore}
          />
        );
      }
      if (
        node.type === "tag" &&
        node.name === "input" &&
        node.attribs.type === "boxMatch"
      ) {
        //render tag input boxMatch
        const id_dot = node.attribs.id;
        if (Question.DrawLines.newPointDot === true) {
          const { DrawLines } = Question;
          // const lengthArray = Question.DrawLines.circleStringArray.map(
          //   (item) => item.length
          // );
          // const firstPosition = lengthArray
          //   .filter((item, index) => index < id_dot)
          //   .reduce((a, b) => a + b, 0);
          return (
            <>
              <DrawCircleArray
                circleColor={DrawLines.colorLineTo}
                isHello={props.isHello}
                userAnswers={DrawLines.userAnswers}
                isDoing={DrawLines.isDoing}
                widthLineto={DrawLines?.widthLineto}
                listBoolean={DrawLines.resultLines?.listBoolean}
                padding={DrawLines.circlePadding}
                path={DrawLines.path}
              ></DrawCircleArray>
              <DrawCircle
                circleColor={DrawLines.colorLineTo}
                isHello={props.isHello}
                item={userSelect.join("-")}
                isDoing={DrawLines.isDoing}
                widthLineto={DrawLines?.widthLineto}
                listBoolean={DrawLines.resultLines?.listBoolean}
                padding={DrawLines.circlePadding}
                path={DrawLines.path}
              ></DrawCircle>
              {Question.DrawLines.circleStringArray ? (
                Question.DrawLines.boxMatch
                  .filter((item, index) => item.id.split("_")[0] === id_dot)
                  .map((item, index) => (
                    <PointDotv2
                      key={index}
                      id={item.id}
                      margin={Question.DrawLines.margin}
                      padding={Question.DrawLines.padding}
                      border={Question.DrawLines.border}
                      transform={Question.DrawLines.transform}
                      widthHeighDot={Question.DrawLines.widthHeighDot}
                      sort={Question.DrawLines.sort}
                      isHello={props.isHello}
                      data={Question.DrawLines}
                      setData={(data) =>
                        setQuestion((pre) => ({ ...pre, DrawLines: data }))
                      }
                    />
                  ))
              ) : (
                <PointDotv2
                  key={index}
                  id={id_dot}
                  margin={Question.DrawLines.margin}
                  padding={Question.DrawLines.padding}
                  transform={Question.DrawLines.transform}
                  widthHeighDot={Question.DrawLines.widthHeighDot}
                  sort={Question.DrawLines.sort}
                  isHello={props.isHello}
                  data={Question.DrawLines}
                  setData={(data) =>
                    setQuestion((pre) => ({ ...pre, DrawLines: data }))
                  }
                />
              )}
            </>
          );
        } else
          return (
            <PointDot
              key={index}
              id={id_dot}
              widthHeighDot={Question.DrawLines.widthHeighDot}
              sort={Question.DrawLines.sort}
              isHello={props.isHello}
              noTransform={Question.DrawLines.noTransform}
              data={Question.DrawLines}
              setData={(data) =>
                setQuestion((pre) => ({ ...pre, DrawLines: data }))
              }
            />
          );
      }
      if (node.type === "tag" && node.name === "hintbox") {
        const { id, ...elementStyle } = node.attribs;
        const styleElementHintBox = props?.styleHint?.styleElementHintBox;
        return props.hintBox.map((v, i) => {
          if (id !== `${i}`) return null;
          // cont
          const {
            src,
            style,
            marginLeft,
            borderColor,
            width,
            position,
            arrow,
            styleElement,
          } = v;
          return (
            <HintBox
              styleElement={styleElement ?? styleElementHintBox}
              key={i}
              src={src}
              borderColor={borderColor}
              width={width}
              elementStyle={elementStyle}
              marginLeft={marginLeft}
              style={style ?? props?.styleHint}
              position={position}
              arrow={arrow}
            />
          );
        });
      }
      if (node.type === "tag" && node.name === "textarea") {
        //render tag textarea
        //<div><textarea id="0" rows="7" text-indent='200px' color='red'  margin-top='500px' ></textarea></div>
        const { id, rows, ...elementStyle } = node.attribs;
        const { textareaStyle } = props;
        const isCorrect = Question.Write.resultWrite?.listBoolean[id] ?? false;
        if (Question.Write.isDoing === false) {
          isCorrect === true || props.isHello
            ? (textareaStyle.color = "#2ecc71")
            : (textareaStyle.color = "#e74c3c");
        } else {
          textareaStyle.color = "black";
        }

        return (
          <div key={index}>
            <Form.Item name={id} initialValue={node.children[0]?.data}>
              <textarea
                rows={rows}
                className={styles.notes}
                style={{
                  fontSize: 24,
                  // height:40,
                  border: "none",
                  ...props.textareaStyle,
                  ...elementStyle,
                }}
              ></textarea>
            </Form.Item>
          </div>
        );
      }
      if (node.type === "tag" && node.name === "input") {
        //render tag input
        let currentInputNo = countInput.next();
        const { id, type, rows, ...elementStyle } = node.attribs;
        return (
          <Write
            key={index}
            id={id || currentInputNo}
            handleChange={handleChange}
            inputRong={props.question.inputRong}
            isHello={props.isHello}
            data={Question.Write}
            type={type}
            rows={rows}
            elementStyle={elementStyle}
          />
        );
      }
    },
    [countInput, Question]
  );
  //
  const onStopRecording = React.useCallback((recordURL) => {
    setState((prevState) => ({ ...prevState, recordURL: recordURL.blobURL }));
  }, []);
  const handleChange = (e, id) => {
    setValueInput(e.target.value);
    if (Question?.Write.circleInput === true) {
      // if (e.nativeEvent.data !== " " && e.nativeEvent.data !== null) return;
      let newListWords = Question?.Circle.listWords.map((item, index) =>
        index === id
          ? e.target.value
              .replace(/^ +/, function (match) {
                return "&nbsp;".repeat(match.length);
              })
              .replace(/ {2,}/g, function (match) {
                return `${"&nbsp;".repeat(match.length - 1)} `;
              })
          : item
      );
      setQuestion((pre) => ({
        ...pre,
        Circle: {
          ...pre.Circle,
          listWords: newListWords,
          userAnswers: pre.Circle.userAnswers.filter((item) => {
            let arrayWords = newListWords[id].split(/[\s,|]+/g);
            let wordIndex = Number(item.split("-")[1]);
            let questionIndex = Number(item.split("-")[0]);
            let filtered =
              questionIndex !== id || wordIndex < arrayWords.length;

            return filtered;
          }),
        },
      }));
    }
  };
  const checkEnableSubmit = React.useEffect(() => {
    //
    setQuestion((question) => {
      //check Write
      //check Circle
      const { Circle, DrawLines } = question;
      const isCircleDone =
        DrawLines.userAnswers.length +
        Circle.userAnswers.length +
        valueInput.length;
      let isSubmit;
      isSubmit =
        DrawLines.initialValue.length + Circle.initialValue.length > 0
          ? isCircleDone > 1
          : isCircleDone >= 1;
      //
      if (Circle.isDoing === false || DrawLines.isDoing === false) {
        isSubmit = false;
      }

      setState((pre) => ({ ...pre, isDisabledSubmit: !isSubmit }));
      return question;
    });
  }, [form, valueInput, Question]);
  //
  const renderContent = React.useCallback(() => {
    const { Layout, DrawLines } = Question;
    return (
      <div
        className="match-container"
        style={{
          position: "relative",
          paddingBottom: "30px",
          marginLeft: props.question.leftContent ?? "",
          fontSize: "25px",
        }}
        onClick={checkEnableSubmit}
      >
        {ReactHtmlParser(Layout, { transform })}

        {DrawLines.newPointDot !== true && (
          <RenderLineTo
            colorLineTo={DrawLines.colorLineTo}
            lineToNotCenter={DrawLines.lineToNotCenter}
            lineDirection={DrawLines.lineDirection}
            isHello={props.isHello}
            userAnswers={DrawLines.userAnswers}
            isDoing={DrawLines.isDoing}
            widthLineto={DrawLines?.widthLineto}
            listBoolean={DrawLines.resultLines?.listBoolean}
            checkEnableSubmit={checkEnableSubmit}
          />
        )}
      </div>
    );
  }, [Question, checkEnableSubmit, transform, userSelect]);

  const handleClick = React.useCallback(() => {
    let userAnswers = Question.DrawLines.userAnswers ?? [];
    if (userSelect.length >= 2) {
      const newline = userSelect
        .sort((a, b) => a.split("_")[1] - b.split("_")[1])
        .join("-");
      const new_point = newline
        .split("-")
        .sort((a, b) => a.split("_")[1] - b.split("_")[1])
        .map((item) => item.split("_").map((item) => Number(item))); // điểm mới chọn
      if (
        new_point[0][0] !== new_point[1][0] &&
        new_point[0][1] !== new_point[1][1]
      )
        return null; // không cho chọn chéo
      if (Question.DrawLines.multipleLine) {
        //chọn  nhiều
        if (userAnswers.includes(newline)) {
          // tìm đường đã nối
          userAnswers = userAnswers.filter((x) => x !== newline); // xóa đường nối
        } else {
          userAnswers.push(newline); // thêm đường mới
        }
      } else {
        // chọn 1-1
        if (userAnswers.includes(newline)) {
          // tìm đường đã nối
          userAnswers = userAnswers.filter((x) => x !== newline); // xóa đường nối
        } else {
          if (
            Question.DrawLines.notOverlapse &&
            Question.DrawLines.newPointDot
          ) {
            // không cho nối đè
            //calculate ids between 2 points
            const calculateIds = (point) => {
              let ids = [];
              if (point[0][0] === point[1][0]) {
                ids = Array.from(
                  { length: point[1][1] - point[0][1] + 1 },
                  (_, i) => `${point[0][0]}_${point[0][1] + i}`
                );
              }
              if (point[0][1] === point[1][1]) {
                ids = Array.from(
                  { length: point[1][0] - point[0][0] + 1 },
                  (_, i) => `${point[0][0] + i}_${point[0][1]}`
                );
              }
              return ids;
            };
            const new_pointIds = calculateIds(new_point);
            userAnswers = userAnswers.filter((x) => {
              const selected_point = x
                .split("-")
                .sort((a, b) => a.split("_")[1] - b.split("_")[1])
                .map((item) => item.split("_").map((item) => Number(item))); // điểm đã chọn
              const selected_pointIds = calculateIds(selected_point);
              // phát hiện đường nối đè
              const isOverlapse = selected_pointIds.some((x) =>
                new_pointIds.includes(x)
              );
              return !isOverlapse; // lọc lấy những đường ko đè
            }); // xóa đường nối
          }
          userAnswers = userAnswers.filter((item) => {
            const selected_point = item.split("-"); // điểm đã chọn
            const isRepetition = userSelect.some((x) =>
              selected_point.includes(x)
            ); //phát hiện ít nhất 1 điểm trùng lặp
            return !isRepetition; // lọc lấy những đường ko trùng lặp
          });

          userAnswers.push(newline);
        }
      }
    }
    //update
    const newData = { ...Question?.DrawLines, userSelect, userAnswers };
    setQuestion((pre) => ({ ...pre, DrawLines: newData }));
    setUserSelect([]);
  }, [Question?.DrawLines, setQuestion, userSelect]);

  React.useEffect(() => {
    if (!Question?.DrawLines?.newPointDot === true) return null;
    let container = document.getElementById("circle-container");
    if (!container) return null;
    const handleMouseDown = (e) => {
      e.preventDefault();
      if (typeof e.target.className !== "string") return null;
      if (!e.target.className.includes("dot-")) {
        return null;
      }
      let currentId = e.target.className.split("-")[1];
      setFirstId(currentId);
      setIsMoving(true);
    };
    const handleMouseUp = (e) => {
      e.preventDefault();
      if (typeof e.target.className !== "string") return null;
      if (!e.target.className.includes("dot-")) {
        return null;
      }
      let currentId = e.target.className.split("-")[1];
      currentId !== firstId && setUserSelect([firstId, currentId]);
      handleClick();
      setIsMoving(false);
    };
    const handleMouseLeave = (e) => {
      setIsMoving(false);
      setUserSelect([]);
    };
    container.addEventListener("mousedown", handleMouseDown, false);
    container.addEventListener("mouseup", handleMouseUp, false);
    container.addEventListener("mouseleave", handleMouseLeave, false);

    return () => {
      container.removeEventListener("mousedown", handleMouseDown, false);
      container.removeEventListener("mouseup", handleMouseUp, false);
      container.removeEventListener("mouseleave", handleMouseLeave, false);
    };
  }, [handleClick, firstId]);

  React.useEffect(() => {
    if (!isMoving || !Question.DrawLines.newPointDot === true) return null;
    let container = document.getElementById("circle-container");

    const handleMouseMove = (e) => {
      e.preventDefault();
      if (typeof e.target.className !== "string") return null;
      if (!e.target.className.includes("dot-")) {
        return null;
      }
      let currentId = e.target.className.split("-")[1];
      currentId !== firstId && setUserSelect([firstId, currentId]);
    };
    container.addEventListener("mousemove", handleMouseMove, false);
    return () => {
      container.removeEventListener("mousemove", handleMouseMove, false);
    };
  }, [isMoving, firstId]);
  //
  if (!Question) return null;
  // test
  function testButton({ Question, setQuestion, form }) {
    return (
      <div>
        <Button
          onClick={() => {
            setQuestion((pre) => {
              const { Circle, Write, DrawLines } = pre;
              Circle.userAnswers = Array.from(Circle.answers);
              //
              DrawLines.userAnswers = Array.from(DrawLines.answers);
              Write.userAnswers = Array.from(Write.answers);

              form.resetFields();
              form.setFieldsValue(toFormValues(Write.userAnswers)); // nhập giá trị cho form
              //handle circle input onchange
              console.log(Write.userAnswers);
              props.question.Write.circleInput &&
                Write.userAnswers.map((item, index) => {
                  handleChange({ target: { value: item } }, index);
                  return null;
                });
              return { ...pre, Circle, Write, DrawLines };
            });
          }}
        >
          điền đáp án test
        </Button>
        <Button
          onClick={() => {
            console.log(
              "🚀 ~ Circle.userAnswers: ",
              Question.Circle.userAnswers
            );
            const values = form.getFieldsValue();
            const Write_userAnswers = Object.values(values);
            console.log("🚀 ~ Write_userAnswers", Write_userAnswers);
            console.log(
              "🚀 ~ testButton ~ Question.Circle.userAnswers:",
              Question.DrawLines.userAnswers
            );
          }}
        >
          log userAnswers
        </Button>
      </div>
    );
  }

  return (
    <Container>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div>
          <Row>
            <TitleQuestion titleQuestion={props.titleQuestion} />
          </Row>
          {state.videoUrl && (
            <div class="d-flex justify-content-center" style={props.videoStyle}>
              <div style={{ width: "35vw", marginBottom: 50 }}>
                <Player>
                  <BigPlayButton position="center" />
                  <source src={props.video} type="video/mp4" default />

                  <track
                    kind="captions"
                    src={props.videoSub}
                    srcLang="en"
                    label="English"
                  />
                  <ControlBar autoHide={false}>
                    <ReplayControl seconds={10} order={1.1} />
                    <ForwardControl seconds={10} order={1.2} />
                    <CurrentTimeDisplay order={4.1} />
                    <ClosedCaptionButton order={7} />
                  </ControlBar>
                </Player>
              </div>
            </div>
          )}
          <Row
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "start",
            }}
          >
            <Form
              autoComplete="off"
              form={form}
              // onFinish={onFinish}
              onValuesChange={checkEnableSubmit}
            >
              {renderContent()}
            </Form>
          </Row>
          {!props.isHello && (
            <StarModal isModal={modalStar} star={state.star} />
          )}
        </div>
      </div>
      {props?.recorder && (
        <Row className="justify-content-center" style={{ marginBottom: 30 }}>
          <Recorder
            // onRecording={onRecording}
            onStop={onStopRecording}
          />
          {state.recordURL && (
            <Listen
              custom
              audioURL={state.recordURL}
              style={{ padding: 0, borderWidth: 0 }}
            >
              <Button color="primary" id="tooltipRepeat" type="button">
                <i style={{ fontSize: 15 }} className="fas fa-volume-up" />
              </Button>
            </Listen>
          )}
        </Row>
      )}
      {/* {process.env.REACT_APP_STAGEMENT !== "production" &&
        testButton({
          Question,
          setQuestion,
          form,
        })} */}
      <FooterModal
        onRetry={onRetry}
        onSubmit={onSubmit}
        audioUrl={state.audioUrl}
        result={state?.booleanArray}
        percentCircle={state?.percent}
        isHello={props.isHello}
        studentAnswer={state.studentAnswer}
        exerciseKey={props.exerciseKey}
        isDisabledRetry={state.isDisabledRetry}
        isDisabledSubmit={state.isDisabledSubmit}
        hideBtnFooter={props.hideBtnFooter} //not answer
      />
    </Container>
  );
}
export default Circle_Write;
function count() {
  let n = 0;
  return {
    next: () => n++,
    reset: () => (n = 0),
  };
}
//tính kết quả
function calculateResult(ArrayCircle, ArrayWrite, ArrayDrawline) {
  let DrawLine = ArrayDrawline;
  const booleanArray = [ArrayCircle, ArrayDrawline, ArrayWrite].filter(
    (a) => a.length > 0
  );
  const correctAnswerCircle = ArrayCircle.filter((x) => x === true).length;
  const correctArrayWrite = ArrayWrite.filter((x) => x === true).length;
  const correctArrayDrawline = ArrayDrawline.filter((x) => x === true).length;
  const ThongSoCircle =
    ArrayCircle.length > 0 ? correctAnswerCircle / ArrayCircle.length : 0;
  ArrayDrawline.forEach((item, index) => {
    if (item === undefined) {
      DrawLine.push("");
    }
  });
  const ThongSoWrite =
    ArrayWrite.length > 0 ? correctArrayWrite / ArrayWrite.length : 0;

  const ThongSoDrawline =
    ArrayDrawline.length > 0 ? correctArrayDrawline / DrawLine.length : 0;
  const ThongSoBoolean = booleanArray.length / 3;
  const percent =
    ArrayCircle.length > 0 && ArrayWrite.length > 0
      ? parseInt(
          ((ThongSoCircle * 3 + ThongSoDrawline * 3 + ThongSoWrite * 4) /
            (ThongSoBoolean * 10)) *
            100
        )
      : parseInt(
          ((ThongSoCircle + ThongSoWrite + ThongSoDrawline) /
            booleanArray.length) *
            100
        );
  const star = percent / 20;
  const resultString = `${percent / 100}`;

  return { star, resultString, booleanArray, percent };
}
