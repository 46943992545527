import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Starter",
    id: "WB5-2024-Starter-P50-E1",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page3/Key/E1answerKey.png",
    video: "",
    component: T6,
    titleQuestion: [
      {
        num: "1",
        title: "Order the words and write short answers for you.",
        color: "#203c8f",
        left: 50,
      },
    ],
    stylesTextInput: { width: 540 },
    // textAlign:'center',
    questions: [
      {
        title: `
          <b>1.</b>&ensp;you go to Did yesterday the park ?<br>
          <u style='color:gray'>&ensp;Did you go to the park yesterday? Yes, I did.&ensp;&ensp;</u> <br>
          <b>2.</b>&ensp;see you your friends on Saturday Did ?<br>#<br>
          <b>3.</b>&ensp;you Did on Sunday play soccer ?<br>#<br>
          <b>4.</b>&ensp;you visit on the weekend Did a museum ?<br>#<br>
          
          `,
        answer: [
          "Did you see your friends on Saturday?",
          "Did you play soccer on Sunday?",
          "Did you visit a museum on the weekend?",
        ],
      },
    ],
  },
  2: {
    unit: "Starter",
    id: "WB5-2024-Starter-P50-E2",
    audio: "",
    video: "",

    exerciseKey: "img/FriendsPlus/Page3/Key/E2answerKey.png",
    component: T6,
    // maxLength: 5,
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "dash",
      background: "none",
    },
    inputSize: 100,
    titleQuestion: [
      {
        num: "2",
        title:
          "Complete the questions and answers. Use the past simple of the verbs. ",
        color: "#203c8f",
        left: 50,
      },
    ],
    questionImage: [],
    // isHello: true,
    questions: [
      {
        title: `
        <div style='display:flex'>
            <div >
                <img style='height:15mm' src='img/FriendsPlus/Page3/E2/1.jpg'/><br>
                <div style='margin-left:20px'>
                    <b>1.</b>&ensp;<u style='color:gray'>&ensp;Who&ensp;</u> did you see yesterday?<br>
                    <b>2.</b>&ensp;#did you see them?<br>
                    <b>3.</b>&ensp;#did you do?<br>
                    <b>4.</b>&ensp;#you have a good time?<br>
                    <b>5.</b>&ensp;#did you eat?<br>
                </div>    
            </div>
            <div style='margin-left:50px'>
                <img style='height:15mm' src='img/FriendsPlus/Page3/E2/2.jpg'/><br>
                <div style='margin-left:50px'>
                    I <u style='color:gray'>&ensp;saw&ensp;</u> Robbie and Simon.<br>
                    I saw them at the# .<br>
                    We# basketball.<br>
                    Yes,# did.<br>
                    I# a sandwich.
                </div>    
            </div>

            
        </div>
        
        `,
        answer: ["Where", "What", "Did", "What", "park", "played", "I", "ate"],
      },
    ],
  },

  3: {
    unit: "Starter",
    id: "WB5-2024-Starter-P50-E3",
    audio: "Audios/20-audio.mp3",
    video: "",

    exerciseKey: "img/FriendsPlus/Page3/Key/E3answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen to the song. Circle the correct words. <headphone name='20' src='Audios/20-tieude.mp3'></headphone> ",
        color: "#203c8f",
        numberImg: "b",
        left: -23,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "580px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "580px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "171px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 4
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",

          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "school_trip / boat_trip.",
          "movie_theater / museum.",
          "train / bus.",
          "four / five.",
          " postcard / dinosaur_model.",
        ],
        answers: ["1-4", "3-4", "2-0", "0-0", "4-6"],
        initialValue: ["0-0"],
      },
      Layout: `
      
     <div style='display:flex'>
        <div>
            <b>1.</b>&ensp;We went on a <input id='0' type='Circle' /><br>
            <b>2.</b>&ensp;We went to a <input id='1' type='Circle' /><br>
            <b>3.</b>&ensp;We went by <input id='2' type='Circle' /><br>
            <b>4.</b>&ensp;We didn’t come home until <input id='3' type='Circle' /><br>
            <b>5.</b>&ensp;I bought a <input id='4' type='Circle' /><br>
        </div>
        <img style='height:10cm' src='img/FriendsPlus/Page3/E3/1.jpg'/>
     </div>
      `,
    },
  },
  5: {
    unit: "Starter",
    id: "WB5-2024-Starter-P50-E5",
    audio: "",
    video: "",

    component: T6,
    // maxLength: 5,
    // textAlign: "center",
    textareaStyle: {
      width: 700,
      paddingTop: 5,
    },
    inputSize: 720,
    titleQuestion: [
      {
        num: "5",
        title: "Would you like to visit a cave? Why / Why not?",
        color: "#203c8f",
        left: 80,
      },
    ],
    questionImage: [],
    isHello: true,
    questions: [
      {
        title: `
				<textarea id='0' rows=7 ></textarea>
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
