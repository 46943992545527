import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    unit: "Unit 3",
    id: "WB5-2024-U3-P25-E1",
    // audio: "Audios/03-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page25/Key/E1answerKey.png",
    stylesTextInput: {
      fontSize: 21,
    },
    // textAlign: "center",
    titleQuestion: [
      {
        num: "1",
        title: "Write always, usually, sometimes, or never.",
        color: "#2d408e",
        left: 50,
      },
    ],
    component: T6,
    questions: [
      {
        title: `
        <div style='font-size:21px; width: 25cm; display: flex; flex-direction: column; gap: 5px'>
          <span><b>1. </b>cut onion the first <u style='color:gray; text-underline-offset: 5px'>&nbspFirst, cut the onion.&nbsp</u></span>
          <span><b>2. </b>mix meat the onion the and then <input width='550px'/></span>
          <span><b>3. </b>add salt next some and pepper <input width='570px'/></span>
          <span><b>4. </b>the put on mixture rice paper the it roll and <input width='450px'/></span>
          <span><b>5. </b>the fry finally rolls <input width='700px'/></span>
        </div>
        `,
        answer: [
          "Then, mix the meat and the onion.|Then, mix the onion and the meat.",
          "Next, add some salt and pepper.|Next, add some pepper and salt.",
          "Put the mixture on the rice paper and roll it.",
          "Finally, fry the rolls.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 3",
    id: "WB5-2024-U3-P25-E2",
    audio: "",
    video: "",
    stylesTextInput: { background: "none", border: "none", paddingLeft: 0 },
    textareaStyle: {
      marginLeft: 7,
      background: "none",
      width: "calc(100% - 70px)",
      resize: "none",
      lineHeight: "1.75em",
      position: "relative",
      zIndex: 1,
      // borderBottom: "1px solid rgb(168, 168, 168)",
    },
    maxLength: 119,
    // textAlign: "center",
    inputSize: 500,
    titleQuestion: [
      {
        num: "2",
        title: "Complete the notes.",
        color: "#2d408e",
        left: 50,
        // prefix:[{text:"hello",icon:"fas fa-pen"}]
      },
    ],
    // character: "",
    hideBtnFooter: true,
    component: T6,
    notification: false,
    questions: [
      {
        title: `
          <div style='display: flex; flex-direction: column; width: 25cm; border: 1px solid rgb(220, 221, 223); font-size: 20px; box-shadow: 5px 5px rgb(233, 233, 233);padding-bottom: 10px'>
            <div style='text-align: center; border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px'>
              <span style='color: rgb(0,136,202); font-weight: bold; font-size: 25px'>Notes</span>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 3fr 5fr; position: relative'>
              <span style='color: rgb(0,136,202)'>A recipe for:</span>
              <div style='position: absolute;top: 5px;left: 15%; width: 60%'><input placeholder='...'></div>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 3fr 5fr; position: relative'>
              <span style='color: rgb(0,136,202)'>You need: </span>
              <span style='color: rgb(0,136,202)'>&nbsp</span>
              <div style='position: absolute; left:4px; top:4px; width:335px; height: 347px; border: 3px double rgb(0, 136, 202); border-radius: 10px'></div>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 3fr 5fr; position: relative'>
              <span style='color: rgb(0,136,202)'>&nbsp</span>
              <div style='position: absolute;top: 0;left: 0; width: 37.5%'><textarea id='5' rows=7 placeholder='...' ></textarea></div>
              <span style='color: rgb(0,136,202); padding-left: 20px'>First,&nbsp<input placeholder='...'></span>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 3fr 5fr; position: relative'>
              <span style='color: rgb(0,136,202)'>&nbsp</span>
              <span style='color: rgb(0,136,202); padding-left: 20px'>Then,&nbsp<input placeholder='...'></span>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 3fr 5fr; position: relative'>
              <span style='color: rgb(0,136,202)'>&nbsp</span>
              <span style='color: rgb(0,136,202); padding-left: 20px'>Next,&nbsp<input placeholder='...'></span>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 3fr 5fr; position: relative'>
              <span style='color: rgb(0,136,202)'>&nbsp</span>
              <span style='color: rgb(0,136,202); padding-left: 20px'>Finally,&nbsp<input placeholder='...'></span>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 3fr 5fr; position: relative'>
              <span style='color: rgb(0,136,202)'>&nbsp</span>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 3fr 5fr; position: relative'>
              <span style='color: rgb(0,136,202)'>&nbsp</span>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 3fr 5fr; position: relative'>
              <span style='color: rgb(0,136,202)'>&nbsp</span>
            </div>
          </div>
        `,
        InputRong: true,
        answer: [""],
      },
    ],
  },
  3: {
    unit: "Unit 3",
    id: "WB5-2024-U3-P25-E3",
    audio: "",
    video: "",
    component: T6,
    // maxLength: 5,
    // textAlign: "center",
    stylesTextInput: { background: "none", border: "none", paddingLeft: 0 },
    textareaStyle: {
      marginLeft: 7,
      background: "none",
      width: "calc(100% - 70px)",
      resize: "none",
      lineHeight: "1.75em",
      position: "relative",
      zIndex: 1,
      // borderBottom: "1px solid rgb(168, 168, 168)",
    },
    inputSize: 500,
    titleQuestion: [
      {
        num: "3",
        title: "Write a recipe. Use your notes to help you.",
        color: "#2d408e",
        left: 50,
      },
    ],
    hideBtnFooter: true,
    // isHello: true,
    questions: [
      {
        title: `
          <div style='width: 25cm; border: 3px solid rgb(101, 193, 139); padding: 10px 10px 0 10px; border-radius: 10px; position: relative'>
            <div style='border-bottom: 1px solid gray; padding: 5px 10px; display: grid; grid-template-columns: 1fr 3fr; position: relative'>
              <span style='color: gray'>A recipe for:</span>
              <input placeholder='...'>
            </div>
            <div style='border-bottom: 1px solid gray; padding: 5px 10px; display: grid; grid-template-columns: 1fr 3fr; position: relative'>
              <span style='color: gray'>You need: </span>
              <div style='position: absolute;top: 0;left: 24.2%; width: 72.5%'><textarea id='5' rows=7 placeholder='1 ...' ></textarea></div>
            </div>
            <div style='border-bottom: 1px solid gray; padding: 5px 10px; display: grid; grid-template-columns: 1fr 3fr; position: relative'>
              <span style='color: gray'>&nbsp</span>
            </div>
            <div style='border-bottom: 1px solid gray; padding: 5px 10px; display: grid; grid-template-columns: 1fr 3fr; position: relative'>
              <span style='color: gray'>&nbsp</span>
            </div>
            <div style='border-bottom: 1px solid gray; padding: 5px 10px; display: grid; grid-template-columns: 1fr 3fr; position: relative'>
              <span style='color: gray'>&nbsp</span>
            </div>
            <div style='border-bottom: 1px solid gray; padding: 5px 10px; display: grid; grid-template-columns: 1fr 3fr; position: relative'>
              <span style='color: gray'>&nbsp</span>
            </div>
            <div style='border-bottom: 1px solid gray; padding: 5px 10px; display: grid; grid-template-columns: 1fr 3fr; position: relative'>
              <span style='color: gray'>&nbsp</span>
            </div>
            <div style='border-bottom: 1px solid gray; padding: 5px 10px; display: grid; grid-template-columns: 1fr 3fr; position: relative'>
              <span style='color: gray'>&nbsp</span>
            </div>
            <div style='border-bottom: 1px solid gray; padding: 5px 10px; display: grid; grid-template-columns: 1fr 3fr; position: relative'>
              <span style='color: gray'>&nbsp</span>
            </div>
            <div style='position: absolute;top: 10px;left: 24.2%; height: 95%; width: 1px; background: gray'></div>
          </div>
        `,
        answer: [""],
        initialValue: [""],
      },
    ],
  },
};

export default json;
