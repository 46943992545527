import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 2",
    id: "WB5-2024-U2-P18-E1",
    // audio: "Audios/07-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page18/Key/E1answerKey.png",
    textAlign: "center",
    stylesTextInput: {
      fontSize: 25,
      height: "2.5em",
      width: 55,

      background: "none",
      border: "none",
    },
    titleQuestion: [
      {
        num: "1",
        title: "Look and write.",
        color: "#2d408e",
        left: 50,
      },
    ],
    maxLength: 1,
    component: T6,
    questions: [
      {
        title: `
        <div style='position:relative; text-align:center'>
          <img src='img/FriendsPlus/Page18/E1/1.jpg' style='width: 20cm' />
          <div style='position: absolute; top: 75px; left: 352px; display:flex; gap: 1px'>
            <div style='width: 55.5px'>#</div>
            <div style='width: 55.5px'>#</div>
            <div style='width: 55.5px'>#</div>
            <div style='width: 55.5px'>#</div>
            <div style='width: 55.5px'>#</div>
            <div style='width: 55.5px'>#</div>
            <div style='width: 55.5px'>#</div>
          </div>
          <div style='position: absolute; top: 139px; left: 13px;; display:flex; gap: 1px'>
            <div style='width: 55.5px'>#</div>
            <div style='width: 55.5px'>#</div>
            <div style='width: 55.5px'>#</div>
            <div style='width: 55.5px'>#</div>
            <div style='width: 55.5px'>#</div>
            <div style='width: 55.5px'>#</div>
            <div style='width: 55.5px'>#</div>
            <div style='width: 55.5px'>#</div>
            <div style='width: 55.5px'>#</div>
          </div>
          <div style='position: absolute; top: 203px; left: 126px;; display:flex; gap: 1px'>
            <div style='width: 55.5px'>#</div>
            <div style='width: 55.5px'>#</div>
            <div style='width: 55.5px'>#</div>
            <div style='width: 55.5px'>#</div>
            <div style='width: 55.5px'>#</div>
          </div>
        </div>
        `,
        answer: [
          "c",
          "o",
          "n",
          "c",
          "e",
          "r",
          "t",
          "c",
          "h",
          "a",
          "r",
          "a",
          "c",
          "t",
          "e",
          "r",
          "m",
          "o",
          "v",
          "i",
          "e",
        ],
        initialValue: [],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "WB5-2024-U2-P18-E2",
    // audio: "Audios/Page10/E1/audio-e1.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page18/Key/E2answerKey.png",
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "dash",
    },
    inputSize: 100,
    titleQuestion: [
      {
        num: "2",
        title: "Read the passage. Fill in the correct circle.",
        color: "#2d408e",
        left: -33,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          width: "22px",
          height: "22px",
          paddingLeft: 3,
          border: "1px solid",
          borderRadius: "50%",
          color: "transparent",
          backgroundColor: "transparent",
          borderColor: "black",
          fontWeight: "bold",
          lineHeight: "25px",
          fontSize: "18px",
        },
        selectWordStyle: {
          backgroundColor: "gray",
          borderColor: "gray",
        },
        limitSelect: 1,
        listWords: ["✓  ✓  ✓", "✓  ✓  ✓", "✓  ✓  ✓", "✓  ✓  ✓"],
        answers: ["2-8", "0-4", "1-0", "3-8"],
        initialValue: ["0-4"],
      },
      Layout: `
      <div style='font-size:22px'>
        <div style='width:27cm; margin-bottom: 10px'>There are many different places people visit in their free time. Some people go to the movie theater. This is for people who like stories and characters. Lots of children like the skatepark at the playground. These activities are lots of fun. Other people go to concerts to see their favorite singers.</div>
        <div style='width: 27cm; display:grid; grid-template-columns: 1fr 1fr; gap: 30px'>
          <div>
            <span>1. What is the reading about?</span>
            <div style='display:flex; gap: 20px; width: 100%; padding-left: 20px'>
            <div style='display:flex; flex-direction:column; gap: 5px'>
              <span>(A) places in town</span>
              <span>(B) places people go in their free time</span>
              <span>(C) places to meet people</span>
            </div>
            <div style='display:flex; flex-direction:column; gap: 4px; padding-top: 10px; margin-left: auto;'><input id=0 type='Circle'/></div>
            </div>
          </div>
          <div>
            <span>3. Where is the skatepark?</span>
            <div style='display:flex; gap: 20px; width: 100%; padding-left: 20px'>
            <div style='display:flex; flex-direction:column; gap: 5px'>
              <span>(A) at the playground</span>
              <span>(B) at the shopping mall</span>
              <span>(C)  at the movie theater</span>
            </div>
            <div style='display:flex; flex-direction:column; gap: 4px; padding-top: 10px; margin-left: auto;'><input id=1 type='Circle'/></div>
            </div>
          </div>
          <div>
            <span>2. Which place is good for people who like stories?</span>
            <div style='display:flex; gap: 20px; width: 100%; padding-left: 20px'>
              <div style='display:flex; flex-direction:column; gap: 5px'>
              
                <span>(A) the swimming pool</span>
                <span>(B) a concert</span>
                <span>(C)  the movie theater</span>
              </div>
              <div style='display:flex; flex-direction:column; gap: 4px; padding-top: 10px; margin-left: auto;'><input id=2 type='Circle'/></div>
            </div>
          </div>
          <div>
            <span>4. Where can you see a singer?</span>
            <div style='display:flex; gap: 20px; width: 100%; padding-left: 20px'>
                <div style='display:flex; flex-direction:column; gap: 5px'>
                  <span>(A) at the movie theater</span>
                  <span>(B) at the swimming pool</span>
                  <span>(C) at a concert</span>
                </div>
                <div style='display:flex; flex-direction:column; gap: 4px; padding-top: 10px; margin-left: auto;'><input id=3 type='Circle'/></div>
              </div>
            </div>
          </div>
          
      </div>
      
      `,
    },
  },
};
export default json;
