import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Grammar time",
    id: "WB5-2024-GT-P93-E1",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page93/Key/E1answerKey.png",
    video: "",
    component: T6,
    titleQuestion: [
      {
        num: "1",
        title:
          "Write sentences about Jim using the simple past, affirmative and negative.",
        color: "#2d408e",
        left: 50,
        // prefix:[{text:"hello",icon:"fas fa-pen"}]
      },
    ],
    // textAlign: "center",
    inputSize: 450,
    // styleHint: {
    //   // border: "none",
    //   // justifyContent: "center",
    //   display: "inline-flex",
    //   gap: "5px",
    //   paddingInline: 0,
    //   marginLeft: 20,
    //   // borderColor: "#2bbbf1",
    //   // color: "#57585a",
    //   styleElementHintBox: {
    //     background: "#c6eafa",
    //     padding: "5px 10px",
    //     borderRadius: "5px",
    //     margin: 0,
    //   },
    // },
    // checkUppercase: true,
    // hintBox: [
    //   {
    //     src: ["water", "have", "?", "some", "Could", "I"],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["apple", "have", "Could", "I", "?", "that"],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["some", "Could", "?", "cookies", "we", "have"],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["help", "you", "me", "?", "Could"],
    //     width: "max-content",
    //     inline: true,
    //   },
    // ],
    // character: "/",
    // hai , ba , bốn , năm
    // checkUppercase: true,
    stylesTextInput: {
      background: "none",
      marginTop: 20,
    },
    textareaStyle: {
      width: "100%",
      resize: "none",
      paddingTop: 5,
    },
    notification: true,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width: 25cm; display:grid; grid-template-columns: 1fr 1fr; grid-gap: 5px 20px'>
          <span><b>1. </b>finish / his homework / Sunday <b>(✓)</b></span><u style='color: gray; text-underline-offset: 5px'>&nbspHe finished his homework on Sunday.&nbsp</u> 
          <span><b>2. </b>love / his dinner / Saturday <b>(✓)</b></span>#
          <span><b>3. </b>cook / fish / Saturday <b>(✗)</b></span>#
          <span><b>4. </b>hate / his dinner / Sunday <b>(✗)</b></span>#
        </div>
        `,
        answer: [
          "He loved his dinner on Saturday.",
          "He didn't cook fish on Saturday.",
          "He didn't hate his dinner on Sunday.",
        ],
      },
    ],
  },
  2: {
    unit: "Grammar time",
    id: "WB5-2024-GT-P93-E2",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page93/Key/E2answerKey.png",
    video: "",
    component: T6,
    titleQuestion: [
      {
        num: "2",
        title: "Complete the question and write the short answer for you.",
        color: "#2d408e",
        left: 50,
        // prefix:[{text:"hello",icon:"fas fa-pen"}]
      },
    ],
    textAlign: "center",
    inputSize: 100,
    styleHint: {
      // border: "none",
      // justifyContent: "center",
      display: "inline-flex",
      // gap: 15,
      paddingInline: 0,
      // marginLeft: 5,
      border: "1px solid #00aeef",
      marginRadius: 5,
      padding: "5px 20px",
      // color: "#57585a",
      styleElementHintBox: {
        // background: "#c6eafa",
        // padding: "5px 10px",
        // borderRadius: "5px",
        // margin: 0,
      },
    },
    // checkUppercase: true,
    hintBox: [
      {
        src: [
          "play",
          "listen",
          "paint",
          "<span class='strikediag'>walk</span>",
        ],
        width: "max-content",
        inline: true,
      },
    ],
    // character: "/",
    // hai , ba , bốn , năm
    // checkUppercase: true,
    stylesTextInput: {
      background: "none",
      marginTop: 20,
    },
    textareaStyle: {
      width: "100%",
      resize: "none",
      paddingTop: 5,
    },
    notification: true,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='width: 27cm; display:grid; grid-template-columns: 4fr 3fr; grid-gap: 5px 20px'>
          <span style='margin-top: 12px'><b>1. </b><u style='color: gray; text-underline-offset: 5px'>&nbspDid&nbsp</u> you <u style='color: gray; text-underline-offset: 5px'>&nbspwalk&nbsp</u> to school yesterday? </span><input width='400px' notrequired='true' textalign='left'/>
          <span><b>2. </b># you # a picture yesterday? </span><input width='400px' notrequired='true' textalign='left'/>
          <span><b>3. </b># you # to music yesterday? </span><input width='400px' notrequired='true' textalign='left'/>
          <span><b>4. </b># you # soccer yesterday?</span><input width='400px' notrequired='true' textalign='left'/>
        </div>
        `,
        answer: [
          "",
          "Did",
          "paint",
          "",
          "Did",
          "listen",
          "",
          "Did",
          "play",
          "",
        ],
      },
    ],
  },
};

export default json;
