import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 8",
    id: "WB5-2024-U8-P57-E3",
    exerciseKey: "img/FriendsPlus/Page57/Key/E1answerKey.png",
    audio: "Audios/24-audio.mp3",
    video: "",
    inputSize: 200,
    maxLength: 40,
    textAlign: "center",
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen to the chant and write the words. <span style='color: white'><headphone name='&nbsp 24' typeimg=sound src='Audios/24-tieude.mp3'></headphone></span>",
        color: "#203c8f",
        left: 50,
      },
    ],
    component: T6,
    questions: [
      {
        title: `
        <div style='margin-top: 20px; display: flex; gap: 40px'>
          <div style='display: flex; flex-direction: column; gap: 10px'>
            <span>It’s <sup>1</sup><u style='color:gray; text-underline-offset: 5px'>&nbspdeeper than&nbsp</u> a swimming pool.</span>
            <span>It’s <sup>2</sup># a plane.</span>
            <span>It’s <sup>3</sup># the road to school.</span>
            <span>What is it? What’s the name?</span>
          </div>
          <img style='height: 7cm' src='img/FriendsPlus/Page57/E1/1.jpg'/>
        </div>
        `,
        answer: ["wider than", "longer than"],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "WB5-2024-U8-P57-E2",
    exerciseKey: "img/FriendsPlus/Page57/Key/E2answerKey.png",
    audio: "Audios/25-audio.mp3",
    video: "",
    component: Circle_Write,
    // recorder: true,
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and underline the words that sound stronger. <span style='color: white'><headphone name='&nbsp 25' typeimg=sound src='Audios/25-tieude.mp3'></headphone></span>",
        color: "#203c8f",
      },
    ],
    styleContent: {
      width: "90%",
      marginLeft: 50,
    },
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        colorLineTo: "gray",
        // newPointDot: true,
        // path: true,
        // notOverlapse: true,
        circlePadding: "5 1",
        margin: "5px 2px",
        // circleStringArray: ["modelsalivescreambonesdinosaursroarscarydead"],
        boxMatch: [],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        circleInput: true,
        inputStyle: {
          width: 457,
          paddingBottom: 3,
          fontSize: 23,
          borderBottom: "none",
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        newSplit: true,
        initialWordStyle: {
          padding: "1px 3px",
          border: "none",
          background: "white",
          fontSize: 23,
          // fontWeight: "bold",
        },
        selectWordStyle: {
          color: "black",
          borderBottom: "2px solid #00aeef",
        },
        // limitSelect: 1,
        listWords: [
          "A mountain is higher than a building",
          "A cheetah is faster than a cat",
          "Grapes are smaller than melons",
        ],
        answers: [
          "0-1",
          "0-3",
          "0-6",
          "1-1",
          "1-3",
          "1-6",
          "2-0",
          "2-2",
          "2-4",
        ],
        initialValue: [],
      },
      Layout: `
        <div style='margin-top: 20px; display: flex; gap: 30px'>
          <div style='display: flex; flex-direction: column; gap: 10px; font-size: 23px'>
            <span><input id=0 type='Circle'/></span>
            <span><input id=1 type='Circle'/></span>
            <span><input id=2 type='Circle'/></span>
            <span>We all agree with that</span>
          </div>
          <img style='height: 5cm; margin-top: 20px' src='img/FriendsPlus/Page57/E2/1.jpg'/>
        </div>
        `,
    },
  },
  3: {
    unit: "Unit 8",
    id: "WB5-2024-U8-P57-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page57/Key/E3answerKey.png",
    stylesTextInput: { background: "none" },
    textareaStyle: {
      width: "calc(100% - 10px)",
      resize: "none",
      paddingTop: 5,
      // borderBottom: "1px solid rgb(168, 168, 168)",
    },
    inputSize: 200,
    titleQuestion: [
      {
        num: "3",
        title: "Draw and write.",
        color: "#2d408e",
        left: 50,
      },
    ],
    hideBtnFooter: true,
    // isHello: true,
    questions: [
      {
        title: `
          <div style='width: 25cm; display: flex; flex-direction: column; gap: 5px'>
            <span>A # is # than a #.</span>
            <span>A # is # than a #.</span>
            <input width='24.5cm'/>
            <span>We all agree with that.</span>
          </div>
          <div style='width: 25cm; height: 8cm; margin-top: 20px; border: 3px solid rgb(244, 132, 101); padding: 10px 10px 0 10px; border-radius: 10px'>
            
          </div>
        `,
        answer: [""],
        initialValue: [""],
      },
    ],
  },
};

export default json;
