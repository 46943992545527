import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 2",
    id: "WB5-2024-U1-P16-E1",
    // audio: "Audios/03-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page16/Key/E1answerKey.png",
    textAlign: "center",
    stylesTextInput: {
      width: "100%",
    },
    titleQuestion: [
      {
        num: "1",
        title: "Write. <hintbox id=0></hintbox>",
        color: "#2d408e",
        left: 50,
      },
    ],
    component: T6,
    styleHint: {
      border: "2px solid",
      justifyContent: "center",
      // paddingInline: 5,
      // paddingBlock: "0px",
      padding: "0 5px",
      marginTop: -20,
      borderColor: "#1eb26a",
      color: "#57585a",
      styleElementHintBox: {
        // background: "#2bbbf1",
        marginRight: 10,
        padding: "2px 5px",
        fontWeight: 400,
        // borderRadius: "5px",
      },
    },
    hintBox: [
      {
        src: [
          "<span style='color: rgb(0, 186, 241); font-weight: bold'>✓✓✓✓</span><span> = always</span>",
          "<span style='color: rgb(0, 186, 241); font-weight: bold'>✓✓✓</span> = usually",
          "<span style='color: rgb(0, 186, 241); font-weight: bold'>✓✓</span> = often",
          "<span style='color: rgb(0, 186, 241); font-weight: bold'>✓</span> = sometimes",
          "<span style='color: rgb(0, 186, 241); font-weight: bold'>✗</span> = never",
        ],
        width: "24cm",
        inline: true,
      },
    ],
    questions: [
      {
        title: `
          <div style='display:grid; grid-template-columns: 1fr 1fr; grid-gap: 30px; margin-top: 20px'>
            <div style='display: flex; gap: 10px; align-items: center'>
              <img style='height: 3cm' src='img/FriendsPlus/Page16/E1/1.jpg'/>
              <span><span style='color: rgb(0, 186, 241); font-weight: bold'>✓✓✓✓</span><br/>Thanh <u style='color:gray; text-underline-offset: 5px'>&nbspalways plays&nbsp</u> volleyball.</span>
            </div>
            <div style='display: flex; gap: 10px; align-items: center'>
              <img style='height: 3cm' src='img/FriendsPlus/Page16/E1/2.jpg'/>
              <span><span style='color: rgb(0, 186, 241); font-weight: bold'>✓</span><br/>Mai <div style='display: inline-block; width: 290px'>#</div> soccer.</span>
            </div>
            <div style='display: flex; gap: 10px; align-items: center'>
              <img style='height: 3cm' src='img/FriendsPlus/Page16/E1/3.jpg'/>
              <span><span style='color: rgb(0, 186, 241); font-weight: bold'>✓✓</span><br/>Thanh <div style='display: inline-block; width: 290px'>#</div> basketball.</span>
            </div>
            <div style='display: flex; gap: 10px; align-items: center'>
              <img style='height: 3cm' src='img/FriendsPlus/Page16/E1/4.jpg'/>
              <span><span style='color: rgb(0, 186, 241); font-weight: bold'>✗</span><br/>Nam <div style='display: inline-block; width: 290px'>#</div> TV.</span>
            </div>
            <div style='display: flex; gap: 10px; align-items: center'>
              <img style='height: 3cm' src='img/FriendsPlus/Page16/E1/5.jpg'/>
              <span><span style='color: rgb(0, 186, 241); font-weight: bold'>✓✓✓✓</span><br/>Thu <div style='display: inline-block; width: 290px'>#</div>.</span>
            </div>
            <div style='display: flex; gap: 10px; align-items: center'>
              <img style='height: 3cm' src='img/FriendsPlus/Page16/E1/6.jpg'/>
              <span><span style='color: rgb(0, 186, 241); font-weight: bold'>✓✓✓</span><br/> Nam <div style='display: inline-block; width: 290px'>#</div> the dog.</span>
            </div>
          </div>
        `,
        answer: [
          "sometimes plays",
          "often plays",
          "never watches",
          "always plays basketball",
          "usually walks",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "WB5-2024-U1-P16-E2",
    // audio: "Audios/03-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page16/Key/E2answerKey.png",
    textAlign: "center",
    stylesTextInput: {
      width: 300,
    },
    titleQuestion: [
      {
        num: "2",
        title:
          "Write true sentences about you. Use never, usually, often, sometimes, or always.",
        color: "#2d408e",
        left: 50,
      },
    ],
    component: T6,
    hideBtnFooter: true,
    questions: [
      {
        title: `
          <div style='display: flex; flex-direction: column; gap: 10px'>
            <span><b>1. </b><u style='color:gray; text-underline-offset: 5px'>&nbspI never go to&nbsp</u>the playground.</span>
            <span><b>2. </b>#to the playground after school.</span>
            <span><b>3. </b>#to the shopping mall with my family.</span>
            <span><b>4. </b>#to the skatepark with my friends.</span>
            <span><b>5. </b>#to the movie theater.</span>
          </div>
        `,
        answer: [],
      },
    ],
  },

  3: {
    unit: "Unit 2",
    id: "WB5-2024-U1-P16-E3",
    audio: "Audios/05-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page16/Key/E3answerKey.png",
    textAlign: "center",
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen to the song. Check (✓) the boxes. <span style='color: white'><headphone name='&nbsp 05' typeimg=sound src='Audios/05-tieude.mp3'></headphone></span>",
        color: "#2d408e",
        left: -33,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "580px",
              width: "30px",
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "350px",
              width: "30px",
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "580px",
              width: "30px",
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "171px",
              left: "350px",
              width: "30px",
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 4
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        isHiddenCheck: true,
        newSplit: true,
        initialWordStyle: {
          border: "2px solid",
          color: "transparent",
          margin: "-2px 0 0 -2px",
          height: "calc(100% + 1px)",
          borderColor: "transparent",
          fontWeight: "bold",
          textAlign: "center",
        },
        selectWordStyle: {
          color: "gray",
          borderColor: "transparent",
        },
        limitSelect: 1,
        listWords: [
          "✓ ✓ ✓",
          "✓ ✓ ✓",
          "✓ ✓ ✓",
          "✓ ✓ ✓",
          "✓ ✓ ✓",
          "✓ ✓ ✓",
          "✓ ✓ ✓",
          "✓ ✓ ✓",
          "✓ ✓ ✓",
          "✓ ✓ ✓",
          "✓ ✓ ✓",
          "✓ ✓ ✓",
        ],
        answers: [
          "0-1",
          "1-2",
          "2-1",
          "3-1",
          "4-1",
          "5-1",
          "6-2",
          "7-0",
          "8-0",
          "9-1",
          "10-1",
          "11-2",
        ],
        initialValue: ["0-1"],
      },
      Layout: `
      <div style='display:flex; gap:20px; margin-top: 10px; font-size: 20px;'>
        <img style='height: 10cm' src='img/FriendsPlus/Page16/E3/1.jpg'/>
        <table class='table-question' >
          <tr style='border:none'>
            <th style='width: 150px'>never</th>
            <th style='width: 150px'>sometimes</th>
            <th style='width: 150px'>always</th>
            <th style='border:none; background: none'></th>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>play computer games</td>
            <div style='width: 450px; position: absolute; top: 0; left: 0; display: grid; grid-template-columns: 1fr 1fr 1fr'><input id='0' type='Circle' /></div>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>ride my bike</td>
            <div style='width: 450px; position: absolute; top: 0; left: 0; display: grid; grid-template-columns: 1fr 1fr 1fr'><input id='1' type='Circle' /></div>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>watch a DVD</td>
            <div style='width: 450px; position: absolute; top: 0; left: 0; display: grid; grid-template-columns: 1fr 1fr 1fr'><input id='2' type='Circle' /></div>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>fly my kite</td>
            <div style='width: 450px; position: absolute; top: 0; left: 0; display: grid; grid-template-columns: 1fr 1fr 1fr'><input id='3' type='Circle' /></div>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>snorkel in the sea</td>
            <div style='width: 450px; position: absolute; top: 0; left: 0; display: grid; grid-template-columns: 1fr 1fr 1fr'><input id='4' type='Circle' /></div>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>read a book</td>
            <div style='width: 450px; position: absolute; top: 0; left: 0; display: grid; grid-template-columns: 1fr 1fr 1fr'><input id='5' type='Circle' /></div>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>play my new guitar</td>
            <div style='width: 450px; position: absolute; top: 0; left: 0; display: grid; grid-template-columns: 1fr 1fr 1fr'><input id='6' type='Circle' /></div>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>shop</td>
            <div style='width: 450px; position: absolute; top: 0; left: 0; display: grid; grid-template-columns: 1fr 1fr 1fr'><input id='7' type='Circle' /></div>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>cook</td>
            <div style='width: 450px; position: absolute; top: 0; left: 0; display: grid; grid-template-columns: 1fr 1fr 1fr'><input id='8' type='Circle' /></div>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>surf the Internet</td>
            <div style='width: 450px; position: absolute; top: 0; left: 0; display: grid; grid-template-columns: 1fr 1fr 1fr'><input id='9' type='Circle' /></div>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>watch TV</td>
            <div style='width: 450px; position: absolute; top: 0; left: 0; display: grid; grid-template-columns: 1fr 1fr 1fr'><input id='10' type='Circle' /></div>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>have a lot of fun</td>
            <div style='width: 450px; position: absolute; top: 0; left: 0; display: grid; grid-template-columns: 1fr 1fr 1fr'><input id='11' type='Circle' /></div>
          </tr>
        </table>
      </div>
      `,
    },
  },
};

export default json;
