import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Review 4",
    id: "WB5-2024-R4-P86-E1",
    audio: "Audios/35-audio.mp3",
    exerciseKey: "img/FriendsPlus/Page86/Key/E1answerKey.png",
    video: "",
    component: T6,
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen and write. <span style='color: white'><headphone name='&nbsp 35' typeimg=sound src='Audios/35-tieude.mp3'></headphone></span>",
        color: "#2d408e",
        left: 50,
        // prefix:[{text:"hello",icon:"fas fa-pen"}]
      },
    ],
    textAlign: "center",
    inputSize: 200,
    // styleHint: {
    //   // border: "none",
    //   // justifyContent: "center",
    //   display: "inline-flex",
    //   gap: "5px",
    //   paddingInline: 0,
    //   // borderColor: "#2bbbf1",
    //   // color: "#57585a",
    //   styleElementHintBox: {
    //     background: "#c6eafa",
    //     padding: "2px 5px",
    //     borderRadius: "5px",
    //     margin: 0,
    //   },
    // },
    // hintBox: [
    //   {
    //     src: ["is", "going", "Where", "to", "she", "go", "?"],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["you", "watch", "TV", "going", "Are", "to", "?"],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["they", "are", "eat", "going", "What", "to", "?"],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["he", "to", "going", "play", "soccer", "Is", "?"],
    //     width: "max-content",
    //     inline: true,
    //   },
    // ],
    stylesTextInput: {
      background: "none",
    },
    // character: "",
    // hai , ba , bốn , năm
    // checkUppercase: true,
    questions: [
      {
        title: `
        <div style='width: 25cm; display:flex; flex-direction: column; gap: 7px'>
          <span><b>1. </b>Duy can't find the <u style='color:gray; text-underline-offset: 5px'>&nbspremote control&nbsp</u>.</span>
          <span><b>2. </b>Hoa wants to watch a #.</span>
          <span><b>3. </b>Duy wants to watch a #.</span>
          <span><b>4. </b>Duy and Hoa like watching #.</span>
          <span><b>5. </b>The program they want to watch is on Channel #.</span>
        </div>
        `,
        answer: ["quiz program", "movie", "cartoons", "3"],
      },
    ],
  },
  2: {
    unit: "Review 4",
    id: "WB5-2024-R4-P86-E2",
    // audio: "Audios/35-audio.mp3",
    exerciseKey: "img/FriendsPlus/Page86/Key/E2answerKey.png",
    video: "",
    component: T6,
    titleQuestion: [
      {
        num: "2",
        title: "Read. Complete the text.",
        color: "#2d408e",
        left: 50,
        // prefix:[{text:"hello",icon:"fas fa-pen"}]
      },
    ],
    textAlign: "center",
    inputSize: 200,
    // styleHint: {
    //   // border: "none",
    //   // justifyContent: "center",
    //   display: "inline-flex",
    //   gap: "5px",
    //   paddingInline: 0,
    //   // borderColor: "#2bbbf1",
    //   // color: "#57585a",
    //   styleElementHintBox: {
    //     background: "#c6eafa",
    //     padding: "2px 5px",
    //     borderRadius: "5px",
    //     margin: 0,
    //   },
    // },
    // hintBox: [
    //   {
    //     src: ["is", "going", "Where", "to", "she", "go", "?"],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["you", "watch", "TV", "going", "Are", "to", "?"],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["they", "are", "eat", "going", "What", "to", "?"],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["he", "to", "going", "play", "soccer", "Is", "?"],
    //     width: "max-content",
    //     inline: true,
    //   },
    // ],
    stylesTextInput: {
      background: "none",
    },
    // character: "",
    // hai , ba , bốn , năm
    // checkUppercase: true,
    questions: [
      {
        title: `
        <div style='position: relative'>
          <img style='width: 25cm' src='img/FriendsPlus/Page86/E2/1.jpg'/>
          <div style='position: absolute; top: 110px; left: 80px; text-align: center'>
            In 100 years, the world <span style='text-wrap: nowrap'><sup>1</sup><u style='color:gray; text-underline-offset: 5px'>&nbspwon't be&nbsp</u></span> (not be) the same. Robots <br><span style='text-wrap: nowrap'><sup>2</sup>#</span> (help) us at home and we <span style='text-wrap: nowrap'><sup>3</sup>#</span> (have) <br>robots at home too—my robot <span style='text-wrap: nowrap'><sup>4</sup>#</span> (do) my <br>homework with me!<br>We <span style='text-wrap: nowrap'><sup>5</sup>#</span> (travel) to different places on supersonic planes, <br>so it <span style='text-wrap: nowrap'><sup>6</sup>#</span> (not take) long to get there.<br>And <span style='text-wrap: nowrap'><sup>7</sup>#</span> there # (be) <br>classrooms in the future? Maybe not!
          </div>
        </div>
        `,
        answer: [
          "will help",
          "will have",
          "will do",
          "will travel",
          "won't take",
          "will",
          "be",
        ],
      },
    ],
  },
};

export default json;
