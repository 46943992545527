import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Unit 10",
    id: "WB5-2024-U10-P72-E1",
    exerciseKey: "img/FriendsPlus/Page72/Key/E1answerKey.png",
    audio: "",
    video: "",
    inputSize: 150,
    maxLength: 80,
    textAlign: "center",
    titleQuestion: [
      {
        num: "1",
        title: "Read. Choose the correct word.",
        color: "#203c8f",
        left: 55,
        top: 100,
      },
    ],
    component: T6,
    // hideBtnFooter: true,
    stylesTextInput: {
      paddingLeft: 0,
    },
    questions: [
      {
        title: `
        <img src='img/FriendsPlus/Page72/E1/1.jpg' style='width: 25cm' />
        <div style='margin-top: 10px;display: flex; flex-direction: column; gap: 7px'>
          <span><b>1. </b>When you move your body to keep it strong and well. <u style='color:gray; text-underline-offset: 5px'>&nbspexercise&nbsp</u></span>
          <span><b>2. </b>This word describes someone who eats well and doesn’t get sick. #</span> 
          <span><b>3. </b>A food with lots of salt. It’s not very healthy. #</span>
          <span><b>4. </b>Something in food and drink to make it sweet. #</span>
          <span><b>5. </b>Exercise makes you feel this. #</span>
        </div>
        `,
        answer: ["healthy", "chips", "sugar", "strong"],
      },
    ],
  },
  2: {
    unit: "Unit 10",
    id: "WB5-2024-U10-P72-E2",
    // audio: "Audios/22-audio.mp3",
    video: "",

    exerciseKey: "img/FriendsPlus/Page72/Key/E2answerKey.png",
    // maxLength: 5,
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "dash",
    },
    inputSize: 100,
    titleQuestion: [
      {
        num: "2",
        title:
          "Read the Student Book. Answer the questions. Circle the correct answer.",
        color: "#203c8f",
        left: -35,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0 3px 0 4px",
          border: "2px solid",
          borderRadius: "50%",
          borderColor: "transparent",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        newSplit: true,
        listWords: ["a. b. c.", "a. b. c."],
        answers: ["0-1", "1-0"],
        initialValue: [],
      },
      Layout: `
      <div style='width: 25cm; display: flex; gap: 20px'>
        <div style='width: 26cm; display: flex; flex-direction: column; gap: 5px'>
          <span><b>1. </b>What is the article about? </span>
          <div style='display: flex; gap: 2px; margin-left: 20px'>
            <div style='display: flex; flex-direction: column; gap: 5px'><input id='0' type='Circle' /></div>
            <div style='display: flex; flex-direction: column; gap: 9px; margin-top: 2px'>
              <span>to help people make healthy food</span>
              <span>to tell people how to stay healthy</span>
              <span>to tell people about sports clubs and<br>healthy cafés</span>
            </div>
          </div>
          <span><b>2. </b>What makes you feel stronger?</span>
          <div style='display: flex; gap: 2px; margin-left: 20px'>
            <div style='display: flex; flex-direction: column; gap: 5px'><input id='1' type='Circle' /></div>
            <div style='display: flex; flex-direction: column; gap: 9px; margin-top: 2px'>
              <span>doing lots of exercise</span>
              <span>eating candy and chips</span>
              <span>drinking juice</span>
            </div>
          </div>
        </div>
        <img style='height: 8cm; margin-top: auto' src='img/FriendsPlus/Page72/E2/1.jpg'/>
      </div>
      `,
    },
  },
  4: {
    unit: "Review 3",
    id: "SB5-2024-R3-P72-E1",
    audio: "",
    video: "",
    component: MatchDots,
    exerciseKey: "img/FriendsPlus/Page72/E4/Key/answerKey.png",
    stylesTextInput: { borderBottom: "dotted" },
    inputSize: 700,
    maxLength: 200,
    checkUppercase: true,
    // exerciseKey: "img/FriendsPlus/Page32/E8/Key/answerKey.png",
    titleQuestion: [
      {
        num: "",
        title:
          "<img src='img/FriendsPlus/Page72/E4/15.jpg' /> <br> <img src='img/FriendsPlus/Page72/E4/1.jpg' /> ",
        color: "",
      },
    ],
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "580px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "580px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "171px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "171px",
              left: "580px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "235px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "235px",
              left: "580px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
        ],
        answers: ["0-5", "1-4", "2-7", "3-6"],
        initialValue: [],
      },
      Layout: `
        <div style="font-size: 30px;">
					

          <div style='display: flex;padding-top: 5mm'>
            <div ><b>1</b> I don’t like fish.</div>
						<div><input id='5' type= 'boxMatch' /></div>
            <div><input id='1' type= 'boxMatch' /></div>
						<div style='margin-left: 380px;'><b>a.</b> They are not my dogs.</div>
          </div>

					<div style='display: flex;padding-top: 5mm'>
            <div><b>2</b> It isn’t banana.</div>
						<div><input id='2' type= 'boxMatch' /></div>
            <div><input id='3' type= 'boxMatch' /></div>
						<div style='margin-left: 383px;'><b>b.</b> I do not have blue eyes.</div>
          </div>
					
					<div style='display: flex;padding-top: 5mm'>
            <div ><b>3</b>  They aren’t my dogs.</div>
						<div><input id='4' type= 'boxMatch' /></div>
            <div><input id='0' type= 'boxMatch' /></div>
						<div style='margin-left: 300px;'><b>c.</b> I do not like fish.</div>
          </div>
					<div style='display: flex;padding-top: 5mm'>
            <div ><b>4</b> I don’t have blue eyes.</div>
						<div><input id='6' type= 'boxMatch' /></div>
            <div><input id='7' type= 'boxMatch' /></div>
						<div style='margin-left: 278px;'><b>d.</b> It is not a banana.</div>
          </div>
        </div>
      `,
    },
  },
  // 4: {
  //   unit: 'Review 3',
  //   id: 'SB5-2024-R3-P72-E1',
  //   audio: '',
  //   video: '',
  //   component: Match_Write,
  //   exerciseKey: 'img/FriendsPlus/Page72/E4/Key/answerKey.png',
  //   titleQuestion: [
  //     {
  //       num: '',
  //       title: "<img src='img/FriendsPlus/Page72/E4/15.jpg' /> <br> <img src='img/FriendsPlus/Page72/E4/1.jpg' /> ",
  //       color: '',
  //     },
  //   ],
  //   question: {
  //     Match: {
  //       answers: ['0-5', '1-4', '2-7', '3-6'],
  //       coordinates: [
  //         { x: 222, y: 200 },
  //         { x: 444, y: 200 },
  //         { x: 666, y: 200 },
  //         { x: 888, y: 200 },
  //         { x: 222, y: 400 },
  //         { x: 444, y: 400 },
  //         { x: 666, y: 400 },
  //         { x: 888, y: 400 },
  //       ],
  //       // height: 600,
  //       isHorizontal: false,
  //       width: 1110,
  //     },
  //     Write: {
  //       answers: ['hello', 'hi', 'now', 'future'],
  //       commonStyles: {},
  //     },
  //     Layout: `
  //       <div>
  //       <input id='0' />
  //       <input id='1' />
  //       <canvas></canvas>
  //       <input id='2' />
  //       <input id='3' width='50px' />
  //       </div>
  //     `,
  //   },
  // },
};
export default json;
