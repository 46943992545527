import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 11",
    id: "WB5-2024-U11-P78-E1",
    // audio: "Audios/05-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page78/Key/E1answerKey.png",
    textAlign: "center",
    titleQuestion: [
      {
        num: "1",
        title: "Find and circle the words.",
        color: "#2d408e",
        left: -30,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: true,
        colorLineTo: "#40c7f4",
        newPointDot: true,
        // notOverlapse: true,
        circlePadding: "0 0",
        // margin: "0 0 0 10px",
        padding: "4px 0px 4px 15px",
        border: "1px solid",
        // transform: "translate(50%)",
        circleStringArray: [
          "mixloessa",
          "alttunktd",
          "rrscmgzof",
          "oorsfwurt",
          "bawhfyumg",
          "odvyoedgs",
          "tftraffic",
          "crowdedcb",
        ],
        boxMatch: [],
        answers: ["6_2-6_8", "0_7-4_7", "2_1-5_1", "7_0-7_6", "2_0-6_0"],
        initialValue: ["6_2-6_8"],
      },
      Write: {
        inputStyle: { width: 200, textAlign: "center" },
        answers: [],
        initialValue: [],
      },
      Circle: {
        isHiddenCheck: true,
        newSplit: true,
        initialWordStyle: {
          border: "2px solid",
          borderColor: "transparent",
          padding: "1px 5px",
          borderRadius: "50%",
          fontSize: 20,
        },
        selectWordStyle: {
          borderColor: "#40c7f4",
        },
        limitSelect: 1,
        listWords: [],
        answers: [],
        initialValue: [],
      },
      Layout: `
      <div style='width: 26cm; display: flex; gap: 20px'>
        <img style='height:8.5cm' src='img/FriendsPlus/Page78/E1/1.jpg'/>
          <div id='circle-container' style='position: relative; width: 11cm;  display: grid; grid-template-columns: repeat(9, 1fr)'>
            <input id=0 type='boxMatch'/>
            <input id=1 type='boxMatch'/>
            <input id=2 type='boxMatch'/>
            <input id=3 type='boxMatch'/>
            <input id=4 type='boxMatch'/>
            <input id=5 type='boxMatch'/>
            <input id=6 type='boxMatch'/>
            <input id=7 type='boxMatch'/>
          </div>
      </div>
      `,
    },
  },
  2: {
    unit: "Unit 11",
    id: "WB5-2024-U11-P78-E2",
    exerciseKey: "img/FriendsPlus/Page78/Key/E2answerKey.png",
    audio: "",
    video: "",
    inputSize: 200,
    maxLength: 80,
    textAlign: "center",
    titleQuestion: [
      {
        num: "2",
        title: "Write. Use the words above.",
        color: "#203c8f",
        left: 55,
      },
    ],
    component: T6,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <div style='display: flex; flex-direction: column; gap: 10px'>
            <span><b>1. </b>A kind of computer that can move and work. <u style='color:gray; text-underline-offset: 5px'>&nbsprobot&nbsp</u></span>
            <span><b>2. </b>When there are lots of people in a small space, the place is #</span>
            <span><b>3. </b>Heavy rain and thunder. #</span>
            <span><b>4. </b>When there are too many cars on the road, there is a lot of #</span>
            <span><b>5. </b>Cars go along this. #</span>
          </div>
        
        `,
        answer: ["crowded", "storm", "traffic", "road"],
      },
    ],
  },
  3: {
    unit: "Unit 11",
    id: "WB5-2024-U11-P78-E3",
    exerciseKey: "img/FriendsPlus/Page78/Key/E3answerKey.png",
    audio: "",
    video: "",
    inputSize: 50,
    maxLength: 1,
    textAlign: "center",
    titleQuestion: [
      {
        num: "3",
        title:
          "Read the text in the Student Book. Write T (true) or F (false).",
        color: "#203c8f",
        left: 55,
      },
    ],
    component: T6,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width: 25cm; display: flex; gap: 40px; align-items: end'>
          <div style='display: flex; flex-direction: column; gap: 10px'>
            <span><b>1. </b>Robots will help us in the house. <u style='color:gray; text-underline-offset: 5px'>&nbspT&nbsp</u></span>
            <span><b>2. </b>Robots will help us with our homework. #</span>
            <span><b>3. </b>There won’t be lots of people on Earth. #</span>
            <span><b>4. </b>There will be roads in the sky. #</span>
            <span><b>5. </b>It will be colder. #</span>
            <span><b>6. </b>There will be more snow.#</span>
          </div>
          <img src='img/FriendsPlus/Page78/E3/1.jpg' style='width: 7cm'/>
        </div>
        
        `,
        answer: ["T", "F", "T", "F", "F"],
      },
    ],
  },
};

export default json;
