import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Unit 12",
    id: "WB5-2024-U12-P84-E1",
    exerciseKey: "img/FriendsPlus/Page84/Key/E1answerKey.png",
    // audio: "Audios/33-audio.mp3",
    video: "",
    inputSize: 170,
    maxLength: 1,
    textAlign: "center",
    titleQuestion: [
      {
        num: "1",
        title: "Match the words with the pictures.",
        color: "#203c8f",
        left: 55,
      },
    ],
    styleHint: {
      border: "2px solid",
      justifyContent: "center",
      paddding: "0 10px",
      paddingBlock: "2px",
      borderColor: "#4d5a9e",
      color: "#57585a",
      marginLeft: "-27px",
      styleElementHintBox: {
        margin: "0px 15px",
        fontWeight: "normal",
      },
    },
    hintBox: [
      {
        src: [
          "<b>a</b> cartoon",
          "<b>b</b> channel",
          "<b>c</b><span class='strikediag'> team</span>",
          "<b>d</b> stadium",
          "<b>e</b> quiz program",
        ],
        width: "max-content",
        inline: true,
      },
    ],
    component: T6,
    // hideBtnFooter: true
    stylesTextInput: {
      background: "none",
      border: "none",
      width: 30,
      height: 30,
      padding: 5,
    },
    questions: [
      {
        InputRong: true,
        title: `
        <hintbox id='0'></hintbox>
        <div style='margin-top: 20px; width: 25cm; display: flex; gap: 15px'>
          <div style='position: relative'>
            <img style='width: 100%' src='img/FriendsPlus/Page84/E1/1.jpg'/>
          </div>
          <div style='position: relative'>
            <img style='width: 100%' src='img/FriendsPlus/Page84/E1/2.jpg'/>
            <div style='position: absolute; bottom: 0; right: 0'>
              #
            </div>
          </div>
          <div style='position: relative'>
            <img style='width: 100%' src='img/FriendsPlus/Page84/E1/3.jpg'/>
            <div style='position: absolute; bottom: 0; right: 0'>
              #
            </div>
          </div>
          <div style='position: relative'>
            <img style='width: 100%' src='img/FriendsPlus/Page84/E1/4.jpg'/>
            <div style='position: absolute; bottom: 0; right: 2px'>
              #
            </div>
          </div>
          <div style='position: relative'>
            <img style='width: 100%' src='img/FriendsPlus/Page84/E1/5.jpg'/>
            <div style='position: absolute; bottom: 0; right: 0'>
              #
            </div>
          </div>
        </div>
        `,
        answer: ["e", "a", "d", "b"],
      },
    ],
  },
  2: {
    unit: "Unit 12",
    id: "WB5-2024-U12-P84-E2",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page84/Key/E2answerKey.png",
    video: "",
    component: T6,
    titleQuestion: [
      {
        num: "2",
        title:
          "Read the text in the Student Book. Write some words to complete the sentences.",
        color: "#2d408e",
        left: 50,
        // prefix:[{text:"hello",icon:"fas fa-pen"}]
      },
    ],
    textAlign: "center",
    inputSize: 250,
    // styleHint: {
    //   // border: "none",
    //   // justifyContent: "center",
    //   display: "inline-flex",
    //   gap: "5px",
    //   paddingInline: 0,
    //   // borderColor: "#2bbbf1",
    //   // color: "#57585a",
    //   styleElementHintBox: {
    //     background: "#c6eafa",
    //     padding: "2px 5px",
    //     borderRadius: "5px",
    //     margin: 0,
    //   },
    // },
    // hintBox: [
    //   {
    //     src: ["is", "going", "Where", "to", "she", "go", "?"],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["you", "watch", "TV", "going", "Are", "to", "?"],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["they", "are", "eat", "going", "What", "to", "?"],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["he", "to", "going", "play", "soccer", "Is", "?"],
    //     width: "max-content",
    //     inline: true,
    //   },
    // ],
    stylesTextInput: {
      background: "none",
    },
    // character: "",
    // hai , ba , bốn , năm
    // checkUppercase: true,
    questions: [
      {
        title: `
        <div style='width: 27cm; display:flex; flex-direction: column; gap: 5px'>
          <span><b>1. </b>Gru wants to <u style='margin-top: 15px; color:gray; text-underline-offset: 5px'>&nbsptake the moon&nbsp</u>.</span>
          <span><b>2. </b>Gru's life changes because of #.</span>
          <span><b>3. </b>Tom likes <input width='350px'/>.</span>
          <span><b>4. </b>Tom is going to #.</span>
          <span><b>5. </b>Tonight Viet Nam and Thailand are going to play at <input width='400px'/>.</span>
          <span><b>6. </b>To win the quiz program, you must be #.</span>
        </div>
        `,
        answer: [
          "three little girls",
          "chasing a mouse called Jerry",
          "chase Jerry up a tree",
          "the My Dinh National Stadium",
          "very clever",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 12",
    id: "WB5-2024-U12-P84-E3",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page84/Key/E3answerKey.png",
    video: "",
    component: T6,
    titleQuestion: [
      {
        num: "3",
        title: "Read the text in the Student Book. Answer the questions.",
        color: "#2d408e",
        left: 50,
        // prefix:[{text:"hello",icon:"fas fa-pen"}]
      },
    ],
    // textAlign: "center",
    inputSize: "24cm",
    // styleHint: {
    //   // border: "none",
    //   // justifyContent: "center",
    //   display: "inline-flex",
    //   gap: "5px",
    //   paddingInline: 0,
    //   // borderColor: "#2bbbf1",
    //   // color: "#57585a",
    //   styleElementHintBox: {
    //     background: "#c6eafa",
    //     padding: "2px 5px",
    //     borderRadius: "5px",
    //     margin: 0,
    //   },
    // },
    // hintBox: [
    //   {
    //     src: ["is", "going", "Where", "to", "she", "go", "?"],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["you", "watch", "TV", "going", "Are", "to", "?"],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["they", "are", "eat", "going", "What", "to", "?"],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["he", "to", "going", "play", "soccer", "Is", "?"],
    //     width: "max-content",
    //     inline: true,
    //   },
    // ],
    stylesTextInput: {
      background: "none",
      marginLeft: 25,
    },
    // character: "",
    // hai , ba , bốn , năm
    // checkUppercase: true,
    questions: [
      {
        title: `
        <div style='width: 25cm; display:flex; flex-direction: column; gap: 10px'>
          <span><b>1. </b>Which two shows start at the same time?</span>
          <u style='margin-left: 25px; color:gray; text-underline-offset: 5px'>&nbsp&nbspThe soccer game and the quiz program.&nbsp</u>
          <span><b>2. </b>What color are Gru's little friends? </span>#
          <span><b>3. </b>Who is going to catch a mouse?</span>#
          <span><b>4. </b>Where is the soccer game tonight?</span>#
          <span><b>5. </b>Which two teams are going to play soccer?</span>#
          <span><b>6. </b>Who is in the quiz program?</span>#
        </div>
        `,
        answer: [
          "They're yellow.",
          "Tom|Tom is going to catch a mouse. ",
          "It's at the My Dinh National Stadium.",
          "Viet Nam and Thailand|They are Viet Nam and Thailand.|Viet Nam and Thailand are going to play soccer.",
          "Children|Children are in the quiz program.",
        ],
      },
    ],
  },
};

export default json;
