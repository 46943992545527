import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 6",
    id: "WB5-2024-U6-P43-E1",
    component: T6,
    textAlign: "left",
    exerciseKey: "img/FriendsPlus/Page43/Key/E1answerKey.png",
    inputSize: 250,
    maxLength: 15,
    questionImage: [],
    checkUppercase: true,
    styleHint: {
      border: "2px solid",
      justifyContent: "center",
      paddingInline: 5,
      paddingBlock: "0px",
      borderColor: "red",
      color: "#57585a",
      styleElementHintBox: {
        marginRight: 25,
        padding: "2px 4px",
      },
    },
    hintBox: [
      {
        src: [
          "<span class='strikediag'>folded</span>",
          "kicked ",
          "cleaned",
          "loved",
          "liked <br>",
          "started",
          "finished",
          "lived",
          "wanted",
          "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;",
        ],
        width: "15cm",
      },
    ],

    titleQuestion: [
      {
        num: "1",
        title: "Write the words in the correct column.",
        color: "#203c8f",
        left: 77,
      },
    ],

    questions: [
      {
        title: `
        <hintbox id=0></hintbox> 
        <table style="width: 25cm;margin-top:20px; border-radius: 10px">
        <thead>
            <tr style="text-align: center;border: 2.5px solid orangered; background-color:peachpuff; color: red;">
                <th style="border: 2px solid rgb(241, 89, 33); border-width: 0 2px 0 0">played</th>
                <th style="border: 2px solid rgb(241, 89, 33); border-width: 0 2px 0 0">painted</th>
                <th style="border: 2px solid rgb(241, 89, 33); border-width: 0 2px 0 0">watched</th>
            </tr>
        </thead>
        <tbody style="border: 2.5px solid rgb(241, 89, 33);text-align: center;">
            <tr>
                <td style="border: 2px solid rgb(241, 89, 33); border-width: 0 2px 0 0">#</td>
                <td style="border: 2px solid rgb(241, 89, 33); border-width: 0 2px 0 0">
                  <u style='margin-left: -155px; color:gray; text-underline-offset: 5px'>&nbsp;&nbsp;folded&nbsp;&nbsp;</u>
                </td>
                <td style="border: 2px solid rgb(241, 89, 33); border-width: 0 2px 0 0">#</td>
            </tr>


            <tr>
                <td style="border: 2px solid rgb(241, 89, 33); border-width: 0 2px 0 0">#</td>
                <td style="border: 2px solid rgb(241, 89, 33); border-width: 0 2px 0 0">#</td>
                <td style="border: 2px solid rgb(241, 89, 33); border-width: 0 2px 0 0">#</td>
            </tr>

              <tr>
                <td style="border: 2px solid rgb(241, 89, 33); border-width: 0 2px 0 0">#</td>
                <td style="border: 2px solid rgb(241, 89, 33); border-width: 0 2px 0 0">#</td>
                <td style="border: 2px solid rgb(241, 89, 33); border-width: 0 2px 0 0; padding-bottom: 10px">#</td>
            </tr>
        </tbody>
    </table>
        `,
        answer: [
          "loved|lived|cleaned",
          "kicked|finished|liked",
          "loved|lived|cleaned",
          "started|wanted",
          "kicked|finished|liked",
          "loved|lived|cleaned",
          "started|wanted",
          "kicked|finished|liked",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 6",
    id: "WB5-2024-U6-P43-E2",
    audio: "Audios/17-audio.mp3",
    exerciseKey: "img/FriendsPlus/Page43/Key/E2answerKey.png",
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "dash",
    },
    inputSize: 100,
    titleQuestion: [
      {
        num: "2",
        title:
          "Circle the correct word. Then listen and check.<span style='color: white'><headphone name='&nbsp 17' typeimg=sound src='Audios/17-tieude.mp3'></headphone></span>",
        color: "#2d408e",
        left: -33,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 5px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
          borderRadius: "50%",
        },
        selectWordStyle: {
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "painted / cleaned",
          "painted / played",
          "folded / painted",
          "folded / watched ",
          "watched / cleaned",
          "played / kicked ",
        ],
        answers: ["0-4", "2-4", "4-0", "5-4", "6-0", "1-4", "3-0"],
        initialValue: ["0-4"],
      },
      Layout: `
    
<div style="position: relative; z-index: 1; ">
    <span>I <sup>1</sup><input id="0" type="Circle"/> my room.</span><br>
    <span>I <sup>2</sup><input id="1" type="Circle"/> for hours.</span><br>
    <span>I <sup>3</sup><input id="2" type="Circle"/> a picture.</span><br>
    <span>I <sup>4</sup><input id="3" type="Circle"/>some flowers.</span><br>
    <span>I <sup>5</sup><input id="4" type="Circle"/>TV.</span><br>
    <span>I <sup>6</sup><input id="5" type="Circle"/> a ball.</span><br>
    I was so busy, <br>All day long!
</div>
<div style="position: relative">
    <img style="margin-left: 175px; margin-top: -230px; width: 22cm" src="img/FriendsPlus/Page43/E2/1.jpg" />
</div>


      `,
    },
  },
};

export default json;
