import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 7",
    id: "WB5-2024-U7-P51-E1",
    audio: "Audios/21-audio.mp3",
    exerciseKey: "img/FriendsPlus/Page51/Key/E1answerKey.png",
    video: "",
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen and color dots. <span style='color: white'><headphone name='&nbsp 21' typeimg=sound src='Audios/21-tieude.mp3'></headphone></span>",
        color: "#203c8f",
        left: -20,
      },
    ],
    stylesTextInput: { width: 540 },
    // textAlign:'center',
    component: Circle_Write,

    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        elementBefore: {
          width: "1rem",
          height: "1rem",
          borderRadius: "50%",
          background: "transparent",
        },
        selectElementBefore: {
          width: "1rem",
          height: "1rem",
          borderRadius: "50%",
          background: "red",
        },
        newSplit: true,
        initialWordStyle: {
          padding: "1px 4px",
          border: "none",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          border: "none",
        },
        limitSelect: 1,
        listWords: [
          "Did you go to the zoo",
          "Did he like the movie",
          "Did they go on vacation",
          "Did she go on foot",
        ],
        answers: ["0-2", "1-2", "2-2", "3-2"],
        initialValue: ["0-2"],
      },
      Layout: `
      
      <div style='display:grid; grid-template-columns: 1fr 1fr;grid-gap: 0 50px'>
          <span><span style="display: inline-flex; align-items: end"><b>1.</b><input id=0 type='Circle'/>?</span></span>
          <span><span style="display: inline-flex; align-items: end"><b>2.</b><input id=1 type='Circle'/>?</span></span>
          <span><span style="display: inline-flex; align-items: end"><b>3.</b><input id=2 type='Circle'/>?</span></span>
          <span><span style="display: inline-flex; align-items: end"><b>4.</b><input id=3 type='Circle'/>?</span></span>
      </div>
      `,
    },
  },
  2: {
    unit: "Unit 7",
    id: "WB5-2024-U7-P51-E2",
    audio: "Audios/22-audio.mp3",
    video: "",

    exerciseKey: "img/FriendsPlus/Page51/Key/E2answerKey.png",
    // maxLength: 5,
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "dash",
    },
    inputSize: 100,
    titleQuestion: [
      {
        num: "2",
        title:
          "Circle the correct word. Then listen and check. <span style='color: white'><headphone name='&nbsp 22' typeimg=sound src='Audios/22-tieude.mp3'></headphone></span>",
        color: "#203c8f",
        left: -50,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              // borderRadius: "50%",
              border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "580px",
              width: "30px",
              height: "30px",
              // borderRadius: "50%",
              border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "580px",
              width: "30px",
              height: "30px",
              // borderRadius: "50%",
              border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "350px",
              width: "30px",
              height: "30px",
              // borderRadius: "50%",
              border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "171px",
              left: "350px",
              width: "30px",
              height: "30px",
              // borderRadius: "50%",
              border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 4
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          borderRadius: "50%",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "go / see",
          "zebras / monkeys",
          "lions / giraffes",
          "kangaroos / crocodiles",
        ],
        answers: ["0-0", "1-0", "2-0", "3-4"],
        initialValue: [],
      },
      Layout: `
      
      <div style='display:flex'>
        <div>
          Did you <sup>1</sup><input id=0 type=Circle /> to the zoo? <br>
          Yes, I did.<br>
          Did you see the <sup>2</sup><input id=1 type=Circle />?<br>
          Yes, I did.<br>
          Did you see the <sup>3</sup><input id=2 type=Circle />?<br>
          Yes, I did.<br>
          Did you see the <sup>4</sup><input id=3 type=Circle />?<br>
          No, I didn’t.<br>
        </div>
        <img style='height:8.5cm; margin-left: 10px' src='img/FriendsPlus/Page51/E2/1.jpg'/>
      </div>
      `,
    },
  },

  3: {
    unit: "Unit 7",
    id: "WB5-2024-U7-P51-E3",
    audio: "",
    video: "",

    exerciseKey: "img/FriendsPlus/Page51/Key/E3answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title: "Look and match.",
        color: "#203c8f",
        // numberImg:"b",
        left: -23,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        lineToNotCenter: true,
        lineDirection: "row",
        noTransform: true,
        colorLineTo: "#40c7f4",
        widthLineto: 2,
        boxMatch: [
          {
            children: "Were there bones?",
            boxMatchStyle: {
              padding: "10px 17px",
              border: "1px solid #89c650",
              borderRadius: "20px",
              background: "#d9eac7",
            },
          }, // 0
          {
            children: "Did you go to the park?",
            boxMatchStyle: {
              padding: "10px 17px",
              border: "1px solid #f48332",
              borderRadius: "20px",
              background: "#fdd5b6",
            },
          }, // 1
          {
            children: "Was it scary?",
            boxMatchStyle: {
              padding: "10px 17px",
              border: "1px solid #a19fce",
              borderRadius: "20px",
              background: "#cac9e4",
            },
          }, // 2
          {
            children: "Yes, they were.",
            boxMatchStyle: {
              padding: "10px 17px",
              border: "1px solid #89c650",
              borderRadius: "20px",
              background: "#d9eac7",
            },
          }, // 3
          {
            children: "Did you eat an ice cream?",
            boxMatchStyle: {
              padding: "10px 17px",
              border: "1px solid #00aeef",
              borderRadius: "20px",
              background: "#aae0f9",
            },
          }, // 4
          {
            children: "Yes, she was.",
            boxMatchStyle: {
              padding: "10px 17px",
              border: "1px solid #f48332",
              borderRadius: "20px",
              background: "#fdd5b6",
            },
          }, // 5
        ],
        answers: ["3-5", "1-4", "0-2"],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",

          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          // borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "school_trip / boat_trip.",
          "movie_theater / museum.",
          "train / bus.",
          "four / five.",
          " postcard / dinosaur_model.",
        ],
        answers: ["1-4", "3-4", "2-0", "0-0", "4-6"],
        initialValue: ["0-0"],
      },
      Layout: `
      <div style='height: 13cm; width: 25cm; position: relative'>
        <div style='font-size: 21px; position: absolute; top: 7px; left: 11px'><input id='0' type='boxMatch' /></div>
        <div style='font-size: 21px; position: absolute; top: 127px; left: 268px'><input id='1' type='boxMatch' /></div>
        <div style='font-size: 21px; position: absolute; top: 196px; left: 23px'><input id='2' type='boxMatch' /></div>
        <div style='font-size: 21px; position: absolute; top: 199px; left: 699px'><input id='3' type='boxMatch' /></div>
        <div style='font-size: 21px; position: absolute; top: 284px; left: 215px'><input id='4' type='boxMatch' /></div>
        <div style='font-size: 21px; position: absolute; top: 430px; left: 101px'><input id='5' type='boxMatch' /></div>
        <div style='font-size: 21px; position: absolute; top: 6px; left: 432px; border: 1px solid rgb(0, 174, 239); border-radius: 20px; background: rgb(170, 224, 249);padding: 10px 17px'>No, he didn’t.</div>
        <div style='font-size: 21px; position: absolute; top: 427px; left: 697px; border: 1px solid rgb(161, 159, 206); border-radius: 20px; background: rgb(202, 201, 228);padding: 10px 17px'>No, they didn’t.</div>
        <div style="border-top: 2px solid rgb(64, 199, 244); position: absolute; top: 62px; left: 515.883px; width: 457.195px; z-index: 1; transform: rotate(53.181deg); transform-origin: 0px 0px;"></div>
      </div>
      `,
    },
  },
};

export default json;
