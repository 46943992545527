import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 10",
    id: "WB5-2024-U10-P77-E1",
    audio: "Audios/31-audio.mp3",
    exerciseKey: "img/FriendsPlus/Page77/Key/E1answerKey.png",
    //video: "Videos/.mp4",
    component: Circle_Write,
    //recorder: true,
    textAlign: "center",
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen and draw lines to show the words that join together as you<br>say them. <span style='color: white'><headphone name='&nbsp 31' typeimg=sound src='Audios/31-tieude.mp3'></headphone></span>",
        color: "#203c8f",
        left: -30,
      },
    ],
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          display: "inline-block",
          width: 10,
          lineHeight: "1.1em",
          padding: "1px 10px",
          margin: "0 -8px",
          border: "2px solid",
          borderBottom: "none",
          borderColor: "transparent",
          fontWeight: "bold",
          borderRadius: "50%",
          color: "transparent",
        },
        selectWordStyle: {
          borderTop: "2px solid #00aeef",
          borderTopLeftRadius: "50%",
          borderTopRightRadius: "50%",
          borderBottomLeftRadius: "0",
          borderBottomRightRadius: "0",
          color: "transparent",
        },
        limitSelect: 1,
        dontChangeColor: true,
        onlyBorderTop: true,
        listWords: [
          "F", //1
          "T", //2
          "F", //3
          "T", //4
          "T", //5
          "F", //6
          "T", //7
          "T", //8
          "F", //9
          "T", //10
          "F", //11
          "T", //12
          "F", //13
          "T", //14
        ],
        answers: [
          "0-0",
          "1-0",
          "3-0",
          "4-0",
          "6-0",
          "7-0",
          "9-0",
          "11-0",
          "13-0",
        ],
        initialValue: [],
      },
      Layout: `
            <div style='width: 25cm; display:grid; grid-template-columns: 1fr 1fr; grid-gap: 10px 50px; margin-top: 20px'>
              <span><b>1. </b>Get<input id=0 type='Circle' />in<input id=1 type='Circle'/>a<input id=2 type='Circle'/>spaceship. </span>
              <span><b>2. </b>Look<input id=3 type='Circle'/>at<input id=4 type='Circle'/>a<input id=5 type='Circle'/>book. </span>
              <span><b>3. </b>I'll<input id=6 type='Circle'/>open<input id=7 type='Circle'/>a<input id=8 type='Circle'/>box<input id=9 type='Circle'/>of<input id=10 type='Circle'/>cookies.</span>
              <span><b>4. </b>That's<input id=11 type='Circle'/>a<input id=12 type='Circle'/>bad<input id=13 type='Circle'/>idea.</span>
            </div>

      `,
    },
  },
  2: {
    unit: "Unit 10",
    id: "WB5-2024-U10-P77-E2",
    audio: "Audios/32-audio.mp3",
    exerciseKey: "img/FriendsPlus/Page77/Key/E2answerKey.png",
    //video: "Videos/.mp4",
    component: Circle_Write,
    //recorder: true,
    textAlign: "center",
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and draw lines to show the words that join together. Then listen<br>and check. <span style='color: white'><headphone name='&nbsp 32' typeimg=sound src='Audios/32-tieude.mp3'></headphone></span>",
        color: "#203c8f",
        left: -30,
      },
    ],
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          display: "inline-block",
          width: 10,
          lineHeight: "1.1em",
          padding: "1px 10px",
          margin: "0 -8px",
          border: "2px solid",
          borderBottom: "none",
          borderColor: "transparent",
          fontWeight: "bold",
          borderRadius: "50%",
          color: "transparent",
        },
        selectWordStyle: {
          borderTop: "2px solid #00aeef",
          borderTopLeftRadius: "50%",
          borderTopRightRadius: "50%",
          borderBottomLeftRadius: "0",
          borderBottomRightRadius: "0",
          color: "transparent",
        },
        limitSelect: 1,
        dontChangeColor: true,
        onlyBorderTop: true,
        listWords: [
          "F", //1
          "T", //2
          "F", //3
          "T", //4
          "T", //5
          "F", //6
          "T", //7
          "T", //8
          "F", //9
          "T", //10
          "F", //11
          "T", //12
          "F", //13
        ],
        answers: ["1-0", "9-0", "10-0"],
        initialValue: ["1-0"],
      },
      Layout: `
        <div style='position: relative'>
          <img src='img/FriendsPlus/Page77/E2/1.jpg' style='width: 25cm'/>
            <div style='position: absolute; left: 25px; top: 15px; display:flex; flex-direction: column; gap: 7px'>
              <span>I<input id=0 type='Circle'/>get<input id=1 type='Circle'/>in<input id=2 type='Circle'/>my<input id=3 type='Circle'/>rocket.</span>
              <span>I<input id=4 type='Circle'/>go<input id=5 type='Circle'/>to<input id=6 type='Circle'/>space.</span>
              <span>I'm<input id=7 type='Circle'/>hungry!</span>
              <span>I<input id=8 type='Circle'/>eat<input id=9 type='Circle'/>an<input id=10 type='Circle'/>orange.</span>
              <span>In<input id=11 type='Circle'/>my<input id=12 type='Circle'/>spaceship!</span>
            </div>
        </div>
      `,
    },
  },
  3: {
    unit: "Unit 11",
    id: "WB5-2024-U11-P77-E3",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page77/Key/E3answerKey.png",
    video: "",
    component: T6,
    titleQuestion: [
      {
        num: "3",
        title: "Order the words. Say the sentences.",
        color: "#2d408e",
        left: 50,
        // prefix:[{text:"hello",icon:"fas fa-pen"}]
      },
    ],
    stylesTextInput: { background: "none" },
    // textAlign: "center",
    inputSize: 850,
    styleHint: {
      // border: "none",
      // justifyContent: "center",
      display: "inline-flex",
      gap: "5px",
      paddingInline: 0,
      // borderColor: "#2bbbf1",
      // color: "#57585a",
      styleElementHintBox: {
        background: "#c6eafa",
        padding: "2px 5px",
        borderRadius: "5px",
        margin: 0,
      },
    },
    hintBox: [
      {
        src: ["old", "peel", "apple", "An", "."],
        inline: true,
      },
      {
        src: ["orange", ".", "an", "Have"],
        inline: true,
      },
      {
        src: ["Take", "walk", ".", "a"],
        inline: true,
      },
      {
        src: ["umbrella", ".", "Open", "an"],
        inline: true,
      },
    ],
    // character: "",
    // hai , ba , bốn , năm
    checkUppercase: true,
    questions: [
      {
        title: `
        <div style='width: 25cm; display:flex; flex-direction: column; gap: 10px'>
          <span><b>1. </b><hintbox id=0></hintbox> <u style='color:gray; text-underline-offset: 5px'>&nbspPeel an old apple.&nbsp</u></span>
          <span><b>2. </b><hintbox id=1></hintbox> <input width='678px'/></span>
          <span><b>3. </b><hintbox id=2></hintbox> <input width='725px'/></span>
          <span><b>4. </b><hintbox id=3></hintbox> <input width='652px'/></span>
        </div>
        `,
        answer: ["Have an orange.", "Take a walk.", "Open an umbrella."],
      },
    ],
  },
};

export default json;
