import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 10",
    id: "WB5-2024-U10-P71-E1",
    exerciseKey: "img/FriendsPlus/Page71/Key/E1answerKey.png",
    audio: "",
    video: "",
    component: Circle_Write,
    // recorder: true,
    titleQuestion: [
      {
        num: "1",
        title: "Underline the words that are stronger.",
        color: "#203c8f",
      },
    ],
    styleContent: {
      width: "90%",
      marginLeft: 50,
    },
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        colorLineTo: "gray",
        // newPointDot: true,
        // path: true,
        // notOverlapse: true,
        circlePadding: "5 1",
        margin: "5px 2px",
        // circleStringArray: ["modelsalivescreambonesdinosaursroarscarydead"],
        boxMatch: [],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        // circleInput: true,
        inputStyle: {
          width: 457,
          paddingBottom: 3,
          fontSize: 23,
          borderBottom: "none",
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        newSplit: true,
        initialWordStyle: {
          padding: "1px 3px",
          border: "none",
          background: "white",
          fontSize: 23,
          // fontWeight: "bold",
        },
        selectWordStyle: {
          color: "black",
          borderBottom: "2px solid #00aeef",
        },
        // limitSelect: 1,
        listWords: [
          "You mustn’t drink soda",
          "You must go to school",
          "You should go to bed",
          "You shouldn’t eat ice cream",
        ],
        answers: ["0-1", "1-1", "2-1", "3-1"],
        initialValue: ["0-1"],
      },
      Layout: `
        <div style='display: grid; grid-template-columns: 1fr 1fr; grid-gap: 10px 150px'>
          <span><b>1. </b><input id=0 type='Circle'/>.</span>
          <span><b>3. </b><input id=2 type='Circle'/>.</span>
          <span><b>2. </b><input id=1 type='Circle'/>.</span>
          <span><b>4. </b><input id=3 type='Circle'/>.</span>
        </div>
        `,
    },
  },

  2: {
    unit: "Unit 10",
    id: "WB5-2024-U10-P71-E2",
    exerciseKey: "img/FriendsPlus/Page71/Key/E2answerKey.png",
    audio: "Audios/29-audio.mp3",
    video: "",
    component: Circle_Write,
    // recorder: true,
    titleQuestion: [
      {
        num: "2",
        title:
          "Look at the lines 1, 2, 3 and 4. Underline the words that are stronger. Then listen and check. <span style='color: white'><headphone name='&nbsp 29' typeimg=sound src='Audios/29-tieude.mp3'></headphone></span>",
        color: "#203c8f",
      },
    ],
    styleContent: {
      width: "90%",
      marginLeft: 50,
    },
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        colorLineTo: "gray",
        // newPointDot: true,
        // path: true,
        // notOverlapse: true,
        circlePadding: "5 1",
        margin: "5px 2px",
        // circleStringArray: ["modelsalivescreambonesdinosaursroarscarydead"],
        boxMatch: [],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        // circleInput: true,
        inputStyle: {
          width: 457,
          paddingBottom: 3,
          fontSize: 23,
          borderBottom: "none",
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        newSplit: true,
        initialWordStyle: {
          padding: "1px 3px",
          border: "none",
          background: "none",
          // fontSize: 23,
          // fontWeight: "bold",
        },
        selectWordStyle: {
          color: "black",
          borderBottom: "2px solid #00aeef",
        },
        // limitSelect: 1,
        listWords: [
          "He shouldn’t go to school",
          "He mustn’t watch TV",
          "He should stay in bed",
          "He should go to sleep",
        ],
        answers: ["0-1", "1-1", "2-1", "3-1"],
        initialValue: ["0-1"],
      },
      Layout: `
      <div style='position: relative'>
        <img style='width: 25cm' src='img/FriendsPlus/Page71/E2/1.jpg'/>
        <div style='position: absolute; left: 25px; top: 10px; display: flex; flex-direction: column; gap: 10px'>
          <span>Minh has a cold,</span>
          <span>He feels so bad.</span>
          <span><sup>1</sup><input id=0 type='Circle'/>,</span>
          <span>He feels so sad..</span>
          <span><sup>2</sup><input id=1 type='Circle'/>.</span>
          <span><sup>3</sup><input id=2 type='Circle'/>.</span>
          <span><sup>4</sup><input id=3 type='Circle'/>,</span>
          <span>Like the doctor said.</span>
        </div>
      </div>
        
        `,
    },
  },
  3: {
    unit: "Unit 10",
    id: "WB5-2024-U10-P71-E3",
    exerciseKey: "img/FriendsPlus/Page71/Key/E3answerKey.png",
    audio: "",
    video: "",
    component: Circle_Write,
    // recorder: true,
    titleQuestion: [
      {
        num: "3",
        title: "Write and underline the stronger words. Then say.",
        color: "#203c8f",
      },
    ],
    styleContent: {
      width: "90%",
      marginLeft: 50,
    },
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        colorLineTo: "gray",
        // newPointDot: true,
        // path: true,
        // notOverlapse: true,
        circlePadding: "5 1",
        margin: "5px 2px",
        // circleStringArray: ["modelsalivescreambonesdinosaursroarscarydead"],
        boxMatch: [],
        answers: [],
        initialValue: [],
      },
      Write: {
        // isHiddenCheck: true,
        circleInput: true,
        inputStyle: {
          width: 450,
          paddingBottom: 3,

          fontSize: 23,
          borderBottom: "none",
          background: "none",
          maxLength: 31,
          // border: "1px solid blue",
        },
        answers: [
          "should see a doctor",
          "should drink water",
          "should go to bed",
        ],
        initialValue: [],
      },
      Circle: {
        newSplit: true,
        initialWordStyle: {
          padding: "1px 0 1px 6px",
          border: "none",
          background: "none",
          fontSize: 23,
          // fontWeight: "bold",
        },
        selectWordStyle: {
          color: "black",
          borderBottom: "2px solid #00aeef",
        },
        // limitSelect: 1,
        listWords: ["", "", ""],
        answers: ["0-1", "0-2", "0-3", "1-1", "1-2", "2-1", "2-2", "2-3"],
        initialValue: [],
      },
      Layout: `
        <div style='position: relative;'>
          <img style='width: 25cm' src='img/FriendsPlus/Page71/E3/1.jpg'/>
          <div style='position: absolute; top: 99px; left: 373px;'>
            <div style='position: relative'>
              #
              <div style='position: absolute; top: 0; left:-1px; z-index: 1'><input id=0 type='Circle'></div>
            </div>
          </div>
          <div style='position: absolute; top: 180px; left: 407px;'>
            <div style='position: relative'>
              #
              <div style='position: absolute; top: 0; left:-1px; z-index: 1'><input id=1 type='Circle'></div>
            </div>
          </div>
          <div style='position: absolute; top: 261px; left: 373px;'>
            <div style='position: relative'>
              #
              <div style='position: absolute; top: 0; left:-1px; z-index: 1'><input id=2 type='Circle'></div>
            </div>
          </div>
        </div>
        `,
    },
  },
};

export default json;
