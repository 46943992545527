import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 4",
    id: "WB5-2024-U4-P28-E1",
    // audio: "Audios/03-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page28/Key/E1answerKey.png",
    textAlign: "center",
    stylesTextInput: {
      width: 130,
    },
    titleQuestion: [
      {
        num: "1",
        title: "Look and count. Write the numbers.",
        color: "#2d408e",
        left: 50,
      },
    ],
    component: T6,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative; width: 25cm'>
          <img src='img/FriendsPlus/Page28/E1/1.jpg' style='width: 25cm'/>
          <div style='position: absolute; top: -40px; right: 0; display: flex; flex-direction: column; gap: 10px'>
            <span><b>1. </b><u style='color:gray; text-underline-offset: 5px'>&nbspsix&nbsp</u> taxis</span>
            <span><b>2. </b># motorcycles</span>
            <span><b>3. </b># train</span>
            <span><b>4. </b># electric bikes</span>
            <span><b>5. </b># trolley</span>
            <span><b>6. </b># planes</span>
            <span><b>7. </b># buses</span>
            <span><b>8. </b># scooters</span>
          </div>
        </div>
          
        `,
        answer: ["seven", "one", "two", "one", "two", "six", "three"],
      },
    ],
  },
  2: {
    unit: "Unit 4",
    id: "WB5-2024-U4-P28-E2",
    // audio: "Audios/03-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page28/Key/E2answerKey.png",
    stylesTextInput: {
      width: 100,
    },
    textAlign: "center",
    titleQuestion: [
      {
        num: "2",
        title: "Look and read. Write yes or no.",
        color: "#2d408e",
        left: 50,
      },
    ],
    component: T6,
    questions: [
      {
        title: `
          <div style='width: 25cm; display: grid; grid-template-columns: 3fr 1fr; grid-gap: 10px'>
            <span><b>1. </b>There's a trolley near the airport.</span><span><u style='color:gray; text-underline-offset: 5px'>&nbspno&nbsp</u></span>
            <span><b>2. </b>There's a train at the station. </span><span>#</span>
            <span><b>3. </b>There's a bus in front of the supermarket. </span><span>#</span>
            <span><b>4. </b>There are some electric bikes near the school. </span><span>#</span>
            <span><b>5. </b>There's a bus next to the school. </span><span>#</span>
            <span><b>6. </b>There are some taxis in front of the supermarket. </span><span>#</span>
            <span><b>7. </b>There's a plane at the airport. </span><span>#</span>
            <span><b>8. </b>There are some motorcycles in front of the school. </span><span>#</span>
          </div>        
        `,
        answer: ["yes", "yes", "yes", "no", "no", "yes", "yes"],
      },
    ],
  },
};

export default json;
