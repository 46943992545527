import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    id: "WB5-2024-U5-P35-E1",
    // audio: "Audios/03-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page35/Key/E1answerKey.png",
    stylesTextInput: {
      width: 120,
    },
    textAlign: "center",
    titleQuestion: [
      {
        num: "1",
        title: "Write was, were or had.",
        color: "#2d408e",
        left: 50,
      },
    ],
    component: T6,
    questions: [
      {
        title: `
          <div style='width: 25cm; display: flex; flex-direction: column; gap: 15px; padding-right: 40px'>
            <img style='width: 100%' src='img/FriendsPlus/Page35/E1/1.jpg'/>
            <span>Yesterday Quang and Oanh <sup>1</sup><u style='color:gray; text-underline-offset: 5px'>&nbspwere&nbsp</u> were at the beach with their mom and dad.It <sup>2</sup># sunny. The children <sup>3</sup># very happy. At twelve o'clock, they <sup>4</sup># hungry so they <sup>5</sup># some hamburgers. Mom <sup>6</sup># hot, but they <sup>7</sup># lots of fun.</span>
          </div>
        `,
        answer: ["was", "were", "were", "had", "was", "had"],
      },
    ],
  },
  2: {
    id: "WB5-2024-U5-P35-E2",
    // audio: "Audios/03-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page35/Key/E2answerKey.png",
    stylesTextInput: {
      width: 400,
    },
    // textAlign: "center",
    titleQuestion: [
      {
        num: "2",
        title: "Order the words. Write the question and answer.",
        color: "#2d408e",
        left: 50,
      },
    ],
    component: T6,
    questions: [
      {
        title: `
          <div style='width: 26cm; display: grid; grid-template-columns: 1fr 1fr; grid-gap: 5px 20px'>
            <div style='display: flex; flex-direction: column; gap: 5px'>
              <span><b>1. </b>go / Did / the beach / Oanh and Quang / to</span>
              <span style='margin-left: 10px'><u style='color:gray; text-underline-offset: 5px'>&nbspDid Oanh and Quang go to the beach?&nbsp</u></span>
              <span style='margin-left: 10px'><u style='color:gray; text-underline-offset: 5px'>&nbspYes, they did.&nbsp</u></span>
            </div>
            <div style='display: flex; flex-direction: column; gap: 5px'>
              <span><b>2. </b>it / sunny / was ?</span>
              <span style='margin-left: 10px'>#</span>
              <span style='margin-left: 10px'>#</span>
            </div>
            <div style='display: flex; flex-direction: column; gap: 5px'>
              <span><b>3. </b>were / happy / the children ?</span>
              <span style='margin-left: 10px'>#</span>
              <span style='margin-left: 10px'>#</span>
            </div>
            <div style='display: flex; flex-direction: column; gap: 5px'>
              <span><b>4. </b>have / they / ice cream / Did ?</span>
              <span style='margin-left: 10px'>#</span>
              <span style='margin-left: 10px'>#</span>
            </div>
            <div style='display: flex; flex-direction: column; gap: 5px'>
              <span><b>5. </b>cold / Mom / Was ?</span>
              <span style='margin-left: 10px'>#</span>
              <span style='margin-left: 10px'>#</span>
            </div>
            <div style='display: flex; flex-direction: column; gap: 5px'>
              <span><b>6. </b>fun / they / have / Did ?</span>
              <span style='margin-left: 10px'>#</span>
              <span style='margin-left: 10px'>#</span>
            </div>
          </div>
        `,
        answer: [
          "Was it sunny?",
          "Yes, it was.",
          "Were the children happy?",
          "Yes, they were.",
          "Did they have ice cream?",
          "No, they didn't.",
          "Was Mom cold?",
          "No, she wasn't.|No, she wasn't. She was hot.",
          "Did they have fun?",
          "Yes, they did.",
        ],
      },
    ],
  },
};

export default json;
