import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 9",
    id: "WB5-2024-U9-P60-E1",
    // audio: "Audios/07-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page60/Key/E1answerKey.png",
    textAlign: "center",
    stylesTextInput: {
      fontSize: 25,
      height: "2em",
      width: 48.7,
      background: "none",
      border: "none",
      zIndex: 1,
      position: "relative",
    },
    titleQuestion: [
      {
        num: "1",
        title:
          "<span style='position: relative; z-index: 1'>Complete the crossword.</span>",
        color: "#2d408e",
        left: 50,
      },
    ],
    maxLength: 1,
    component: T6,
    questions: [
      {
        title: `
        <div style='position:relative; text-align:center'>
          <img src='img/FriendsPlus/Page60/E1/1.jpg' style='width: 25cm; margin-top: -80px' />
          <div style='position: absolute; top: 25px; left: 252px; display:flex; flex-direction: column; gap: 0.5px'>
            <div style='width: 48.7px'>#</div>
            <div style='width: 48.7px'>#</div>
            <div style='width: 48.7px'>#</div>
            <div style='width: 48.7px'>#</div>
            <div style='width: 48.7px'>#</div>
            <div style='width: 48.7px'>#</div>
            <div style='width: 48.7px'>#</div>
            <div style='width: 48.7px; height: 2em'></div>
            <div style='width: 48.7px'>#</div>
            <div style='width: 48.7px'>#</div>
            <div style='width: 48.7px'>#</div>
          </div>
          <div style='position: absolute; top: 25px; left: 348px; display:flex; flex-direction: column; gap: 0.5px'>
            <div style='width: 48.7px'>#</div>
            <div style='width: 48.7px'>#</div>
            <div style='width: 48.7px'>#</div>
            <div style='width: 48.7px'>#</div>
            <div style='width: 48.7px'>#</div>
          </div>
          <div style='position: absolute; top: 25px; left: 446px; display:flex; flex-direction: column; gap: 0.5px'>
            <div style='width: 48.7px'>#</div>
            <div style='width: 48.7px'>#</div>
            <div style='width: 48.7px'>#</div>
            <div style='width: 48.7px'>#</div>
            <div style='width: 48.7px'>#</div>
          </div>
          <div style='position: absolute; top: 126px; left: 7px; display:flex; gap: 0.5px'>
            <div style='width: 48.7px'>#</div>
            <div style='width: 48.7px'>#</div>
            <div style='width: 48.7px; height: 2em'></div>
            <div style='width: 48.7px'>#</div>
            <div style='width: 48.7px'>#</div>
            <div style='width: 48.7px; height: 2em'></div>
          </div>
          <div style='position: absolute; top: 177px; left: 251px; display:flex; gap: 0.5px'>
            <div style='width: 48.7px; height: 2em'></div>
            <div style='width: 48.7px'>#</div>
            <div style='width: 48.7px; height: 2em'></div>
            <div style='width: 48.7px'>#</div>
            <div style='width: 48.7px; height: 2em'></div>
            <div style='width: 48.7px'>#</div>
          </div>
          <div style='position: absolute; top: 327px; left: 56px; display:flex; gap: 0.5px'>
            <div style='width: 48.7px'>#</div>
            <div style='width: 48.7px'>#</div>
            <div style='width: 48.7px'>#</div>
            <div style='width: 48.7px'>#</div>
            <div style='width: 48.7px; height: 2em'></div>
            <div style='width: 48.7px'>#</div>
            <div style='width: 48.7px'>#</div>
          </div>
          <div style='position: absolute; top: 477px; left: 7px; display:flex; gap: 0.5px'>
            <div style='width: 48.7px'>#</div>
            <div style='width: 48.7px'>#</div>
            <div style='width: 48.7px'>#</div>
            <div style='width: 48.7px'>#</div>
            <div style='width: 48.7px'>#</div>
            <div style='width: 48.7px; height: 2em'></div>
            <div style='width: 48.7px'>#</div>
            <div style='width: 48.7px'>#</div>
          </div>
        </div>
        `,
        answer: [
          "g",
          "a",
          "r",
          "b",
          "a",
          "g",
          "e",
          "c",
          "a",
          "n",
          "g",
          "r",
          "a",
          "s",
          "s",
          "t",
          "r",
          "e",
          "e",
          "s",
          "l",
          "i",
          "t",
          "e",
          "u",
          "h",
          "s",
          "f",
          "l",
          "o",
          "w",
          "r",
          "s",
          "f",
          "o",
          "u",
          "n",
          "t",
          "i",
          "n",
        ],
        initialValue: [],
      },
    ],
  },
  2: {
    unit: "Unit 9",
    id: "WB5-2024-U9-P60-E2",
    exerciseKey: "img/FriendsPlus/Page60/Key/E2answerKey.png",
    audio: "",
    video: "",
    inputSize: 200,
    maxLength: 40,
    textAlign: "center",
    titleQuestion: [
      {
        num: "2",
        title: "Read. Choose a word from the box and write.",
        color: "#203c8f",
        left: 82,
      },
    ],
    styleHint: {
      border: "2px solid",
      justifyContent: "center",
      paddingInline: 5,
      paddingBlock: "0px",
      borderColor: "#ee1d23",
      color: "#57585a",
      styleElementHintBox: {
        margin: "0px 10px",
        padding: "2px 4px",
      },
    },
    hintBox: [
      {
        src: [
          "litter",
          "path",
          "fountain",
          "garbage can",
          "bushes",
          "tree",
          "<span class='strikediag'>park</span>",
        ],
        width: 500,
        inline: true,
      },
    ],
    component: T6,
    questions: [
      {
        title: `
        <div style='display: flex; gap: 20px; width: 25cm'>
          <img src='img/FriendsPlus/Page60/E2/1.jpg' style='width: 38%' />
          <div style='display: flex; flex-direction: column; gap: 15px; align-items: center'>
            <hintbox id=0></hintbox>
            <span style='line-height: 1.7em'>
              My name’s Thu and I live near a <sup>1</sup><u style='color:gray; text-underline-offset: 5px'>&nbsppark&nbsp</u>. This is a picture of me and my friend Giang. We’re walking on the <sup>2</sup># . There’s a cat in the <sup>3</sup># . There’s a wall around the park. There are <sup>4</sup># behind the wall. They’re very beautiful! Can you see that? There’s some <sup>5</sup># near the wall. You must use a <sup>6</sup># . Also, there’s a dog playing in the <sup>7</sup># . You mustn’t let your dog play in the fountain. 
            </span>
          </div>
        </div>
        `,
        answer: ["path", "tree", "bushes", "litter", "garbage can", "fountain"],
      },
    ],
  },
};

export default json;
