import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    // Exercise num
    unit: "Grammar time",
    id: "WB5-2024-GT-P94-E1",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page94/Key/E1answerKey.png",
    video: "",
    component: T6,
    titleQuestion: [
      {
        num: "1",
        title: "Write.",
        color: "#2d408e",
        left: 50,
        // prefix:[{text:"hello",icon:"fas fa-pen"}]
      },
    ],
    textAlign: "center",
    inputSize: 200,
    // styleHint: {
    //   // border: "none",
    //   // justifyContent: "center",
    //   display: "inline-flex",
    //   gap: "5px",
    //   paddingInline: 0,
    //   marginLeft: 20,
    //   // borderColor: "#2bbbf1",
    //   // color: "#57585a",
    //   styleElementHintBox: {
    //     background: "#c6eafa",
    //     padding: "5px 10px",
    //     borderRadius: "5px",
    //     margin: 0,
    //   },
    // },
    // checkUppercase: true,
    // hintBox: [
    //   {
    //     src: ["water", "have", "?", "some", "Could", "I"],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["apple", "have", "Could", "I", "?", "that"],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["some", "Could", "?", "cookies", "we", "have"],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["help", "you", "me", "?", "Could"],
    //     width: "max-content",
    //     inline: true,
    //   },
    // ],
    // character: "/",
    // hai , ba , bốn , năm
    // checkUppercase: true,
    stylesTextInput: {
      background: "none",
      // marginTop: 20,
    },
    textareaStyle: {
      width: "100%",
      resize: "none",
      paddingTop: 5,
    },
    notification: true,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width: 25cm; display:grid; grid-template-columns: 1fr 1fr; grid-gap: 10px 40px'>
          <span><b>1. </b>They <u style='color: gray; text-underline-offset: 5px'>&nbspwent&nbsp</u> (go) to the museum yesterday.</span>
          <span><b>3. </b>Amy # (buy) a model.</span>
          <span><b>2. </b>We # (see) some dinosaur skeletons.</span>
          <span><b>4. </b>We # (not see) any bones.</span>
        </div>
        `,
        answer: ["bought", "saw", "didn't see"],
      },
    ],
  },
  2: {
    unit: "Grammar time",
    id: "WB5-2024-GT-P94-E2",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page94/Key/E2answerKey.png",
    video: "",
    component: T6,
    titleQuestion: [
      {
        num: "2",
        title: "Write questions and short answers.",
        color: "#2d408e",
        left: 50,
        // prefix:[{text:"hello",icon:"fas fa-pen"}]
      },
    ],
    // textAlign: "center",
    inputSize: 200,
    // styleHint: {
    //   // border: "none",
    //   // justifyContent: "center",
    //   display: "inline-flex",
    //   gap: "5px",
    //   paddingInline: 0,
    //   marginLeft: 20,
    //   // borderColor: "#2bbbf1",
    //   // color: "#57585a",
    //   styleElementHintBox: {
    //     background: "#c6eafa",
    //     padding: "5px 10px",
    //     borderRadius: "5px",
    //     margin: 0,
    //   },
    // },
    // checkUppercase: true,
    // hintBox: [
    //   {
    //     src: ["water", "have", "?", "some", "Could", "I"],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["apple", "have", "Could", "I", "?", "that"],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["some", "Could", "?", "cookies", "we", "have"],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["help", "you", "me", "?", "Could"],
    //     width: "max-content",
    //     inline: true,
    //   },
    // ],
    // character: "/",
    // hai , ba , bốn , năm
    // checkUppercase: true,
    stylesTextInput: {
      background: "none",
      // marginTop: 20,
    },
    textareaStyle: {
      width: "100%",
      resize: "none",
      paddingTop: 5,
    },
    notification: true,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width: 25cm; display: flex; flex-direction: column; gap: 10px'>
          <span><b>1. </b>Amy / buy a postcard? <b>(✗)</b> <u style='color: gray; text-underline-offset: 5px'>&nbsp&nbspDid Amy buy a postcard? No, she didn't.&nbsp&nbsp</u> </span>
          <span><b>2. </b>they / wear / sun hats? <b>(✓)</b> <input width='611px'/> </span>
          <span><b>3. </b>the dinosaur / roar? <b>(✓)</b> <input width='642px'/> </span>
          <span><b>4. </b>they / watch a movie? <b>(✗)</b> <input width='620px'/> </span>
        </div>
        `,
        answer: [
          "Did they wear sun hats? Yes, they did.",
          "Did the dinosaur roar? Yes, it did.",
          "Did they watch a movie? No, they didn't.",
        ],
      },
    ],
  },
  3: {
    unit: "Grammar time",
    id: "WB5-2024-GT-P94-E3",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page94/Key/E3answerKey.png",
    video: "",
    component: T6,
    titleQuestion: [
      {
        num: "1",
        title: "Complete the sentences with comparative adjectives.",
        color: "#2d408e",
        left: 50,
        // prefix:[{text:"hello",icon:"fas fa-pen"}]
      },
    ],
    textAlign: "center",
    inputSize: 250,
    // styleHint: {
    //   // border: "none",
    //   // justifyContent: "center",
    //   display: "inline-flex",
    //   gap: "5px",
    //   paddingInline: 0,
    //   marginLeft: 20,
    //   // borderColor: "#2bbbf1",
    //   // color: "#57585a",
    //   styleElementHintBox: {
    //     background: "#c6eafa",
    //     padding: "5px 10px",
    //     borderRadius: "5px",
    //     margin: 0,
    //   },
    // },
    // checkUppercase: true,
    // hintBox: [
    //   {
    //     src: ["water", "have", "?", "some", "Could", "I"],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["apple", "have", "Could", "I", "?", "that"],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["some", "Could", "?", "cookies", "we", "have"],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["help", "you", "me", "?", "Could"],
    //     width: "max-content",
    //     inline: true,
    //   },
    // ],
    // character: "/",
    // hai , ba , bốn , năm
    // checkUppercase: true,
    stylesTextInput: {
      background: "none",
      // marginTop: 20,
    },
    textareaStyle: {
      width: "100%",
      resize: "none",
      paddingTop: 5,
    },
    notification: true,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width: 25cm; display: flex; flex-direction: column; gap: 10px'>
          <span><b>1. </b>Thanh is <u style='color: gray; text-underline-offset: 5px'>&nbsp&nbspshorter than&nbsp&nbsp</u> (short) Linh.</span>
          <span><b>2. </b>Minh Anh is # (tall) Thanh.</span>
          <span><b>3. </b>Elephants are # (large) cheetahs.</span>
          <span><b>4. </b>Are cheetahs # (fast) cats?</span>
        </div>
        `,
        answer: ["taller than", "larger than", "faster than"],
      },
    ],
  },
};

export default json;
