import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Grammar time",
    id: "WB5-2024-GT-P90-E1",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page90/Key/E1answerKey.png",
    video: "",
    component: T6,
    titleQuestion: [
      {
        num: "1",
        title: "Look and write <i>one, two, three</i>, or <i>some</i>.",
        color: "#2d408e",
        left: 50,
        // prefix:[{text:"hello",icon:"fas fa-pen"}]
      },
    ],
    // textAlign: "center",
    inputSize: "24cm",
    // styleHint: {
    //   // border: "none",
    //   // justifyContent: "center",
    //   display: "inline-flex",
    //   gap: "5px",
    //   paddingInline: 0,
    //   // borderColor: "#2bbbf1",
    //   // color: "#57585a",
    //   styleElementHintBox: {
    //     background: "#c6eafa",
    //     padding: "2px 5px",
    //     borderRadius: "5px",
    //     margin: 0,
    //   },
    // },
    // hintBox: [
    //   {
    //     src: ["have", "family", ".", "I", "with", "breakfast", "my"],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["homework", "in", "I", "my", "do", "the", "morning", "."],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["at", "brush", "I", "my", "teeth", "night", "."],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["play", "I", "school", ".", "soccer", "after"],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["to", "school", "walk", "I", "the", "morning", ".", "in"],
    //     width: "max-content",
    //     inline: true,
    //   },
    // ],
    // character: "/",
    // hai , ba , bốn , năm
    // checkUppercase: true,
    stylesTextInput: {
      background: "none",
    },
    textareaStyle: {
      width: "100%",
      resize: "none",
      paddingTop: 5,
    },
    notification: true,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src='img/FriendsPlus/Page90/E1/1.jpg' style='width: 24.5cm; margin-left: 10px'/>
        <div style='width: 25cm; display:grid; grid-template-columns: repeat(5,1fr); grid-gap: 25px; margin-top: 10px'>
          <u style='width: 100%; color: gray; text-underline-offset: 5px; padding-left: 10px'>&nbspthree&nbsp<br>&nbspmushrooms&nbsp</u>
          <textarea id='0' rows='2'></textarea>
          <textarea id='1' rows='2'></textarea>
          <textarea id='2' rows='2'></textarea>
          <textarea id='3' rows='2'></textarea>
        </div>
        `,
        answer: ["one cucumber", "some meat", "two onions", "some ice tea"],
      },
    ],
  },
  2: {
    unit: "Grammar time",
    id: "WB5-2024-GT-P90-E2",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page90/Key/E2answerKey.png",
    video: "",
    component: T6,
    titleQuestion: [
      {
        num: "2",
        title: "Order the words.",
        color: "#2d408e",
        left: 50,
        // prefix:[{text:"hello",icon:"fas fa-pen"}]
      },
    ],
    // textAlign: "center",
    inputSize: 400,
    styleHint: {
      // border: "none",
      // justifyContent: "center",
      display: "inline-flex",
      gap: "5px",
      paddingInline: 0,
      marginLeft: 20,
      // borderColor: "#2bbbf1",
      // color: "#57585a",
      styleElementHintBox: {
        background: "#c6eafa",
        padding: "5px 10px",
        borderRadius: "5px",
        margin: 0,
      },
    },
    checkUppercase: true,
    hintBox: [
      {
        src: ["water", "have", "?", "some", "Could", "I"],
        width: "max-content",
        inline: true,
      },
      {
        src: ["apple", "have", "Could", "I", "?", "that"],
        width: "max-content",
        inline: true,
      },
      {
        src: ["some", "Could", "?", "cookies", "we", "have"],
        width: "max-content",
        inline: true,
      },
      {
        src: ["help", "you", "me", "?", "Could"],
        width: "max-content",
        inline: true,
      },
    ],
    // character: "/",
    // hai , ba , bốn , năm
    // checkUppercase: true,
    stylesTextInput: {
      background: "none",
      marginTop: 20,
    },
    textareaStyle: {
      width: "100%",
      resize: "none",
      paddingTop: 5,
    },
    notification: true,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width: 27cm; display:grid; grid-template-columns: 1fr 1fr; grid-gap: 5px 10px'>
          <span><b>1. </b><hintbox id=0></hintbox></span> <u style='margin-top: 20px; color: gray; text-underline-offset: 5px'>&nbsp&nbspCould I have some water?&nbsp&nbsp</u>
          <span><b>2. </b><hintbox id=1></hintbox></span> #
          <span><b>3. </b><hintbox id=2></hintbox></span> #
          <span><b>4. </b><hintbox id=3></hintbox></span> #
        </div>
        `,
        answer: [
          "Could I have that apple?",
          "Could we have some cookies?",
          "Could you help me?",
        ],
      },
    ],
  },
};

export default json;
