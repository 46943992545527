import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 3",
    id: "WB5-2024-U3-P21-E1",
    audio: "Audios/08-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page21/Key/E1answerKey.png",
    // stylesTextInput: {
    //   width: 300,
    // },
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen and draw lines. <span style='color: white'><headphone name='&nbsp 08' typeimg=sound src='Audios/08-tieude.mp3'></headphone></span>",
        color: "#2d408e",
        left: -50,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        lineToNotCenter: true,
        lineDirection: "row",
        multipleLine: false,
        sort: true,
        colorLineTo: "#40c7f4",
        widthLineto: 2,
        boxMatch: [
          {
            children: `
              <div style='padding: 2px 20px; border: 1px solid rgb(57, 185, 118); border-radius: 5px'>Nga</div>
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 0
          {
            children: `
              <div style='padding: 2px 20px; border: 1px solid rgb(57, 185, 118); border-radius: 5px'>Lam</div>
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 1
          {
            children: `
              <div style='padding: 2px 20px; border: 1px solid rgb(57, 185, 118); border-radius: 5px'>Giang</div>
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 2

          {
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "absolute",
              top: 14,
              left: 16,
              height: 363,
              width: 121,
              borderRadius: "50px",
              // background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "absolute",
              top: 14,
              left: 142,
              height: 363,
              width: 121,
              borderRadius: "50px",
              // background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "absolute",
              top: 14,
              left: 270,
              height: 363,
              width: 121,
              borderRadius: "50px",
              // background: "white",
            },
          }, // 5
          {
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "absolute",
              top: 7,
              left: 396,
              height: 370,
              width: 121,
              borderRadius: "50px",
              // background: "white",
            },
          }, // 6
          {
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "absolute",
              top: 14,
              left: 519,
              height: 363,
              width: 121,
              borderRadius: "50px",
              // background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "absolute",
              top: 25,
              left: 645,
              height: 352,
              width: 121,
              borderRadius: "50px",
              // background: "white",
            },
          }, // 8
          {
            children: `
              <div style='padding: 2px 20px; border: 1px solid rgb(57, 185, 118); border-radius: 5px'>Quang</div>
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 9
          {
            children: `
              <div style='padding: 2px 20px; border: 1px solid rgb(57, 185, 118); border-radius: 5px'>Ly</div>
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 10
          {
            children: `
              <div style='padding: 2px 20px; border: 1px solid rgb(57, 185, 118); border-radius: 5px'>Hoa</div>
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 11
        ],
        answers: ["2-7", "1-3", "8-11", "6-9", "4-10"],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          textAlign: "center",
          width: 30,
          background: "none",
          fontSize: 22,
          maxLength: 1,
          border: "none",
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",

          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          // borderRadius: "50px",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [],
        answers: [],
        initialValue: [],
      },
      Layout: `
      <div style='display: flex; flex-direction: column; gap: 80px;'>
        <div style='display: flex; gap: 100px; justify-content: center'>
          
          <div style='padding: 2px 20px; border: 1px solid rgb(57, 185, 118); border-radius: 5px'>Nga</div>
          <input id='1' type='boxMatch' />
          <input id='2' type='boxMatch' />
        </div>
        <div style='position: relative'>
          <input id='3' type='boxMatch' />
          <input id='4' type='boxMatch' />
          <input id='6' type='boxMatch' />
          <input id='7' type='boxMatch' />
          <input id='8' type='boxMatch' />
          <img style='height: 10cm' src='img/FriendsPlus/Page21/E1/1.jpg' />
        </div>
        <div style='display: flex; gap: 80px; justify-content: center'>
          <input id='9' type='boxMatch' />
          <input id='10' type='boxMatch' />
          <input id='11' type='boxMatch' />
        </div>
        <div style="border-top: 2px solid rgb(64, 199, 244);position: absolute;top: 45.2812px;left: 186.086px;width: 172.312px;z-index: 1;transform: rotate(33.0604deg);transform-origin: 0px 0px;"></div>
      </div>
      `,
    },
  },

  2: {
    unit: "Unit 3",
    id: "WB5-2024-U3-P21-E2",
    // audio: "Audios/03-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page21/Key/E2answerKey.png",
    stylesTextInput: {
      width: "100%",
      borderWidth: "0 0 1px",
      // background: "blue",
    },
    textAlign: "center",
    titleQuestion: [
      {
        num: "2",
        title: "Complete the question and write the answer.",
        color: "#2d408e",
        left: 50,
      },
    ],
    component: T6,
    questions: [
      {
        title: `
        <div style='width: 25cm; position: relative'>
          <div style='position: absolute; top: 150px; left: 371px; width:200px'>#</div>
          <div style='position: absolute; top: 210px; left: 425px; width:185px'>#</div>
          <div style='position: absolute; top: 296px; left: 227px; width:153px'>#</div>
          <div style='position: absolute; top: 359px; left: 420px; width:182px'>#</div>
          <div style='position: absolute; top: 444px; left: 367px; width:181px'>#</div>
          <div style='position: absolute; top: 503px; left: 372px; width:240px'>#</div>
          <img style='width: 100%' src='img/FriendsPlus/Page21/E2/1.jpg'/>
        </div>
        
        `,
        answer: [
          "some",
          "sorry.",
          "Could",
          "course.",
          "two",
          "Sure.|Of course.",
        ],
        initialValue: [],
      },
    ],
  },
};

export default json;
