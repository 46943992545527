import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 7",
    id: "WB5-2024-U7-P52-E1",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page52/Key/E1answerKey.png",
    video: "",
    component: T6,
    titleQuestion: [
      {
        num: "1",
        title: "Read and write. Use the words from the word box.",
        color: "#203c8f",
        left: 50,
        // prefix:[{text:"hello",icon:"fas fa-pen"}]
      },
    ],
    stylesTextInput: {
      background: "none",
      width: 250,
      // paddingLeft: 0,
    },
    textAlign: "center",
    styleHint: {
      border: "2px solid #ee1d23",
      justifyContent: "center",
      gap: 5,
      // color: "#57585a",
      // flexWrap: "nowrap",
      flexDirection: "column",
      alignItems: "center",
      // padding: "5px 30px",
      // marginTop: 20,
      fontWeight: 400,
      styleElementHintBox: {
        // background: "#2bbbf1",
        // padding: "2px 5px",
        // borderRadius: "5px",
        marginRight: 0,
      },
    },
    hintBox: [
      {
        src: [
          "<span class='strikediag'>scientists</span>",
          "cave",
          "jungle",
          "tourists",
          "river",
        ],
        width: "200px",
        // inline: true,
      },
    ],
    questions: [
      {
        title: `
          <div style='display:flex; gap: 30px; width: 24.5cm'>
            <span style='line-height: 2em'>
              In 2009, a farmer named Ho Khanh and some <sup>1</sup><u style='color:gray; text-underline-offset: 5px'>&nbspscientists&nbsp</u> found Son Doong – a huge <sup>2</sup># . They were surprised because they found a <sup>3</sup># and a <sup>4</sup># inside. <sup>5</sup># can visit the cave, but it’s not an easy trip because it’s so big! 
            </span>
            <hintbox id=0></hintbox>
          </div>

         `,
        answer: ["cave", "jungle|river", "river|jungle", "Tourists"],
      },
    ],
  },
  2: {
    unit: "Unit 7",
    id: "WB5-2024-U7-P52-E2",
    audio: "",
    video: "",

    exerciseKey: "img/FriendsPlus/Page52/Key/E2answerKey.png",
    // maxLength: 5,
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "dash",
    },
    inputSize: 100,
    titleQuestion: [
      {
        num: "2",
        title: "Read the text in the Student Book. Fill in the correct circle.",
        color: "#203c8f",
        left: -32,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        newSplit: true,
        initialWordStyle: {
          width: "22px",
          height: "22px",
          paddingLeft: 3,
          border: "1px solid",
          borderRadius: "50%",
          color: "transparent",
          backgroundColor: "transparent",
          borderColor: "black",
          fontWeight: "bold",
          lineHeight: "25px",
          fontSize: "18px",
        },
        selectWordStyle: {
          backgroundColor: "gray",
          borderColor: "gray",
        },
        limitSelect: 1,
        listWords: ["✓  ✓  ✓", "✓  ✓  ✓", "✓  ✓  ✓", "✓  ✓  ✓"],
        answers: ["0-0", "2-2", "1-1", "3-2"],
        initialValue: ["0-0"],
      },
      Layout: `
      <div style='font-size:22px'>
        <div style='width: 27cm; display:grid; grid-template-columns: 1fr 1fr; gap: 30px'>
          <div>
            <span><b>1.</b> What is the reading about?</span>
            <div style='display:flex; gap: 20px; width: 100%; padding-left: 20px'>
            <div style='display:flex; flex-direction:column; gap: 5px'>
              <span>(A) a big cave</span>
              <span>(B) Mr. Ho Khanh and his family</span>
              <span>(C) an interesting vacation</span>
            </div>
            <div style='display:flex; flex-direction:column; gap: 17px; padding-top: 10px; margin-left: auto;'><input id=0 type='Circle'/></div>
            </div>
          </div>
          <div>
            <span><b>3.</b> Why were Mr. Khanh and the scientists</span>
            <div style='display:flex; gap: 20px; width: 100%; padding-left: 20px'>
            <div style='display:flex; flex-direction:column; gap: 5px'>
              <span>(A) They found some tourists.</span>
              <span>(B) They found a river.</span>
              <span>(C) They found a beach.</span>
            </div>
            <div style='display:flex; flex-direction:column; gap: 17px; padding-top: 10px; margin-left: auto;'><input id=1 type='Circle'/></div>
            </div>
          </div>
          <div>
            <span><b>2.</b> Who found the cave first? </span>
            <div style='display:flex; gap: 20px; width: 100%; padding-left: 20px'>
              <div style='display:flex; flex-direction:column; gap: 5px'>
              
                <span>(A) some scientists</span>
                <span>(B) some tourists</span>
                <span>(C) Mr. Ho Khanh</span>
              </div>
              <div style='display:flex; flex-direction:column; gap: 17px; padding-top: 10px; margin-left: auto;'><input id=2 type='Circle'/></div>
            </div>
          </div>
          <div>
            <span><b>4.</b> Why isn’t it easy for people to visit the cave?</span>
            <div style='display:flex; gap: 20px; width: 100%; padding-left: 20px'>
                <div style='display:flex; flex-direction:column; gap: 5px'>
                  <span>(A) They forget where it is.</span>
                  <span>(B) They think it isn’t real.</span>
                  <span>(C) It’s difficult to walk inside it.</span>
                </div>
                <div style='display:flex; flex-direction:column; gap: 17px; padding-top: 10px; margin-left: auto;'><input id=3 type='Circle'/></div>
              </div>
            </div>
          </div>
      </div>
      
      `,
    },
  },

  3: {
    unit: "Unit 7",
    id: "WB5-2024-U7-P52-E3",
    audio: "",
    video: "",

    exerciseKey: "img/FriendsPlus/Page52/Key/E3answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title:
          "Read the text in the Student Book. Write one word to complete the sentence.",
        color: "#203c8f",
        // numberImg: ["b"],
        left: 50,
      },
    ],
    stylesTextInput: { width: 170 },
    textAlign: "center",
    component: T6,
    questions: [
      {
        title: `<div style='line-height: 2em'>
				<b>1.</b>&ensp;Son Doong is a huge <u style='color:gray; text-underline-offset: 5px'>&nbspcave&nbsp</u> .<br>
        <b>2.</b>&ensp;Mr. Ho Khanh found the cave in# .<br>
        <b>3.</b>&ensp;In 2009, Mr. Khanh and some# found the cave again.<br>
        <b>4.</b>&ensp;There is a# and a# inside the cave.<br>
        </div>
        `,
        answer: ["1991", "scientists", "river|jungle", "jungle|river"],
      },
    ],
  },
};

export default json;
