import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Unit 11",
    id: "WB5-2024-U11-P75-E1",
    exerciseKey: "img/FriendsPlus/Page75/Key/E1answerKey.png",
    audio: "",
    video: "",
    inputSize: 450,
    maxLength: 80,
    // textAlign: "center",
    titleQuestion: [
      {
        num: "1",
        title: "Look and read. Write Yes, she will or No, she won't.",
        color: "#203c8f",
        left: 55,
      },
    ],
    component: T6,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img style='width: 25cm' src='img/FriendsPlus/Page75/E1/1.jpg'/>
        <div style='width: 25cm; margin-top: 20px; display: grid; grid-template-columns: 1fr 1fr; grid-gap: 10px 40px; align-items: center'>
          <span><b>1. </b>Will Ly take her lunchbox tomorrow?</span><u style='color:gray; text-underline-offset: 5px'>&nbsp&nbspYes, she will.&nbsp&nbsp</u>
          <span><b>2. </b>Will she take her calculator? </span>#
          <span><b>3. </b>Will she take her coat? </span>#
          <span><b>4. </b>Will she wear her school shoes? </span>#
          <span><b>5. </b>Will she wear her boots? </span>#
          <span><b>6. </b>Will she take an umbrella?</span>#
        </div>
        `,
        answer: [
          "No, she won't.",
          "Yes, she will.",
          "No, she won't.",
          "Yes, she will.",
          "Yes, she will.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 11",
    id: "WB5-2024-U11-P75-E2",
    exerciseKey: "img/FriendsPlus/Page75/Key/E2answerKey.png",
    audio: "",
    video: "",
    inputSize: 150,
    maxLength: 80,
    textAlign: "center",
    titleQuestion: [
      {
        num: "2",
        title: "Write will or won’t.",
        color: "#203c8f",
        left: 55,
      },
    ],
    component: T6,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width: 24cm; display: flex; gap: 40px'>
          <span>In 100 years, we <span style='text-wrap: nowrap'><sup>1</sup><u style='color:gray; text-underline-offset: 5px'>&nbspwon’t&nbsp</u></span> have teachers. Robots <span style='text-wrap: nowrap'><sup>2</sup>#</span> teach us. Robots <span style='text-wrap: nowrap'><sup>3</sup>#</span> help us with our homework, too. We <span style='text-wrap: nowrap'><sup>4</sup>#</span> learn science at school, but we <span style='text-wrap: nowrap'><sup>5</sup>#</span> learn English. Robots <span style='text-wrap: nowrap'><sup>6</sup>#</span> speak English for us!</span>
          <img style='width: 11cm' src='img/FriendsPlus/Page75/E2/1.jpg'/>
        </div>
        `,
        answer: ["will", "will", "will", "won't", "will"],
      },
    ],
  },
};

export default json;
