import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 6",
    id: "WB5-2024-U6-P44-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page44/Key/E1answerKey.png",
    inputSize: 160,
    textAlign: "center",
    stylesTextInput: {
      background: "none",
      fontSize: 22,
      height: 15,
      padding: "10",
      border: "none",
    },

    titleQuestion: [
      {
        num: "1",
        title: "Read and write the words.",
        color: "#2d408e",
        left: 80,
      },
    ],
    component: T6,
    styleHint: {
      border: "2px solid",
      justifyContent: "center",
      // gap: 0,
      borderColor: "#f15921",
      color: "#57585a",
      flexWrap: "nowrap",
      padding: "5px 30px",
      styleElementHintBox: {
        // background: "#2bbbf1",
        // padding: "2px 5px",
        // borderRadius: "5px",
      },
    },
    hintBox: [
      {
        src: [
          "cooked",
          "enjoyed",
          "fireworks",
          "lucky",
          "received",
          "<span class='strikediag'>visited</span>",
        ],
        width: "max-content",
      },
    ],
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
           <div style='position:relative; margin-top: 20px'>
            <div style='position:absolute;top:203px;left:408px;'>#</div>
            <div style='position:absolute;top:271px;left:407px;'>#</div>
            <div style='position:absolute;top:271px;left:689px;'>#</div>
            <div style='position:absolute;top:338px;left:511px;'>#</div>
            <div style='position:absolute;top:372px;left:659px;'>#</div>
            <img style='width:25cm' src='img/FriendsPlus/Page44/E1/1.jpg'/>
          </div>

         `,
        answer: ["cooked", "received ", "lucky ", "fireworks", "enjoyed"],
      },
    ],
  },
  2: {
    unit: "Unit 6",
    id: "WB5-2024-U6-P44-E2",
    // audio: "Audios/12-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page44/Key/E2answerKey.png",
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "dash",
    },
    inputSize: 100,
    titleQuestion: [
      {
        num: "2",
        title: "Read the text in the Student Book. Fill in the correct circle.",
        color: "#2d408e",
        left: -33,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        newSplit: true,
        initialWordStyle: {
          width: "22px",
          height: "22px",
          paddingLeft: 3,
          border: "1px solid",
          borderRadius: "50%",
          color: "transparent",
          backgroundColor: "transparent",
          borderColor: "black",
          fontWeight: "bold",
          lineHeight: "25px",
          fontSize: "18px",
        },
        selectWordStyle: {
          backgroundColor: "gray",
          borderColor: "gray",
        },
        limitSelect: 1,
        listWords: ["✓  ✓  ✓", "✓  ✓  ✓", "✓  ✓  ✓", "✓  ✓  ✓"],
        answers: ["0-2", "2-0", "1-1", "3-2"],
        initialValue: ["0-2"],
      },
      Layout: `
      <div style='font-size:22px'>
        <div style='width: 27cm; display:grid; grid-template-columns: 1fr 1fr; gap: 30px'>
          <div>
            <span><b>1.</b> Where do Oanh’s grandparents live now?</span>
            <div style='display:flex; gap: 20px; width: 100%; padding-left: 20px'>
            <div style='display:flex; flex-direction:column; gap: 5px'>
              <span>(A) in the city</span>
              <span>(B) in the country</span>
              <span>(C) near the beach</span>
            </div>
            <div style='display:flex; flex-direction:column; gap: 17px; padding-top: 10px; margin-left: auto;'><input id=0 type='Circle'/></div>
            </div>
          </div>
          <div>
            <span><b>3.</b> What did the family enjoy?</span>
            <div style='display:flex; gap: 20px; width: 100%; padding-left: 20px'>
            <div style='display:flex; flex-direction:column; gap: 5px'>
              <span>(A) the fireworks</span>
              <span>(B) the food</span>
              <span>(C) the games</span>
            </div>
            <div style='display:flex; flex-direction:column; gap: 17px; padding-top: 10px; margin-left: auto;'><input id=1 type='Circle'/></div>
            </div>
          </div>
          <div>
            <span><b>2.</b> What did Oanh and her brother receive?</span>
            <div style='display:flex; gap: 20px; width: 100%; padding-left: 20px'>
              <div style='display:flex; flex-direction:column; gap: 5px'>
              
                <span>(A) lucky money</span>
                <span>(B) fun games</span>
                <span>(C) <i>banh chung</i></span>
              </div>
              <div style='display:flex; flex-direction:column; gap: 17px; padding-top: 10px; margin-left: auto;'><input id=2 type='Circle'/></div>
            </div>
          </div>
          <div>
            <span><b>4.</b> What did they do in the evening?</span>
            <div style='display:flex; gap: 20px; width: 100%; padding-left: 20px'>
                <div style='display:flex; flex-direction:column; gap: 5px'>
                  <span>(A) They played games.</span>
                  <span>(B) They cooked banh chung.</span>
                  <span>(C) They watched fireworks.</span>
                </div>
                <div style='display:flex; flex-direction:column; gap: 17px; padding-top: 10px; margin-left: auto;'><input id=3 type='Circle'/></div>
              </div>
            </div>
          </div>
      </div>
      
      `,
    },
  },
};

export default json;
