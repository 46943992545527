import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 5",
    id: "WB5-2024-U4-P38-E1",
    // audio: "Audios/Page59/Track 086.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page38/Key/E1answerKey.png",
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "dash",
    },
    inputSize: 100,
    titleQuestion: [
      {
        num: "1",
        title: "Read. Choose the correct word.",
        color: "#2d408e",
        left: -40,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 5px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
          borderRadius: "50%",
        },
        selectWordStyle: {
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "foggy / stormy",
          "cloudy / humid",
          "foggy / humid",
          "cloudy / stormy",
        ],
        answers: ["0-4", "1-4", "2-0", "3-0"],
        initialValue: [],
      },
      Layout: `
      <img src='img/FriendsPlus/Page38/E1/1.jpg' style='width: 25cm'>
      <div style='display:flex; flex-direction:column; gap:5px; margin-top: 10px'>
        <span><b>1. </b>It was very wet and windy yesterday. It was <input id=0 type='Circle'/>.</span>
        <span><b>2. </b>Last week was very hot and <input id=1 type='Circle'/>. It was sunny, too.</span>
        <span><b>3. </b>This morning was cold and grey. It was <input id=2 type='Circle'/>.</span>
        <span><b>4. </b>Today was <input id=3 type='Circle'/>, but it wasn’t rainy or windy.</span>
      </div>
      `,
    },
  },
  2: {
    unit: "Unit 5",
    id: "WB5-2024-U5-P38-E1",
    // audio: "Audios/11-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page38/Key/E2answerKey.png",
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "dash",
    },
    inputSize: 100,
    titleQuestion: [
      {
        num: "2",
        title:
          "Read the texts in the Student Book. Then read the conversation and choose<br>the best answer.",
        color: "#2d408e",
        left: -33,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          border: "1px solid blue",
          padding: "0 5px",
          borderRadius: "50%",
          backgroundColor: "transparent",
          borderColor: "transparent",
        },
        selectWordStyle: {
          borderColor: "blue",
        },
        limitSelect: 1,
        newSplit: true,
        listWords: [
          "(A) (B) (C)",
          "(A) (B) (C)",
          "(A) (B) (C)",
          "(A) (B) (C)",
          "(A) (B) (C)",
        ],
        answers: ["0-1", "1-0", "2-2", "3-0", "4-2"],
        initialValue: [],
      },
      Layout: `
      <img src='img/FriendsPlus/Page38/E2/1.jpg' style='width:95%' />
      <div style='font-size:22px'>
        <div style='width: 27cm; display:grid; grid-template-columns: 1fr 1fr; gap: 20px 30px; margin-top: 20px'>
          <div>
            <span><b>1. </b> When was Halloween?</span>
            <div style='display:flex; gap: 5px; padding-left: 20px'>
            <div style='display:flex; flex-direction:column; gap: 2px; padding-top: 10px'><input id=0 type='Circle'/></div>
            <div style='display:flex; flex-direction:column; gap: 4px; padding-top: 10px'>
              <span>We had a lot of fun.</span>
              <span>Last Friday.</span>
              <span>I was a ghost.</span>
            </div>
            </div>
          </div>
          <div>
            <span><b>4. </b>How was your Christmas, Lily?</span>
            <div style='display:flex; gap: 5px; padding-left: 20px'>
            <div style='display:flex; flex-direction:column; gap: 2px; padding-top: 10px'><input id=3 type='Circle'/></div>
            <div style='display:flex; flex-direction:column; gap: 4px; padding-top: 10px'>
              <span>We had a very good Christmas.</span>
              <span>We had a Trick or Treat party.</span>
              <span>We had a vacation at Christmas.</span>
            </div>
            </div>
          </div>
          <div>
            <span><b>2. </b>What was the weather like in Germany</span>
            <div style='display:flex; gap: 5px; padding-left: 20px'>
              <div style='display:flex; flex-direction:column; gap: 2px; padding-top: 10px'><input id=1 type='Circle'/></div>
              <div style='display:flex; flex-direction:column; gap: 4px; padding-top: 10px'>
                <span>It was stormy in Australia.</span>
                <span>There was lots of candy.</span>
                <span>On the beach.</span>
              </div>
            </div>
          </div>
          <div>
            <span><b>5. </b>Where were you on Christmas day, Lily?</span>
            <div style='display:flex; gap: 5px; padding-left: 20px'>
                <div style='display:flex; flex-direction:column; gap: 2px; padding-top: 10px'><input id=4 type='Circle'/></div>
                <div style='display:flex; flex-direction:column; gap: 4px; padding-top: 10px'>
                  <span>I was a ghost.</span>
                  <span>My mom doesn’t like tricks.</span>
                  <span>Yes, it was a lot of fun.</span>
                </div>
              </div>
            </div>
          <div>
            <span><b>3. </b>Was the Trick or Treat party fun?</span>
            <div style='display:flex; gap: 5px; padding-left: 20px'>
                <div style='display:flex; flex-direction:column; gap: 2px; padding-top: 10px'><input id=2 type='Circle'/></div>
                <div style='display:flex; flex-direction:column; gap: 4px; padding-top: 10px'>
                  <span>Yes, she’s in the middle of the gate.</span>
                  <span>Yes, she’s inside the play house.</span>
                  <span>Yes, she often comes by bike to the park.</span>
                </div>
            </div>
          </div>
          </div>
          
      </div>
      
      `,
    },
  },
};

export default json;
