import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  3: {
    unit: "Review 2",
    id: "WB5-2024-R2-P47-E3",
    // audio: "Audios/18-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page47/Key/E3answerKey.png",
    inputSize: 50,
    // textAlign: "center",
    stylesTextInput: {
      background: "none",
      paddingLeft: 0,
    },

    titleQuestion: [
      {
        num: "3",
        title: "Write the letters.",
        color: "#2d408e",
        left: 80,
      },
    ],
    component: T6,
    styleHint: {
      border: "1px solid",
      justifyContent: "center",
      // gap: 0,
      borderColor: "#f15921",
      color: "#57585a",
      flexWrap: "nowrap",
      padding: "5px 30px",
      // marginTop: 20,
      fontWeight: 400,
      styleElementHintBox: {
        // background: "#2bbbf1",
        // padding: "2px 5px",
        // borderRadius: "5px",
      },
    },
    hintBox: [
      {
        src: [
          "<span class='strikediag'>ed</span>",
          "ed",
          "nd",
          "lt",
          "nt",
          "ld",
        ],
        width: "max-content",
        inline: true,
      },
    ],
    questions: [
      {
        title: `
          <hintbox id=0></hintbox>
          <div style='width: 25cm; display: flex; gap: 30px; margin-top: 20px'>
            <div style='display: flex; flex-direction: column; align-items: center'>
              <img style='width: 100%' src='img/FriendsPlus/Page47/E1/1.jpg'/>
              <span>kick<u style='color:gray; text-underline-offset: 5px'>ed&nbsp</u></span>
            </div>
            <div style='display: flex; flex-direction: column; align-items: center'>
              <img style='width: 100%' src='img/FriendsPlus/Page47/E1/2.jpg'/>
              <span>ha#</span>
            </div>
            <div style='display: flex; flex-direction: column; align-items: center'>
              <img style='width: 100%' src='img/FriendsPlus/Page47/E1/3.jpg'/>
              <span>be#</span>
            </div>
            <div style='display: flex; flex-direction: column; align-items: center'>
              <img style='width: 100%' src='img/FriendsPlus/Page47/E1/4.jpg'/>
              <span>te#</span>
            </div>
            <div style='display: flex; flex-direction: column; align-items: center'>
              <img style='width: 100%' src='img/FriendsPlus/Page47/E1/5.jpg'/>
              <span>chi#</span>
            </div>
            <div style='display: flex; flex-direction: column; align-items: center'>
              <img style='width: 100%' src='img/FriendsPlus/Page47/E1/6.jpg'/>
              <span>paint#</span>
            </div>
          </div>

         `,
        answer: ["nd", "lt", "nt", "ld", "ed"],
      },
    ],
  },
  4: {
    unit: "Review 2",
    id: "WB5-2024-R2-P47-E4",
    // audio: "Audios/18-audio.mp3",
    video: "",
    recorder: true,
    exerciseKey: "img/FriendsPlus/Page47/Key/E3answerKey.png",
    inputSize: 50,
    // textAlign: "center",
    stylesTextInput: {
      background: "none",
      paddingLeft: 0,
    },

    titleQuestion: [
      {
        num: "4",
        title: "Talk about your last vacation or school trip.",
        color: "#2d408e",
        left: 80,
      },
    ],
    component: T6,
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <img style='margin-top: 20px; width: 25cm' src='img/FriendsPlus/Page47/E2/1.jpg'/>
         `,
        answer: [],
      },
    ],
  },
  5: {
    unit: "Review 2",
    id: "WB5-2024-R2-P47-E5",
    // audio: "Audios/18-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page47/Key/E3answerKey.png",
    inputSize: 50,
    // textAlign: "center",
    stylesTextInput: {
      background: "none",
      paddingLeft: 0,
    },
    textareaStyle: {
      width: "25cm",
      resize: "none",
      marginTop: 20,
      paddingTop: 0,
      // borderBottom: "1px solid rgb(168, 168
    },
    titleQuestion: [
      {
        num: "5",
        title:
          "Write a paragraph of 30–40 words about the weather on your last vacation.<br>Use these words to help you.",
        color: "#2d408e",
        left: 80,
      },
    ],
    hideBtnFooter: true,
    component: T6,
    styleHint: {
      border: "1px solid",
      justifyContent: "center",
      // gap: 0,
      borderColor: "#f15921",
      color: "#57585a",
      flexWrap: "nowrap",
      padding: "5px 30px",
      // marginTop: 20,
      fontWeight: 400,
      styleElementHintBox: {
        // background: "#2bbbf1",
        // padding: "2px 5px",
        // borderRadius: "5px",
      },
    },
    hintBox: [
      {
        src: ["humid", "stormy", "cloudy", "foggy"],
        width: "max-content",
        inline: true,
      },
    ],
    questions: [
      {
        title: `
          <hintbox id=0></hintbox>
          <textarea id=0 placeholder='I visited Cambodia on my last vacation …' rows=4></textarea>
         `,
        answer: [],
        initialValue: ["I visited Cambodia on my last vacation"],
      },
    ],
  },
  6: {
    unit: "Unit 3",
    id: "WB5-2024-U3-P27-E6",
    audio: "",
    video: "",
    component: Circle_Write,
    // maxLength: 5,
    // textAlign: "center",
    stylesTextInput: { background: "none", paddingLeft: 0 },
    inputSize: 500,
    titleQuestion: [{}],
    hideBtnFooter: true,
    // isHello: true,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        isHiddenCheck: true,
        newSplit: true,
        dontChangeColor: true,
        initialWordStyle: {
          display: "inline-block",
          border: "2px solid",
          borderColor: "transparent",
          padding: "20px 20px",
          borderRadius: "50%",
          color: "transparent",
        },
        selectWordStyle: {
          borderColor: "#40c7f4",
        },
        limitSelect: 1,
        listWords: ["aaaaaaaa aaaaaaaaaaaaaaa aaaaaaaaaaaaaaaaaaaaaaaaa"],
        answers: [],
        initialValue: [],
      },
      Layout: `
          <div style='width: 25cm; border: 2px solid rgb(247, 154, 108); box-shadow: 2px 2px rgb(183, 184, 179); padding: 40px 20px 20px 20px; border-radius: 10px; position: relative; margin-top: 50px'>
            <div style='position: absolute; top: -20px; left: 20px; background: rgb(241, 89, 33); padding: 5px 10px; border-radius: 10px; color: white; font-weight: 800'>My work</div>
            <div style='width: 25cm; display:flex; flex-direction: column'>
              <span>My favorite story in Units 4-6 is <input width='530px'></span>
              <span>My favorite song in Units 4-6 is <input width='532px'></span>
              <span>My favorite unit in Units 4-6 is <input width='543px'></span>
              <span>I need to practice <input width='686px'></span>
              <img style='width: 22cm; margin-top: 20px' src='img/FriendsPlus/Page47/E4/1.jpg'/>
              <div style='position: absolute; bottom: 10px; left: 10px; width: 22cm; display: flex; gap: 25px'><input id=0 type='Circle'/></div>
            </div>
          </div>
        `,
    },
  },
};

export default json;
