import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 12",
    id: "WB5-2024-U12-P85-E1",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page85/Key/E1answerKey.png",
    video: "",
    component: T6,
    titleQuestion: [
      {
        num: "1",
        title: "Write <i>and</i>, <i>or</i>, <i>so</i>, or <i>but</i>.",
        color: "#2d408e",
        left: 50,
        // prefix:[{text:"hello",icon:"fas fa-pen"}]
      },
    ],
    textAlign: "center",
    inputSize: 120,
    // styleHint: {
    //   // border: "none",
    //   // justifyContent: "center",
    //   display: "inline-flex",
    //   gap: "5px",
    //   paddingInline: 0,
    //   // borderColor: "#2bbbf1",
    //   // color: "#57585a",
    //   styleElementHintBox: {
    //     background: "#c6eafa",
    //     padding: "2px 5px",
    //     borderRadius: "5px",
    //     margin: 0,
    //   },
    // },
    // hintBox: [
    //   {
    //     src: ["is", "going", "Where", "to", "she", "go", "?"],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["you", "watch", "TV", "going", "Are", "to", "?"],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["they", "are", "eat", "going", "What", "to", "?"],
    //     width: "max-content",
    //     inline: true,
    //   },
    //   {
    //     src: ["he", "to", "going", "play", "soccer", "Is", "?"],
    //     width: "max-content",
    //     inline: true,
    //   },
    // ],
    stylesTextInput: {
      background: "none",
    },
    // character: "",
    // hai , ba , bốn , năm
    // checkUppercase: true,
    questions: [
      {
        title: `
        <div style='width: 25cm; display:flex; flex-direction: column; gap: 10px'>
          <span><b>1. </b>Jerry is clever, <u style='color:gray; text-underline-offset: 5px'>&nbspso&nbsp</u> Tom can never catch him.</span>
          <span><b>2. </b>Will they win the vacation # the car?</span>
          <span><b>3. </b>Gru meets three little girls # his life changes.</span>
          <span><b>4. </b>The questions are difficult, # you must be clever to win.</span>
          <span><b>5. </b>I want to watch the match, # it’s not on TV</span>
        </div>
        `,
        answer: ["or", "and", "so", "but"],
      },
    ],
  },
  2: {
    unit: "Unit 12",
    id: "WB5-2024-U12-P85-E2",
    audio: "",
    video: "",

    exerciseKey: "img/FriendsPlus/Page85/Key/E2answerKey.png",
    // maxLength: 5,
    // textAlign: "center",
    stylesTextInput: {
      background: "none",
      border: "none",
      paddingLeft: 0,
      paddingRight: 0,
      fontSize: 23,
      width: 700,
    },
    textareaStyle: {
      // marginLeft: 7,
      background: "none",
      width: "100%",
      resize: "none",
      fontSize: 23,
      lineHeight: "2em",
      padding: 0,
      // border: "1px solid rgb(168, 168, 168)",
    },
    maxLength: 96,
    titleQuestion: [
      {
        num: "2",
        title: "Complete the notes.",
        color: "#203c8f",
        left: 55,
      },
    ],
    hideBtnFooter: true,
    component: T6,
    notification: false,
    questions: [
      {
        title: `
          <div style='display: flex; flex-direction: column; width: 27cm; border: 1px solid rgb(220, 221, 223); font-size: 22px; box-shadow: 5px 5px rgb(233, 233, 233);padding-bottom: 20px'>
          <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 0 10px; text-align: center; position: relative'>
              <b style='color: rgb(0,136,202); font-size: 27px; margin-bottom: -10px'>My favorite TV show</b>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 15px; display: grid; grid-template-columns: 1fr 2fr; position: relative'>
              <span style='color: rgb(0,136,202)'>What show is it? </span>
              <span style='color: rgb(0,136,202)'><input placeholder='...'> </span>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 15px; display: grid; grid-template-columns: 1fr 2fr; position: relative'>
              <span style='color: rgb(0,136,202)'>When’s it on?</span>
              <span style='color: rgb(0,136,202)'><input placeholder='...'> </span>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 15px; display: grid; grid-template-columns: 1fr 2fr; position: relative'>
              <span style='color: rgb(0,136,202)'>What channel?</span>
              <span style='color: rgb(0,136,202)'><input placeholder='...'> </span>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 15px; display: grid; grid-template-columns: 1fr 2fr; position: relative'>
              <span style='color: rgb(0,136,202)'>What kind of TV show is it?</span>
              <span style='color: rgb(0,136,202)'><input placeholder='...'> </span>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 15px; display: grid; grid-template-columns: 1fr 2fr; position: relative'>
              <span style='color: rgb(0,136,202)'>What’s it about?</span>
              <span style='color: rgb(0,136,202)'><input placeholder='...'> </span>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 15px; display: grid; grid-template-columns: 1fr 2fr; position: relative'>
              <span style='color: rgb(0,136,202)'>What do you like about it?</span>
              <div style='position: absolute;top: 0;left: 29.5%; width: 69%; height: 180px; overflow: hidden; z-index: 1'><textarea id=5 rows=2 placeholder='...' ></textarea></div>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 15px; display: grid; grid-template-columns: 1fr 2fr; position: relative'>
              <span style='color: rgb(0,136,202)'>&nbsp</span>
            </div>
          </div>
        `,
        InputRong: true,
        answer: [
          "Grandma, wolf, Red Riding Hood",
          "Red Riding Hood went to visit her Grandma.",
          "Red Riding Hood and the wolf met Grandma, who was scared of the wolf.",
          "Grandma made cookies and they lived happily ever after.",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 12",
    id: "WB5-2024-U12-P85-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page85/Key/E3answerKey.png",
    stylesTextInput: { background: "none", border: "none", paddingLeft: 0 },
    textareaStyle: {
      width: "calc(100% - 10px)",
      resize: "none",
      paddingTop: 5,
      // borderBottom: "1px solid rgb(168, 168, 168)",
    },
    inputSize: 500,
    titleQuestion: [
      {
        num: "3",
        title: "Write about a TV show. Use your notes to help you.",
        color: "#2d408e",
        left: 50,
      },
    ],
    hideBtnFooter: true,
    // isHello: true,
    questions: [
      {
        title: `
          <div style='width: 25cm; border: 3px solid rgb(116, 119, 176); padding: 10px 10px 0 10px; border-radius: 10px'>
            <textarea id=0 rows=6></textarea>
          </div>
        `,
        answer: [],
        // fixedValue: ["My healthy habits"],
      },
    ],
  },
};

export default json;
