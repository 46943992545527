import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 4",
    id: "WB5-2024-U4-P30-E1",
    // audio: "Audios/Page59/Track 086.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page30/Key/E1answerKey.png",
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "dash",
    },
    inputSize: 100,
    titleQuestion: [
      {
        num: "1",
        title:
          "<span style='position: relative; z-index: 1'>Circle the correct words.</span>",
        color: "#2d408e",
        left: -40,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 0
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 5px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
          borderRadius: "50%",
        },
        selectWordStyle: {
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "twenty_years_ago / one_hundred_years_ago",
          "twenty_years_ago / one_hundred_years_ago",
          "twenty_years_ago / one_hundred_years_ago",
          "twenty_years_ago / one_hundred_years_ago",
          "twenty_years_ago / one_hundred_years_ago",
        ],
        answers: ["0-4", "1-0", "2-0", "3-4", "4-4"],
        initialValue: ["0-4"],
      },
      Layout: `
      <div style='position: relative; width: 25cm; height: 9cm'>
        <img src='img/FriendsPlus/Page30/E1/1.jpg' style='width: 25cm; position: absolute; top: -50px'>
      </div>
      <div style='display:flex; flex-direction:column; gap:5px'>
        <span><b>1. </b>There weren’t any planes <input id=0 type='Circle'/></span>
        <span><b>2. </b>There were motorcycles <input id=1 type='Circle'/>.</span>
        <span><b>3. </b>There were some cars <input id=2 type='Circle'/>.</span>
        <span><b>4. </b>There weren’t any buses <input id=3 type='Circle'/>.</span>
        <span><b>5. </b>There were bikes <input id=4 type='Circle'/>.</span>
      </div>
      `,
    },
  },
  2: {
    unit: "Unit 4",
    id: "WB5-2024-U4-P30-E2",
    audio: "Audios/12-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page30/Key/E2answerKey.png",
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "dash",
    },
    inputSize: 100,
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen to a conversation. Fill in the correct circle.<span style='color: white'><headphone name='&nbsp 12' typeimg=sound src='Audios/12-tieude.mp3'></headphone></span>",
        color: "#2d408e",
        left: -33,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          width: "22px",
          height: "22px",
          paddingLeft: 3,
          border: "1px solid",
          borderRadius: "50%",
          color: "transparent",
          backgroundColor: "transparent",
          borderColor: "black",
          fontWeight: "bold",
          lineHeight: "25px",
          fontSize: "18px",
        },
        selectWordStyle: {
          backgroundColor: "gray",
          borderColor: "gray",
        },
        limitSelect: 1,
        listWords: ["✓  ✓  ✓", "✓  ✓  ✓", "✓  ✓  ✓", "✓  ✓  ✓"],
        answers: ["0-0", "2-8", "1-4", "3-8"],
        initialValue: ["0-0"],
      },
      Layout: `
      <div style='font-size:22px'>
        <div style='width: 27cm; display:grid; grid-template-columns: 1fr 1fr; gap: 30px'>
          <div>
            <span><b>1.</b>The picture of our town is from …</span>
            <div style='display:flex; gap: 20px; width: 100%; padding-left: 20px'>
            <div style='display:flex; flex-direction:column; gap: 5px'>
              <span>(A) one hundred years ago.</span>
              <span>(B) two hundred years ago.</span>
              <span>(C) two thousand years ago.</span>
            </div>
            <div style='display:flex; flex-direction:column; gap: 4px; padding-top: 10px; margin-left: auto;'><input id=0 type='Circle'/></div>
            </div>
          </div>
          <div>
            <span><b>3.</b> What weren’t there?</span>
            <div style='display:flex; gap: 20px; width: 100%; padding-left: 20px'>
            <div style='display:flex; flex-direction:column; gap: 5px'>
              <span>(A) There weren’t any stores.</span>
              <span>(B) There wasn’t a supermarket.</span>
              <span>(C) There weren’t any markets.</span>
            </div>
            <div style='display:flex; flex-direction:column; gap: 4px; padding-top: 10px; margin-left: auto;'><input id=1 type='Circle'/></div>
            </div>
          </div>
          <div>
            <span><b>2.</b> What were there?</span>
            <div style='display:flex; gap: 20px; width: 100%; padding-left: 20px'>
              <div style='display:flex; flex-direction:column; gap: 5px'>
              
                <span>(A) There were buses.</span>
                <span>(B) There were trains.</span>
                <span>(C) There were bikes.</span>
              </div>
              <div style='display:flex; flex-direction:column; gap: 4px; padding-top: 10px; margin-left: auto;'><input id=2 type='Circle'/></div>
            </div>
          </div>
          <div>
            <span><b>4.</b> Where are the people in the picture?</span>
            <div style='display:flex; gap: 20px; width: 100%; padding-left: 20px'>
                <div style='display:flex; flex-direction:column; gap: 5px'>
                  <span>(A) They are at the market.</span>
                  <span>(B) They are at the movie theater.</span>
                  <span>(C) They are at a café.</span>
                </div>
                <div style='display:flex; flex-direction:column; gap: 4px; padding-top: 10px; margin-left: auto;'><input id=3 type='Circle'/></div>
              </div>
            </div>
          </div>
        <img src='img/FriendsPlus/Page30/E2/1.jpg' style='width: 27cm; margin-top: 20px'>
      </div>
      
      `,
    },
  },
  3: {
    unit: "Fluency Time! 1",
    id: "SB5-2024-FT1-P30-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "3",
        title:
          "Ask and answer. <img style='width:1cm' src='img/FriendsPlus/Page11/E4/a.jpg'/>",
        color: "rgb(0, 166, 81)",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page30/E3/1.jpg",
        },
        // {
        // 	url: 'img/FriendsPlus/Page30/E1/2.jpg',
        // 	audioUrl: 'Audios/Page30/hinh2-e1-p30.mp3',
        // },
      ],
    ],
  },
};

export default json;
