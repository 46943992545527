import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 6",
    id: "WB5-2024-U6-P40-E1",
    // audio: "Audios/05-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page40/Key/E1answerKey.png",
    textAlign: "center",
    titleQuestion: [
      {
        num: "1",
        title: "Find and circle the verbs. Then write.",
        color: "#2d408e",
        left: -30,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: true,
        colorLineTo: "#40c7f4",
        newPointDot: true,
        // notOverlapse: true,
        circlePadding: "0 0",
        // margin: "0 0 0 10px",
        padding: "4px 0px 4px 15px",
        border: "1px solid",
        // transform: "translate(50%)",
        circleStringArray: [
          "opqxwdmn",
          "wheyafad",
          "uawvnilm",
          "startnok",
          "eebilive",
          "cbjmksep",
          "xtzbahft",
          "cookohur",
        ],
        boxMatch: [],
        answers: [
          "3_0-3_4",
          "1_5-6_5",
          "2_6-5_6",
          "1_1-4_1",
          "0_4-3_4",
          "2_0-4_0",
          "4_4-4_7",
          "7_0-7_3",
        ],
        initialValue: ["3_0-3_4"],
      },
      Write: {
        inputStyle: { width: 200, textAlign: "center" },
        answers: [
          "finish|love",
          "love|finish",
          "hate",
          "want",
          "use",
          "live",
          "cook",
        ],
        initialValue: [],
      },
      Circle: {
        isHiddenCheck: true,
        newSplit: true,
        initialWordStyle: {
          border: "2px solid",
          borderColor: "transparent",
          padding: "1px 5px",
          borderRadius: "50%",
          fontSize: 20,
        },
        selectWordStyle: {
          borderColor: "#40c7f4",
        },
        limitSelect: 1,
        listWords: [
          "soda / ice_tea",
          "mushroom / meat",
          "lemonade / melon",
          "onion / melon",
          "onion / meat",
          "lemonade / ice_tea",
          "cucumber / lemonade",
          "onion / mushrooms",
        ],
        answers: [],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative; width: 25cm; display: grid; grid-template-columns: repeat(4, 1fr); grid-gap: 20px'>
        <div style='position: absolute; top: 30px; left: 28%'>
          <div id='circle-container' style='position: relative; width: 11cm;  display: grid; grid-template-columns: repeat(8, 1fr)'>
            <input id=0 type='boxMatch'/>
            <input id=1 type='boxMatch'/>
            <input id=2 type='boxMatch'/>
            <input id=3 type='boxMatch'/>
            <input id=4 type='boxMatch'/>
            <input id=5 type='boxMatch'/>
            <input id=6 type='boxMatch'/>
            <input id=7 type='boxMatch'/>
          </div>
        </div>
        <div style='display: flex; flex-direction: column; gap: 10px'>
          <img style='width: 100%' src='img/FriendsPlus/Page40/E1/1.jpg'/>
          <div style='width: 100%; text-align: center'><u style='color:gray; text-underline-offset: 5px'>&nbspstart&nbsp</u></div>
        </div>
        <div></div>
        <div></div>
        <div style='display: flex; flex-direction: column; gap: 10px'>
          <img style='width: 100%' src='img/FriendsPlus/Page40/E1/2.jpg'/>
          <div style='width: 100%; text-align: center'>#</div>
        </div>
        <div style='display: flex; flex-direction: column; gap: 10px'>
          <img style='width: 100%' src='img/FriendsPlus/Page40/E1/3.jpg'/>
          <div style='width: 100%; text-align: center'>#</div>
        </div>
        <div></div>
        <div></div>
        <div style='display: flex; flex-direction: column; gap: 10px'>
          <img style='width: 100%' src='img/FriendsPlus/Page40/E1/4.jpg'/>
          <div style='width: 100%; text-align: center'>#</div>
        </div>
        <div style='display: flex; flex-direction: column; gap: 10px'>
          <img style='width: 100%' src='img/FriendsPlus/Page40/E1/5.jpg'/>
          <div style='width: 100%; text-align: center'>#</div>
        </div>
        <div style='display: flex; flex-direction: column; gap: 10px'>
          <img style='width: 100%' src='img/FriendsPlus/Page40/E1/6.jpg'/>
          <div style='width: 100%; text-align: center'>#</div>
        </div>
        <div style='display: flex; flex-direction: column; gap: 10px'>
          <img style='width: 100%' src='img/FriendsPlus/Page40/E1/7.jpg'/>
          <div style='width: 100%; text-align: center'>#</div>
        </div>
        <div style='display: flex; flex-direction: column; gap: 10px'>
          <img style='width: 100%' src='img/FriendsPlus/Page40/E1/8.jpg'/>
          <div style='width: 100%; text-align: center'>#</div>
        </div>
      </div>
      `,
    },
  },
  2: {
    unit: "Unit 6",
    id: "WB5-2024-U6-P40-E2",
    // audio: "Audios/03-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page40/Key/E2answerKey.png",
    stylesTextInput: {
      width: 250,
      paddingLeft: 0,
    },
    textAlign: "center",
    titleQuestion: [
      {
        num: "2",
        title:
          "Read the story. Complete the sentences with the correct word from 1.",
        color: "#2d408e",
        left: 50,
      },
    ],
    component: T6,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='width: 27cm'>
          My name is Mai. I <sup>1</sup><u style='color:gray; text-underline-offset: 5px'>&nbsplive&nbsp</u> in Ho Chi Minh City. I <sup>2</sup># school at 
          7.30 in the morning, and <sup>3</sup># school at 11 o’clock. 
          I <sup>4</sup># my school! My teacher is good and I like my friends. After school, 
          I do my homework. Sometimes, I <sup>5</sup># a computer 
          in a café.
        </div>
        `,
        answer: ["start", "finish", "love", "use"],
      },
    ],
  },
};

export default json;
