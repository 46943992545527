import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Starter",
    id: "WB5-2024-Starter-P49-E1",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page2/Key/E1answerKey.png",
    video: "",
    component: T6,
    titleQuestion: [
      {
        num: "1",
        title:
          "Look at the table and complete the sentences.",
        color: "#203c8f",
        left:50,
      },
    ],
    stylesTextInput:{width:440},
    // textAlign:'center',
    questions: [
        {
          title: `
          <img style='height:7cm' src='img/FriendsPlus/Page2/E1/1.jpg'/><br>
          <b style='font-size:26px'>Class 5A School trip</b><br>
          <b>1.</b>&ensp;(go ✓) Class 5A went to a museum. #<br>
          <b>2.</b>&ensp;(buy ✗) They didn’t buy #<br>
          <b>3.</b>&ensp;(see ✓)#<br>
          <b>4.</b>&ensp;(make ✗)#<br>
          <b style='font-size:26px'>Class 5B School trip</b><br>
          <b>5.</b>&ensp;(go ✗)#<br>
          <b>6.</b>&ensp;(see ✗)#<br>
          <b>7.</b>&ensp;(make ✓)#<br>
          <b>8.</b>&ensp;(buy ✓)#<br>
          
          `,
          answer: ["went to a museum","posters ","They saw old bicycles.","They didn't make models.","Class 5B didn't go to the theater.","They didn't see dinosaur models.","They made books about dinosaurs.","They bought postcards."],
        },
      ],
  },
  2: {
    unit: "Starter",
    id: "WB5-2024-Starter-P49-E2",
    audio: "",
    video: "",
     
    exerciseKey: "img/FriendsPlus/Page2/Key/E2answerKey.png",
    component: T6,
    // maxLength: 5,
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "dash",
    },
    inputSize: 150,
    titleQuestion: [
      {
        num: "2",
        title:
          "Complete the dialog with the correct form of the words.",
        color: "#203c8f",
        left:50
      },
    ],
    questionImage: [],
    // isHello: true,
    questions: [
      {
        title: `
        <img style='height:17mm' src='img/FriendsPlus/Page2/E2/1.jpg'/>
        <div style='display:flex'>
            <div>
                <b>Ben</b><br>
                <b>Sally</b><br>
                <b>Ben</b><br>
                <b>Sally</b><br>
                <b>Ben</b><br>
                <b>Sally</b><br>
                <b>Ben</b><br>
                <b>Sally</b>
            </div>
            <div style='margin-left:30px'>
                1#Did you go to the museum yesterday, Sally?<br>
                Yes, I did. I 2#with my friends.<br>
                Did you buy a model?<br>
                Yes, I did. And Lea 3#a postcard.<br>
                Did you eat your sandwiches inside?<br>
                No, we 4#. We 5#them outside.<br>
                Did you think it was fun?<br>
                Yes, I did, but my friends 6#it was scary!
            </div>
        </div>
        
        `,
        answer: ["Did","went","bought","didn't","ate","thought"],
      },
    ],
  },
  3:{
    unit: "Starter",
    id: "WB5-2024-Starter-P49-E3",
    audio: "Audios/Page48/Track 085.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and read. <headphone name='85' src='Audios/Page48/Track 085 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    styleContent:{
      width: "90%",
      marginLeft:50
    },
    questionImage: [
      [
        {url: "img/FriendsPlus/Page8/E3/1.jpg",},
       
      ],
    ],
  },
  4: {
    unit: "Starter",
    id: "WB5-2024-Starter-P49-E4",
    audio: "",
    video: "",
     
    exerciseKey: "img/FriendsPlus/Page8/Key/E4answerKey.png",
    titleQuestion: [
      {
        num: "4",
        title:
          "Read again and circle the correct answer. ",
        color: "#203c8f",
        numberImg:"b",
        left: 0
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "580px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "580px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "171px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 4
          
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",
          
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color:"black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a.| <br> b.| <br> c.|",
          "a.| <br> b.| <br> c.|",
          "a.| <br> b.| <br> c.|",
          "a.| <br> b.| <br> c.| ",
          "a.| <br> b.| <br> c.|",
        ],
        answers: ['0-6', '1-12', '4-0', '2-6', '3-0'],
        initialValue: [],
      },
      Layout: `
      <img style='height:10cm' src='img/FriendsPlus/Page8/E3/1.jpg'/>
     <div style='display:flex'>
      <div style='margin-right:50px;'>
       <div><b>1.</b> Mr. Khanh was____________.
        <div style='display:flex'>
          <div><input id='0' type='Circle'/></div>
          <div>a scientist <br> a farmer <br> a tourist</div>
        </div>
       </div>
       <div style='width:10cm'><b>3.</b> When Mr. Khanh found the cave he was____________.
        <div style='display:flex'>
          <div><input id='4' type='Circle'/></div>
          <div>2023 <br> 2009 <br> 1991</div>
        </div>
       </div>
      </div> 
       
      <div> 
       <div style='width:10cm'><b>2.</b> Mr. Khanh first found the cave in____________.
        <div style='display:flex'>
          <div><input id='1' type='Circle'/></div>
          <div>suprised <br> sad <br> scared</div>
        </div>
       </div>
       <div><b>4.</b> The cave is very____________.
        <div style='display:flex'>
          <div><input id='2' type='Circle'/></div>
          <div>old <br> big <br> small</div>
        </div>
       </div>
      </div> 
    </div> 
       <div><b>5.</b> Inside the cave there is____________.
        <div style='display:flex'>
          <div><input id='3' type='Circle'/></div>
          <div>a jungle <br> a farm <br> a museum</div>
        </div>
       </div>
      `,
    },
  },
  5: {
    unit: "Starter",
    id: "WB5-2024-Starter-P49-E5",
    audio: "",
    video: "",
     
    component: T6,
    // maxLength: 5,
    // textAlign: "center",
    textareaStyle: {
      width: 700,
      paddingTop:5
    },
    inputSize: 720,
    titleQuestion: [
      {
        num: "5",
        title:
          "Would you like to visit a cave? Why / Why not?",
        color: "#203c8f",
        left:80
      },
    ],
    questionImage: [],
    isHello: true,
    questions: [
      {
        title: `
				<textarea id='0' rows=7 ></textarea>
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
