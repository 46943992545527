import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 12",
    id: "WB5-2024-U12-P81-E1",
    // audio: "Audios/30-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page81/Key/E1answerKey.png",
    // stylesTextInput: {
    //   width: 300,
    // },
    titleQuestion: [
      {
        num: "1",
        title: "Match.",
        color: "#2d408e",
        left: -30,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        lineToNotCenter: true,
        // lineDirection: "row",
        multipleLine: false,
        sort: true,
        colorLineTo: "#40c7f4",
        widthLineto: 2,
        boxMatch: [
          {
            children: `
              <div style='display: flex; gap: 5px; align-items: center'><span><b>1. </b>What are you going to do tonight?</span><div style='flex-grow: 1; height: 1px; border: 1px dashed rgb(64, 199, 244)'></div></div>
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 0
          {
            children: `
              <div style='display: flex; gap: 5px; align-items: center'><span><b>2. </b>What is Lam going to do on <br>the weekend?</span><div style='flex-grow: 1; height: 1px; border: 1px dashed rgb(64, 199, 244)'></div></div>
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 1
          {
            children: `
              <div style='display: flex; gap: 5px; align-items: center'><span><b>3. </b>Is Mom going to make lunch?</span><div style='flex-grow: 1; height: 1px; border: 1px dashed rgb(64, 199, 244)'></div></div>
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 2
          {
            children: `
              <div style='display: flex; gap: 5px; align-items: center'><span><b>4. </b>What are Kim and Joe going to <br>do on Saturday?</span><div style='flex-grow: 1; height: 1px; border: 1px dashed rgb(64, 199, 244)'></div></div>
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 3
          {
            children: `
              <div style='display: flex; gap: 5px; align-items: center'><span><b>5. </b>Are you going to do your <br>homework?</span><div style='flex-grow: 1; height: 1px; border: 1px dashed rgb(64, 199, 244)'></div></div>
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 4
          {
            children: `
              <span><b>a. </b>No, she isn't. Dad's going <br>to make it.</span>
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 5
          {
            children: `
              <span><b>b. </b>They're going to visit <br>a museum.</span>
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 6
          {
            children: `
              <span><b>c. </b>He's going to play soccer <br>with his friends.</span>
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 7
          {
            children: `
              <span><b>d. </b>Yes, I am.</span>
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 8
          {
            children: `
              <span><b>e. </b>I'm going to read a book <br>on my tablet.</span>
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 9
        ],
        answers: ["1-7", "2-5", "3-6", "4-8"],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          textAlign: "center",
          width: 30,
          background: "none",
          fontSize: 22,
          maxLength: 1,
          border: "none",
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",

          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          // borderRadius: "50px",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [],
        answers: [],
        initialValue: [],
      },
      Layout: `
      <div style='display: flex; gap: 200px'>
        <div style='display: flex; flex-direction: column; gap: 20px;'>
          <span><b>1. </b>What are you going to do tonight?</span>
          <input id='1' type='boxMatch' />
          <input id='2' type='boxMatch' />
          <input id='3' type='boxMatch' />
          <input id='4' type='boxMatch' />
        </div>
        <div style='display: flex; flex-direction: column; gap: 20px;'>
          <input id='5' type='boxMatch' />
          <input id='6' type='boxMatch' />
          <input id='7' type='boxMatch' />
          <input id='8' type='boxMatch' />
          <span><b>e. </b>I'm going to read a book <br>on my tablet.</span>
        </div>
      </div>
      <div style="border-top: 2px solid rgb(64, 199, 244); position: absolute; top: 19.6406px; left: 421.969px; width: 424.655px; z-index: 1; transform: rotate(61.9027deg); transform-origin: 0px 0px;"></div>
      `,
    },
  },

  2: {
    unit: "Unit 12",
    id: "WB5-2024-U12-P81-E2",
    exerciseKey: "img/FriendsPlus/Page81/Key/E2answerKey.png",
    audio: "Audios/33-audio.mp3",
    video: "",
    inputSize: 170,
    maxLength: 1,
    textAlign: "center",
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and write the number. <span style='color: white'><headphone name='&nbsp 33' typeimg=sound src='Audios/33-tieude.mp3'></headphone></span>",
        color: "#203c8f",
        left: 55,
      },
    ],
    // styleHint: {
    //   border: "2px solid",
    //   justifyContent: "center",
    //   paddding: "0 10px",
    //   paddingBlock: "2px",
    //   borderColor: "#253f8e",
    //   color: "#57585a",
    //   marginLeft: "10px",
    //   styleElementHintBox: {
    //     margin: "0px 15px",
    //     fontWeight: "normal",
    //   },
    // },
    // hintBox: [
    //   {
    //     src: [
    //       "build",
    //       "<span class='strikediag'>crowded</span>",
    //       "playgrounds",
    //       "robots",
    //       "spaceships",
    //       "travel",
    //     ],
    //     width: "12cm",
    //     inline: true,
    //   },
    // ],
    component: T6,
    // hideBtnFooter: true
    stylesTextInput: {
      background: "none",
      border: "none",
      width: 30,
      height: 30,
      padding: 5,
    },
    questions: [
      {
        InputRong: true,
        title: `
        <div style='width: 25cm; display: grid; grid-template-columns: repeat(3,1fr); grid-gap: 20px'>
          <div style='position: relative'>
            <img style='width: 100%' src='img/FriendsPlus/Page81/E2/1.jpg'/>
            <div style='position: absolute; bottom: 0; right: 0'>
              #
            </div>
          </div>
          <div style='position: relative'>
            <img style='width: 100%' src='img/FriendsPlus/Page81/E2/2.jpg'/>
          </div>
          <div style='position: relative'>
            <img style='width: 100%' src='img/FriendsPlus/Page81/E2/3.jpg'/>
            <div style='position: absolute; bottom: 5px; right: 0'>
              #
            </div>
          </div>
          <div style='position: relative'>
            <img style='width: 100%' src='img/FriendsPlus/Page81/E2/4.jpg'/>
            <div style='position: absolute; bottom: 2px; right: 2px'>
              #
            </div>
          </div>
          <div style='position: relative'>
            <img style='width: 100%' src='img/FriendsPlus/Page81/E2/5.jpg'/>
            <div style='position: absolute; bottom: 5px; right: 0'>
              #
            </div>
          </div>
          <div style='position: relative'>
            <img style='width: 100%' src='img/FriendsPlus/Page81/E2/6.jpg'/>
            <div style='position: absolute; bottom: 5px; right: 0'>
              #
            </div>
          </div>
        </div>
        `,
        answer: ["6", "3", "4", "2", "5"],
      },
    ],
  },
  3: {
    unit: "Unit 12",
    id: "WB5-2024-U12-P81-E3",
    exerciseKey: "img/FriendsPlus/Page81/Key/E3answerKey.png",
    // audio: "Audios/33-audio.mp3",
    video: "",
    inputSize: 200,
    maxLength: 30,
    textAlign: "center",
    titleQuestion: [
      {
        num: "3",
        title: "Look at the pictures in exercise 2. Write short answers.",
        color: "#203c8f",
        left: 55,
      },
    ],
    // styleHint: {
    //   border: "2px solid",
    //   justifyContent: "center",
    //   paddding: "0 10px",
    //   paddingBlock: "2px",
    //   borderColor: "#253f8e",
    //   color: "#57585a",
    //   marginLeft: "10px",
    //   styleElementHintBox: {
    //     margin: "0px 15px",
    //     fontWeight: "normal",
    //   },
    // },
    // hintBox: [
    //   {
    //     src: [
    //       "build",
    //       "<span class='strikediag'>crowded</span>",
    //       "playgrounds",
    //       "robots",
    //       "spaceships",
    //       "travel",
    //     ],
    //     width: "12cm",
    //     inline: true,
    //   },
    // ],
    component: T6,
    // hideBtnFooter: true
    // stylesTextInput: {
    //   background: "none",
    //   border: "none",
    //   width: 30,
    //   height: 30,
    //   padding: 5,
    // },
    questions: [
      {
        InputRong: true,
        title: `
        <div style='width: 25cm; display: flex; flex-direction: column; gap: 10px'>
          <span><b>1. </b>Is Sally going to watch her favorite TV show about space? <u style='color:gray; text-underline-offset: 5px'>&nbspYes, she is.&nbsp</u></span>
          <span><b>2. </b>Are they going to cook dinner? #</span>
          <span><b>3. </b>Are they going to go for a walk in the park? #</span>
          <span><b>4. </b>Is Mom going to read a book on her tablet? #</span>
          <span><b>5. </b>Is Dad going to go to the supermarket? #</span>
          <span><b>6. </b>Is Filip going to play video games?#</span>
        </div>
        `,
        answer: [
          "Yes, they are.",
          "No, they aren't.",
          "No, she isn't.",
          "Yes, he is.",
          "No, he isn't.",
        ],
      },
    ],
  },
};

export default json;
