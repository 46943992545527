import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 1",
    id: "WB5-2024-U1-P10-E1",
    // audio: "Audios/Page10/E1/audio-e1.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page10/Key/E1answerKey.png",
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "dash",
    },
    inputSize: 100,
    titleQuestion: [
      {
        num: "1",
        title:
          "Look at the picture. Read the sentences. Fill in the correct circle.",
        color: "#2d408e",
        // left: -33,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "580px",
              width: "30px",
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "350px",
              width: "30px",
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "580px",
              width: "30px",
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "171px",
              left: "350px",
              width: "30px",
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 4
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          width: "25px",
          height: "25px",
          paddingLeft: "5px",
          border: "1px solid",
          borderRadius: "50%",
          color: "transparent",
          backgroundColor: "transparent",
          borderColor: "black",
          fontWeight: "bold",
          lineHeight: "25px",
          fontSize: "20px",
        },
        selectWordStyle: {
          backgroundColor: "gray",
          borderColor: "gray",
        },
        limitSelect: 1,
        listWords: ["✓  ✓  ✓", "✓  ✓  ✓", "✓  ✓  ✓"],
        answers: ["0-0", "1-4", "2-8"],
        initialValue: ["0-0"],
      },
      Layout: `
      <div style='display:flex; flex-direction:column; gap: 20px; margin-left: 50px'>
        <div style='display:flex; gap: 20px; width: 100%'>
          <img style='width:25%' src='img/FriendsPlus/Page10/E1/1.jpg'/>
          <div style='display:flex; flex-direction:column; gap: 5px'>
            <span>(A) Karl does his homework in the morning.</span>
            <span>(B) Karl rides his bike in the morning.</span>
            <span>(C) Karl doesn’t do his homework in the morning.</span>
          </div>
          <div style='display:flex; flex-direction:column; gap: 5px; padding-top: 8px; margin-left: auto;'><input id=0 type='Circle'/></div>
        </div>
        <div style='display:flex; gap: 20px; width: 100%'>
          <img style='width:25%' src='img/FriendsPlus/Page10/E1/2.jpg'/>
          <div style='display:flex; flex-direction:column; gap: 5px'>
            <span>(A) Karl doesn’t have a snack at 11.30 a.m.</span>
            <span>(B) Karl has a snack at 11.30 a.m.</span>
            <span>(C) Karl has lunch at 11 a.m.</span>
          </div>
          <div style='display:flex; flex-direction:column; gap: 5px; padding-top: 8px; margin-left: auto;'><input id=1 type='Circle'/></div>
        </div>
        <div style='display:flex; gap: 20px; width: 100%'>
          <img style='width:25%' src='img/FriendsPlus/Page10/E1/3.jpg'/>
          <div style='display:flex; flex-direction:column; gap: 5px'>
            <span>(A) Karl doesn’t catch the bus after school.</span>
            <span>(B) Karl walks home after school.</span>
            <span>(C) Karl catches the bus after school.</span>
          </div>
          <div style='display:flex; flex-direction:column; gap: 5px; padding-top: 8px; margin-left: auto;'><input id=2 type='Circle'/></div>
        </div>
      </div>
      `,
    },
  },
  2: {
    unit: "Unit 1",
    id: "WB5-2024-U1-P10-E2",
    audio: "Audios/02-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page10/Key/E2answerKey.png",
    textAlign: "center",
    stylesTextInput: {
      padding: 5,
      width: "calc(100% - 5px)",
      height: "calc(100% - 20px)",
      borderWidth: "0 0 1px",
      borderColor: "black",
    },
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen to the song. Complete the sentences. Then put the lines in the correct order (1–8). <span style='color: white'><headphone name='&nbsp 02' typeimg=sound src='Audios/02-tieude.mp3'></headphone></span>",
        color: "#2d408e",
        left: 50,
      },
    ],
    component: T6,
    notification: false,
    questions: [
      {
        title: `
          <div style='text-align: center; width: 100%; font-weight: bold; color: rgb(246, 140, 50); -webkit-text-stroke: 3px rgb(230, 231, 233); paint-order: stroke fill'>Her day</div>
          <div style='display:grid; grid-template-columns: 1fr 1fr; grid-gap: 30px; margin-top: 20px'>
            <div style='display: flex; gap: 10px; align-items: center'>
              <img style='width: 25%' src='img/FriendsPlus/Page10/E2/1.jpg'/>
              <span><div style='display: inline-block; width: 50px; height: 50px; padding-top: 5px; border: 1px solid black; border-radius: 5px'><input id=0 maxlength="1" style="border-width: 0 0 0"/></div>&nbsp She <div style='display: inline-block; width: 170px'>#</div> a shower every day.</span>
            </div>
            <div style='display: flex; gap: 10px; align-items: center'>
              <img style='width: 25%' src='img/FriendsPlus/Page10/E2/2.jpg'/>
              <span><div style='display: inline-block; width: 50px; height: 50px; padding-top: 5px; border: 1px solid black; border-radius: 5px'><input id=2 maxlength="1" style="border-width: 0 0 0"/></div>&nbsp Then she <div style='display: inline-block; width: 140px'>#</div> her school friends every day.</span>
            </div>
            <div style='display: flex; gap: 10px; align-items: center'>
              <img style='width: 25%' src='img/FriendsPlus/Page10/E2/3.jpg'/>
              <span><div style='display: inline-block; width: 50px; height: 50px; padding-top: 5px; border: 1px solid black; border-radius: 5px'><input id=4 maxlength="1" style="border-width: 0 0 0"/></div>&nbsp She <div style='display: inline-block; width: 140px'>#</div> outside and plays.</span>
            </div>
            <div style='display: flex; gap: 10px; align-items: center'>
              <img style='width: 25%' src='img/FriendsPlus/Page10/E2/4.jpg'/>
              <span><div style='display: inline-block; width: 50px; height: 50px; padding-top: 5px; border: 1px solid black; border-radius: 5px'><input id=6 maxlength="1" style="border-width: 0 0 0"/></div>&nbsp She <div style='display: inline-block; width: 140px'>#</div> her lessons every day.</span>
            </div>
            <div style='display: flex; gap: 10px; align-items: center'>
              <img style='width: 25%' src='img/FriendsPlus/Page10/E2/5.jpg'/>
              <span><div style='display: inline-block; width: 50px; height: 50px; padding-top: 5px; border: 1px solid black; border-radius: 5px'><input id=8 maxlength="1" style="border-width: 0 0 0"/></div>&nbsp She <div style='display: inline-block; width: 140px'>#</div> her homework every day.</span>
            </div>
            <div style='display: flex; gap: 10px; align-items: center'>
              <img style='width: 25%' src='img/FriendsPlus/Page10/E2/6.jpg'/>
              <span><div style='display: inline-block; width: 50px; height: 50px; padding-top: 5px; border: 1px solid black; border-radius: 5px'><input id=10 maxlength="1" style="border-width: 0 0 0"/></div>&nbsp She <div style='display: inline-block; width: 140px'>#</div> her breakfast every day.</span>
            </div>
            <div style='display: flex; gap: 10px; align-items: center'>
              <img style='width: 25%' src='img/FriendsPlus/Page10/E2/7.jpg'/>
              <span><div style='display: inline-block; width: 50px; height: 50px; padding-top: 5px; border: 1px solid black; border-radius: 5px'><span style='display: inline-block; color: rgb(147, 149, 152); width: 100%; text-align: center'>1</span></div>&nbsp She <div style='display: inline-block; width: 100px'><span style='text-decoration: underline; text-underline-offset: 5px; color: rgb(147, 149, 152)'>&nbspgets up&nbsp</span></div> early every day.</span>
            </div>
            <div style='display: flex; gap: 10px; align-items: center'>
              <img style='width: 25%' src='img/FriendsPlus/Page10/E2/8.jpg'/>
              <span><div style='display: inline-block; width: 50px; height: 50px; padding-top: 5px; border: 1px solid black; border-radius: 5px'><input id=12 maxlength="1" style="border-width: 0 0 0"/></div>&nbsp She <div style='display: inline-block; width: 140px'>#</div> the school bus every day.</span>
            </div>
          </div>
        `,
        answer: [
          "2",
          "takes",
          "6",
          "sees",
          "8",
          "goes",
          "5",
          "has",
          "7",
          "does",
          "3",
          "has",
          "4",
          "catches",
        ],
      },
    ],
  },
};

export default json;
