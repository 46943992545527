import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    id: "WB5-2024-U4-P33-E1",
    // audio: "Audios/03-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page33/Key/E1answerKey.png",
    stylesTextInput: {
      width: 120,
    },
    textAlign: "center",
    titleQuestion: [
      {
        num: "1",
        title:
          "<span style='display: inline-block; margin-top: -6px'> Write. <hintbox id=0></hintbox></span>",
        color: "#2d408e",
        left: 50,
      },
    ],
    component: T6,
    styleHint: {
      border: "1px solid",
      justifyContent: "center",
      // gap: 0,
      borderColor: "#f15921",
      color: "#57585a",
      flexWrap: "nowrap",
      padding: "5px 30px",
      marginLeft: 30,
      fontWeight: 400,
      styleElementHintBox: {
        // background: "#2bbbf1",
        // padding: "2px 5px",
        // borderRadius: "5px",
      },
    },
    hintBox: [
      {
        src: [
          "is",
          "isn't",
          "was",
          "<span class='strikediag'>wasn't</span>",
          "are",
          "aren't",
          "were",
          "weren't",
        ],
        width: "max-content",
        inline: true,
      },
    ],
    questions: [
      {
        title: `
          <div style='width: 26cm; border: 2px solid rgb(247, 154, 108); padding: 15px 30px 15px 20px; border-radius: 20px; display: flex; flex-direction: column; gap: 15px'>
            <span>Fifty years ago, there <span style='white-space: nowrap'><sup>1</sup><u style='color:gray; text-underline-offset: 5px'>&nbspwasn't&nbsp</u></span> a shopping mall in my town. There <span style='white-space: nowrap'><sup>2</sup>#</span> only one café. There was a museum then. There <span style='white-space: nowrap'><sup>3</sup>#</span> any buses, but there <span style='white-space: nowrap'><sup>4</sup>#</span> trolleys.</span><span>Now, there <span style='white-space: nowrap'><sup>5</sup>#</span> lots of cafés. There <span style='white-space: nowrap'><sup>6</sup>#</span> a shopping mall, but there <span style='white-space: nowrap'><sup>7</sup>#</span> a museum. There are buses now, but there <span style='white-space: nowrap'><sup>8</sup>#</span> any trolleys.</span>
          </div>
        `,
        answer: [
          "was",
          "weren't",
          "were",
          "are",
          "isn't|is",
          "is|isn't",
          "aren't",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 4",
    id: "WB5-2024-U4-P33-E2",
    audio: "",
    video: "",
    stylesTextInput: { background: "none", border: "none", paddingLeft: 0 },
    textareaStyle: {
      width: 700,
      paddingTop: 5,
    },
    // maxLength: 119,
    // textAlign: "center",
    inputSize: 320,
    titleQuestion: [
      {
        num: "2",
        title: "Complete the notes about a place that you know.",
        color: "#2d408e",
        left: 50,
        // prefix:[{text:"hello",icon:"fas fa-pen"}]
      },
    ],
    // character: "",
    hideBtnFooter: true,
    component: T6,
    notification: false,
    questions: [
      {
        title: `
          <div style='display: flex; flex-direction: column; width: 25cm; border: 1px solid rgb(220, 221, 223); font-size: 20px; box-shadow: 5px 5px rgb(233, 233, 233);padding-bottom: 10px'>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 1fr 1fr; position: relative'>
              <span style='color: rgb(0,136,202)'><b>Then</b></span>
              <span style='color: rgb(0,136,202)'><b>Now</b></span>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 1fr 1fr; position: relative'>
              <span style='color: rgb(0,136,202)'>There was <input placeholder='...'> </span>
              <span style='color: rgb(0,136,202)'>There is <input placeholder='...'> </span>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 1fr 1fr; position: relative'>
              <span style='color: rgb(0,136,202)'>There wasn’t <input placeholder='...'> </span>
              <span style='color: rgb(0,136,202)'>There isn’t <input placeholder='...'> </span>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 1fr 1fr; position: relative'>
              <span style='color: rgb(0,136,202)'>There were <input placeholder='...'> </span>
              <span style='color: rgb(0,136,202)'>There are <input placeholder='...'> </span>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 1fr 1fr; position: relative'>
              <span style='color: rgb(0,136,202)'>There weren’t <input placeholder='...'> </span>
              <span style='color: rgb(0,136,202)'>There aren’t <input placeholder='...'> </span>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 1fr 1fr; position: relative'>
              <span style='color: rgb(0,136,202)'>&nbsp</span>

            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 1fr 1fr; position: relative'>
              <span style='color: rgb(0,136,202)'>&nbsp</span>
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 1fr 1fr; position: relative'>
              <span style='color: rgb(0,136,202)'>&nbsp</span>
            </div>
          </div>
        `,
        InputRong: true,
        answer: [""],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "WB5-2024-U4-P33-E3",
    audio: "",
    video: "",
    component: T6,
    // maxLength: 5,
    // textAlign: "center",
    stylesTextInput: { background: "none", border: "none", paddingLeft: 0 },
    textareaStyle: {
      width: "calc(100% - 10px)",
      resize: "none",
      paddingTop: 5,
      // borderBottom: "1px solid rgb(168, 168, 168)",
    },
    inputSize: 500,
    titleQuestion: [
      {
        num: "3",
        title: "Write about a place that you know. Use your notes to help you.",
        color: "#2d408e",
        left: 50,
      },
    ],
    hideBtnFooter: true,
    // isHello: true,
    questions: [
      {
        title: `
          <div style='width: 25cm; border: 3px solid rgb(247, 154, 108); padding: 10px 10px 0 10px; border-radius: 10px'>
            <textarea id='0' rows=6 ></textarea>
          </div>
        `,
        answer: [""],
        initialValue: [""],
      },
    ],
  },
};

export default json;
