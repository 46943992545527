import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 3",
    id: "WB5-2024-U3-P23-E1",
    // audio: "Audios/06-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page23/Key/E1answerKey.png",
    // textAlign: "center",
    stylesTextInput: {
      width: 50,
      paddingLeft: 0,
      borderWidth: "0 0 1px",
    },
    titleQuestion: [
      {
        num: "1",
        title: "Write s or es.",
        color: "#2d408e",
        left: 50,
      },
    ],
    component: T6,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <div style='width: 25cm; display: grid; grid-template-columns: 1fr 1fr; grid-gap: 10px'>
            <span><b>1. </b>balloon<u style='color:gray; text-underline-offset: 5px'>s&nbsp</u></span>
            <span><b>4. </b>sandwich#</span> 
            <span><b>2. </b>cookie#</span>
            <span><b>5. </b>box#</span> 
            <span><b>3. </b>hat#</span>
            <span><b>6. </b>mask#</span> 
          </div>
        `,
        answer: ["es", "s", "es", "s", "s"],
      },
    ],
  },
  2: {
    unit: "Unit 3",
    id: "WB5-2024-U3-P23-E2",
    audio: "Audios/09-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page23/Key/E2answerKey.png",
    textAlign: "center",
    stylesTextInput: {
      width: 200,
    },
    titleQuestion: [
      {
        num: "2",
        title:
          "Write the words. Then listen and check. <span style='color: white'><headphone name='&nbsp 09' typeimg=sound src='Audios/09-tieude.mp3'></headphone></span>",
        color: "#2d408e",
        left: 50,
      },
    ],
    component: T6,
    styleHint: {
      border: "2px solid",
      justifyContent: "center",
      // gap: 0,
      borderColor: "#1eb26a",
      color: "#57585a",
      flexWrap: "nowrap",
      padding: "5px 30px",
      styleElementHintBox: {
        // background: "#2bbbf1",
        // padding: "2px 5px",
        // borderRadius: "5px",
      },
    },
    hintBox: [
      {
        src: [
          "balloons",
          "<span class='strikediag'>boxes</span>",
          "cookies",
          "masks",
          "sandwiches",
        ],
        width: "max-content",
      },
    ],
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='width: 25cm; margin-top: 20px; display: flex; gap: 20px; justify-content: space-between; align-items: center'>
          <div style='display: flex; flex-direction: column; gap: 5px'>
            <span>What’s in the <sup>1</sup><u style='color:gray; text-underline-offset: 5px'>&nbspboxes&nbsp</u>? </span>
            <span>What’s in there?</span>
            <span>Are there some cakes?</span>
            <span>Or some <sup>2</sup># to share?</span>
            <span>Are there some <sup>3</sup>#? </span>
            <span>Or some <sup>4</sup>#?</span>
            <span>Look! <sup>5</sup># and hats!</span>
            <span>The party’s this afternoon.</span>
          </div>
          <div style='margin: auto 0 auto'><img src='img/FriendsPlus/Page23/E2/1.jpg' style='width: 12cm'/></div>
        </div>
        `,
        answer: ["cookies", "sandwiches", "balloons", "masks"],
      },
    ],
  },
  3: {
    unit: "Unit 1",
    id: "WB5-2024-U1-P11-E2",
    // audio: "Audios/03-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page23/Key/E3answerKey.png",
    // fontSize: 26,
    titleQuestion: [
      {
        num: "3",
        title: "Match the words with the same sound at the end.",
        color: "#2d408e",
        left: -95,
      },
    ],
    maxLength: 1,
    component: Circle_Write,
    question: {
      DrawLines: {
        colorLineTo: "blue",
        widthLineto: 2,
        lineToNotCenter: true,
        multipleLine: false,
        boxMatch: [
          {
            children:
              "<div style='padding: 10px; border-radius: 20px; background: rgb(211, 239, 251)'>balloons</div>",
            boxMatchStyle: {
              // border: "1px solid blue",
              // background: "white",
            },
          }, // 0
          {
            children:
              "<div style='padding: 10px; border-radius: 20px; background: rgb(254, 233, 213)'>boxes</div>",
            boxMatchStyle: {
              // border: "1px solid blue",
              // background: "white",
            },
          }, // 1
          {
            children:
              "<div style='padding: 10px; border-radius: 20px; background: rgb(225, 238, 221)'>masks</div>",
            boxMatchStyle: {
              // border: "1px solid blue",
              // background: "white",
            },
          }, // 2

          {
            children:
              "<div style='padding: 10px; border-radius: 20px; background: rgb(251, 223, 235)'>sandwiches</div>",
            boxMatchStyle: {
              // border: "1px solid blue",
              // background: "white",
            },
          }, // 3
          {
            children:
              "<div style='padding: 10px; border-radius: 20px; background: rgb(247, 218, 206)'>cookies</div>",
            boxMatchStyle: {
              // border: "1px solid blue",
              // background: "white",
            },
          }, // 4
          {
            children:
              "<div style='padding: 10px; border-radius: 20px; background: rgb(212, 212, 231)'>hats</div>",
            boxMatchStyle: {
              // border: "1px solid blue",
              // background: "white",
            },
          }, // 5
        ],
        answers: ["1-3", "0-4"],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          textAlign: "center",
          width: 30,
          background: "none",
          fontSize: 22,
          maxLength: 1,
          border: "none",
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",

          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          // borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [],
        answers: [],
        initialValue: [],
      },
      Layout: `
      <div style='position:relative;width:20cm;height:8cm'>
        <div style='position: absolute; left: 0px; top: 32px'><input id='0' type='boxMatch' /></div>
        <div style='position: absolute; left: 112px; top: 238px'><input id='1' type='boxMatch' /></div>
        <div style='position: absolute; left: 166px; top: 51px'><div style='padding: 10px; border-radius: 20px; background: rgb(225, 238, 221)'>masks</div></div>
        <div style='position: absolute; left: 344px; top: 266px'><input id='3' type='boxMatch' /></div>
        <div style='position: absolute; left: 479px; top: 50px'><input id='4' type='boxMatch' /></div>
        <div style='position: absolute; left: 608px; top: 165px'><div style='padding: 10px; border-radius: 20px; background: rgb(212, 212, 231)'>hats</div></div>
        <div style="border-top: 2px solid blue; position: absolute; top: 81px; left: 261.07px; width: 364.23px; z-index: 1; transform: rotate(18.2395deg); transform-origin: 0px 0px;"></div>
      </div>
      `,
    },
  },
};

export default json;
