import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 2",
    id: "WB5-2024-U2-P15-E1",
    audio: "Audios/04-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page15/Key/E1answerKey.png",
    stylesTextInput: {
      width: 300,
    },
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen and draw a line from the day to the correct picture. <span style='color: white'><headphone name='&nbsp 04' typeimg=sound src='Audios/04-tieude.mp3'></headphone></span>",
        color: "#2d408e",
        left: -50,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        lineToNotCenter: true,
        multipleLine: false,
        sort: true,
        colorLineTo: "#40c7f4",
        widthLineto: 2,
        boxMatch: [
          {
            children: `
              <img
                style='width: 5cm'
                src="img/FriendsPlus/Page15/E1/1.jpg"
              />
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              width: "5cm",
              // background: "white",
            },
          }, // 0
          {
            children: `
              <img
                style='width: 5cm'
                src="img/FriendsPlus/Page15/E1/3.jpg"
              />
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              marginLeft: 30,
              // background: "white",
            },
          }, // 1
          {
            children: `
              <img
                style='width: 5cm'
                src="img/FriendsPlus/Page15/E1/5.jpg"
              />
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              width: "5cm",
              // background: "white",
            },
          }, // 2

          {
            children: `
              <span>Mondays</span>
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 3
          {
            children: `
              <span>Tuesdays</span>
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 4
          {
            children: `
              <span>Wednesdays</span>
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 5
          {
            children: `
              <span>Thursdays</span>
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 6
          {
            children: `
              <span>Fridays</span>
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 7
          {
            children: `
              <span>Saturdays</span>
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 8
          {
            children: `
              <span>Sundays</span>
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              // background: "white",
            },
          }, // 9
          {
            children: `
              <img
                style='width: 5cm'
                src="img/FriendsPlus/Page15/E1/2.jpg"
              />
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              width: "5cm",
              // background: "white",
            },
          }, // 10
          {
            children: `
              <img
                style='width: 5cm'
                src="img/FriendsPlus/Page15/E1/4.jpg"
              />
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              marginLeft: 30,
              // background: "white",
            },
          }, // 11
          {
            children: `
              <img
                style='width: 5cm'
                src="img/FriendsPlus/Page15/E1/6.jpg"
              />
            `,
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "relative",
              width: "5cm",
              // background: "white",
            },
          }, // 12
        ],
        answers: ["3-11", "2-4", "6-12", "7-10", "1-8", "0-9"],
        initialValue: ["3-11"],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          textAlign: "center",
          width: 30,
          background: "none",
          fontSize: 22,
          maxLength: 1,
          border: "none",
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",

          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          // borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [],
        answers: [],
        initialValue: [],
      },
      Layout: `
      <div style='display: flex; gap: 80px;'>
        <div style='display: flex; flex-direction: column; gap: 50px'>
          <input id='0' type='boxMatch' />
          <input id='1' type='boxMatch' />
          <input id='2' type='boxMatch' />
        </div>
        <div style='display: flex; flex-direction: column; gap: 10px; justify-content: center; align-items: center'>
          <input id='3' type='boxMatch' />
          <input id='4' type='boxMatch' />
          <input id='5' type='boxMatch' />
          <input id='6' type='boxMatch' />
          <input id='7' type='boxMatch' />
          <input id='8' type='boxMatch' />
          <input id='9' type='boxMatch' />
        </div>
        <div style='display: flex; flex-direction: column; gap: 50px'>
          <input id='10' type='boxMatch' />
          <input id='11' type='boxMatch' />
          <input id='12' type='boxMatch' />
        </div> 
      </div>
      `,
    },
  },
  2: {
    unit: "Unit 2",
    id: "WB5-2024-U2-P15-E2",
    // audio: "Audios/03-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page15/Key/E2answerKey.png",
    textAlign: "center",
    stylesTextInput: {
      width: 80,
    },
    titleQuestion: [
      {
        num: "2",
        title: "Complete the sentences about Jane with in, on, or at.",
        color: "#2d408e",
        left: 50,
      },
    ],
    component: T6,
    questions: [
      {
        title: `
        <div style='width: 23cm; display: flex; gap: 30px; justify-content: space-between'>
          <div style='display: flex; flex-direction: column; gap: 10px'>
            <span><b>1. </b>She always gets up <u style='color:gray; text-underline-offset: 5px'>&nbspat&nbsp</u> eight o’clock. </span>
            <span><b>2. </b>She never goes to school # Sundays. </span>
            <span><b>3. </b>She usually visits her cousins # July. </span>
            <span><b>4. </b>She sometimes goes to bed # nine thirty.</span>
            <span><b>5. </b>She often plays soccer # Saturdays.</span>
            <span><b>6. </b>She never wears a coat # August.</span>
          </div>
          <div style='margin: auto 0 auto'><img src='img/FriendsPlus/Page15/E2/1.jpg' style='width: 5cm'/></div>
        </div>
        `,
        answer: ["on", "in", "at", "on", "in"],
      },
    ],
  },
};

export default json;
