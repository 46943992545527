import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import circleInstruction from "../../assets/img/newCircleInstruction.gif";

const json = {
  //Câu 1 chưa làm
  1: {
    unit: "Unit 3",
    id: "WB5-2024-U3-P24-E1",
    // audio: "Audios/05-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page24/Key/E1answerKey.png",
    // textAlign: "center",
    titleQuestion: [
      {
        num: "1",
        title: "Circle the words and write.",
        color: "#2d408e",
        left: -30,
        instruction: {
          children: (
            <div className="text-center mt-3">
              <div style={{ fontSize: 16 }}>
                Click on the first character then drag to the last character of
                the word to circle it.
              </div>
              <img
                src={circleInstruction}
                alt="circle instruction"
                style={{ maxWidth: "100%" }}
              ></img>
            </div>
          ),
        },
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        colorLineTo: "#40c7f4",
        newPointDot: true,
        notOverlapse: true,
        circlePadding: "5 1",
        margin: "5px 2px",
        circleStringArray: ["beansproutsricepapergarlicfishsauce"],
        boxMatch: [],
        answers: ["0_0-0_10", "0_11-0_19", "0_20-0_25", "0_26-0_34"],
        initialValue: ["0_11-0_19"],
      },
      Write: {
        inputStyle: { width: 200 },
        answers: ["bean sprouts", "garlic", "fish sauce"],
        initialValue: [],
      },
      Circle: {
        isHiddenCheck: true,
        newSplit: true,
        initialWordStyle: {
          border: "2px solid",
          borderColor: "transparent",
          padding: "1px 5px",
          borderRadius: "50%",
          fontSize: 20,
        },
        selectWordStyle: {
          borderColor: "#40c7f4",
        },
        limitSelect: 1,
        listWords: [
          "soda / ice_tea",
          "mushroom / meat",
          "lemonade / melon",
          "onion / melon",
          "onion / meat",
          "lemonade / ice_tea",
          "cucumber / lemonade",
          "onion / mushrooms",
        ],
        answers: [],
        initialValue: [],
      },
      Layout: `
        <div id='circle-container' style='display: flex; position: relative'><input id=0 type='boxMatch'/></div>
        <div style='display: flex; gap: 20px; justify-content: space-between; width: 20cm'>
          <div style='display: flex; flex-direction: column; gap: 10px'>
            <span><b>1. </b><u style='color:gray; text-underline-offset: 5px'>&nbsprice paper&nbsp</u></span>
            <span><b>2. </b>#</span>
            <span><b>3. </b>#</span>
            <span><b>4. </b>#</span>
          </div>
          <img src='img/FriendsPlus/Page24/E1/1.jpg' style='height: 8cm'/>
        </div>
      `,
    },
  },
  2: {
    unit: "Unit 3",
    id: "WB5-2024-U3-P24-E2",
    audio: "Audios/10-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page24/Key/E2answerKey.png",
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "dash",
    },
    inputSize: 100,
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen to a woman planning a shopping trip. Fill in the correct circle. <span style='color: white'><headphone name='&nbsp 10' typeimg=sound src='Audios/10-tieude.mp3'></headphone></span>",
        color: "#2d408e",
        left: -33,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          width: "23px",
          height: "23px",
          paddingLeft: 3,
          border: "1px solid",
          borderRadius: "50%",
          color: "transparent",
          backgroundColor: "transparent",
          borderColor: "black",
          fontWeight: "bold",
          lineHeight: "25px",
          fontSize: "18px",
        },
        selectWordStyle: {
          backgroundColor: "gray",
          borderColor: "gray",
        },
        limitSelect: 1,
        listWords: ["✓  ✓  ✓", "✓  ✓  ✓", "✓  ✓  ✓", "✓  ✓  ✓"],
        answers: ["0-4", "2-0", "1-8", "3-8"],
        initialValue: ["0-4"],
      },
      Layout: `
        <div style='width: 20cm; display:flex; flex-direction: column; gap: 20px'>
          <div>
            <span><b>1. </b>What does Grandma need to buy?</span>
            <div style='display:flex; gap: 20px; width: 100%; padding-left: 20px'>
            <div style='display:flex; flex-direction:column; gap: 5px'>
              <span>(A) noodles and garli</span>
              <span>(B) fish sauce and bean sprouts</span>
              <span>(C)) fish sauce and garlic</span>
            </div>
            <div style='display:flex; flex-direction:column; gap: 5px; padding-top: 10px; margin-left: auto;'><input id=0 type='Circle'/></div>
            </div>
          </div>
          <div>
            <span><b>2. </b>What does Grandma need to buy for the cake?</span>
            <div style='display:flex; gap: 20px; width: 100%; padding-left: 20px'>
              <div style='display:flex; flex-direction:column; gap: 5px'>
              
                <span>(A) eggs</span>
                <span>(B) melon</span>
                <span>(C) ice cream</span>
              </div>
              <div style='display:flex; flex-direction:column; gap: 5px; padding-top: 10px; margin-left: auto;'><input id=2 type='Circle'/></div>
            </div>
          </div>
          <div>
            <span><b>3. </b>Why does she need some oranges and one melon?</span>
            <div style='display:flex; gap: 20px; width: 100%; padding-left: 20px'>
            <div style='display:flex; flex-direction:column; gap: 5px'>
              <span>(A) She is making a fruit salad.</span>
              <span>(B) They are her favorite fruits.</span>
              <span>(C) Grandpa likes them.</span>
            </div>
            <div style='display:flex; flex-direction:column; gap: 5px; padding-top: 10px; margin-left: auto;'><input id=1 type='Circle'/></div>
            </div>
          </div>
          <div>
            <span><b>4. </b>What does Peter want?</span>
            <div style='display:flex; gap: 20px; width: 100%; padding-left: 20px'>
                <div style='display:flex; flex-direction:column; gap: 5px'>
                  <span>(A) a melon</span>
                  <span>(B) ice tea</span>
                  <span>(C) cookies</span>
                </div>
                <div style='display:flex; flex-direction:column; gap: 5px; padding-top: 10px; margin-left: auto;'><input id=3 type='Circle'/></div>
              </div>
            </div>
          </div>
      `,
    },
  },
};

export default json;
