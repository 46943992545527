import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Starter",
    id: "WB5-2024-U1-P9-E1",
    // audio: "Audios/Page59/Track 086.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page9/Key/E1answerKey.png",
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "dash",
    },
    inputSize: 100,
    titleQuestion: [
      {
        num: "1",
        title: "Read and circle the correct word.",
        color: "#2d408e",
        left: -40,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "580px",
              width: "30px",
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "350px",
              width: "30px",
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "580px",
              width: "30px",
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "171px",
              left: "350px",
              width: "30px",
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 4
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 5px",
          border: "2px solid",
          borderColor: "transparent",
          fontWeight: "bold",
          borderRadius: "50%",
        },
        selectWordStyle: {
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "go / goes",
          "catches / catch",
          "have / has",
          "has / have",
          "don't / doesn't",
          "go / don't_go",
        ],
        answers: ["0-0", "1-0", "2-0", "3-0", "4-0", "5-4"],
        initialValue: ["0-0"],
      },
      Layout: `
      <div style='width: 100%; text-align: center;'>
        <img style='width:8cm' src='img/FriendsPlus/Page9/E1/1.jpg'/>
      </div>
      <div style='display:flex; flex-direction:column; gap:5px'>
        <span><b>1. </b>Hoa and Vinh <input id=0 type='Circle'/> to primary school.</span>
        <span><b>2. </b>Hoa <input id=1 type='Circle'/> the bus to school on weekdays. </span>
        <span><b>3. </b>Vinh doesn't <input id=2 type='Circle'/> a snack at school.</span>
        <span><b>4. </b>He <input id=3 type='Circle'/> lunch at 12.30.</span>
        <span><b>5. </b>They <input id=4 type='Circle'/> walk home after school.</span>
        <span><b>6. </b>We <input id=5 type='Circle'/> to school every day.</span>
      </div>
      `,
    },
  },
  2: {
    unit: "Starter",
    id: "WB5-2024-U1-P9-E2",
    // audio: "Audios/Page59/Track 086.mp3",
    video: "",

    exerciseKey: "img/FriendsPlus/Page9/Key/E2answerKey.png",
    component: T6,
    // maxLength: 5,
    textAlign: "center",
    textareaStyle: {
      width: 700,
      paddingTop: 5,
    },
    stylesTextInput: { background: "none", border: "none", fontSize: 20 },
    inputSize: 200,
    titleQuestion: [
      {
        num: "2",
        title: "Write.",
        color: "#2d408e",
        left: 65,
      },
    ],
    questionImage: [],
    // isHello: true,
    questions: [
      {
        title: `
				<div style='position:relative; margin-top: 16px'>
          <img style='height:15cm' src='img/FriendsPlus/Page9/E2/1.jpg'/>
          <div style='position:absolute;top:77px;left:795px'>#</div>
          <div style='position:absolute;top:146px;left:738px'>#</div>
          <div style='position:absolute;top:211px;left:738px'>#</div>
          <div style='position:absolute;top:277px;left:738px'>#</div>
          <div style='position:absolute;top:342px;left:738px'>#</div>
        </div>
        `,
        answer: [
          "does.",
          "No, she doesn't.|No, she does not.",
          "No, he doesn't.|No, he does not.",
          "Yes, he does.",
          "No, they don't.|No, they do not.",
        ],
      },
    ],
  },
};

export default json;
