import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 12",
    id: "WB5-2024-U12-P83-E1",
    // audio: "Audios/30-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page83/Key/E1answerKey.png",
    // stylesTextInput: {
    //   width: 300,
    // },
    titleQuestion: [
      {
        num: "1",
        title: "Match the sentences with the same pattern.",
        color: "#2d408e",
        left: -30,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        lineToNotCenter: true,
        lineDirection: "row",
        multipleLine: false,
        sort: true,
        colorLineTo: "#40c7f4",
        widthLineto: 2,
        boxMatch: [
          {
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "calc(100% - 10px)",
              // background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              // background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "calc(100% - 10px)",
              // background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              // background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "calc(100% - 10px)",
              // background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              // border: "1px solid #2eb6e1",
              transform: "none",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              // background: "white",
            },
          }, // 5
        ],
        answers: ["2-5", "1-4"],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          textAlign: "center",
          width: 30,
          background: "none",
          fontSize: 22,
          maxLength: 1,
          border: "none",
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",

          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          // borderRadius: "50px",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [],
        answers: [],
        initialValue: [],
      },
      Layout: `
      <div style='width: 25cm; height: 10cm; position: relative'>
        <div style='position: absolute; top: 0; left: 30px'>
          <div style='position: relative'>
            <img src='img/FriendsPlus/Page83/E1/1.jpg' style='height: 1.6cm'/>
          </div>
        </div>
        <div style='position: absolute; top: 60px; right: 70px'>
          <div style='position: relative'>
            <img src='img/FriendsPlus/Page83/E1/2.jpg' style='height: 1.3cm'/>
            <input id=1 type='boxMatch'/>
          </div>
        </div>
        <div style='position: absolute; top: 120px; left: 0'>
          <div style='position: relative'>
            <img src='img/FriendsPlus/Page83/E1/3.jpg' style='height: 1.3cm'/>
            <input id=2 type='boxMatch'/>
          </div>
        </div>
        <div style='position: absolute; top: 180px; right: 0'>
          <div style='position: relative'>
            <img src='img/FriendsPlus/Page83/E1/4.jpg' style='height: 1.3cm'/>
          </div>
        </div>
        <div style='position: absolute; top: 240px; left: 100px'>
          <div style='position: relative'>
            <img src='img/FriendsPlus/Page83/E1/5.jpg' style='height: 1.3cm'/>
            <input id=4 type='boxMatch'/>
          </div>
        </div>
        <div style='position: absolute; top: 300px; right: 50px'>
          <div style='position: relative'>
            <img src='img/FriendsPlus/Page83/E1/6.jpg' style='height: 1.3cm'/>
            <input id=5 type='boxMatch'/>
          </div>
        </div>
      </div>
      <div style="border-top: 2px solid rgb(64, 199, 244); position: absolute; top: 50.4688px; left: 192px; width: 593.914px; z-index: 1; transform: rotate(12.5973deg); transform-origin: 0px 0px;"></div>
      `,
    },
  },
  2: {
    unit: "Unit 12",
    id: "WB5-2024-U10-P83-E2",
    exerciseKey: "img/FriendsPlus/Page83/Key/E2answerKey.png",
    audio: "Audios/34-audio.mp3",
    video: "",
    component: Circle_Write,
    // recorder: true,
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen to the chant and underline the words that are stronger. <span style='color: white'><headphone name='&nbsp 34' typeimg=sound src='Audios/34-tieude.mp3'></headphone></span>",
        color: "#203c8f",
        left: 50,
      },
    ],
    styleContent: {
      width: "90%",
      marginLeft: 50,
    },
    question: {
      leftContent: 50,
      DrawLines: {
        multipleLine: false,
        colorLineTo: "gray",
        // newPointDot: true,
        // path: true,
        // notOverlapse: true,
        circlePadding: "5 1",
        margin: "5px 2px",
        // circleStringArray: ["modelsalivescreambonesdinosaursroarscarydead"],
        boxMatch: [],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        // circleInput: true,
        inputStyle: {
          width: 457,
          paddingBottom: 3,
          fontSize: 23,
          borderBottom: "none",
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        newSplit: true,
        initialWordStyle: {
          padding: "1px 3px",
          border: "none",
          background: "none",
          // fontSize: 23,
          // fontWeight: "bold",
        },
        selectWordStyle: {
          color: "black",
          borderBottom: "2px solid #00aeef",
        },
        // limitSelect: 1,
        listWords: [
          "Where are you going to go?",
          "What are you going to play?",
          "What are you going to see?",
          "What are you going to say?",
          "Are you going to swim?",
          "Are you going to dance?",
          "Are you going to run?",
          "Are you going to jump?",
          "Are you going to have fun?",
        ],
        answers: [
          "0-0",
          "0-3",
          "0-5",
          "1-0",
          "1-3",
          "1-5",
          "2-0",
          "2-3",
          "2-5",
          "3-0",
          "3-3",
          "3-5",
          "4-0",
          "4-2",
          "4-4",
          "5-0",
          "5-2",
          "5-4",
          "6-0",
          "6-2",
          "6-4",
          "7-0",
          "7-2",
          "7-4",
          "8-0",
          "8-2",
          "8-5",
        ],
        initialValue: ["0-0", "0-3", "0-5"],
      },
      Layout: `
      <div style='width: 25cm; background-color: rgb(232, 246, 253); border-radius: 10px; display: flex; justify-content: space-between; padding: 15px 90px 15px 20px'>
        <div style='display: flex; flex-direction: column; gap: 5px'>
          <span><input id=0 type='Circle'/></span>
          <span><input id=1 type='Circle'/></span>
          <span><input id=2 type='Circle'/></span>
          <span><input id=3 type='Circle'/></span>
          <span></span>
          <span><input id=4 type='Circle'/></span>
          <span><input id=5 type='Circle'/></span>
          <span><input id=6 type='Circle'/></span>
          <span><input id=7 type='Circle'/></span>
          <span><input id=8 type='Circle'/></span>
        </div>
        <img src='img/FriendsPlus/Page83/E2/1.jpg' style='height: 10cm'/>
      </div>
        
        `,
    },
  },
  3: {
    unit: "Unit 12",
    id: "WB5-2024-U12-P83-E3",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page83/Key/E3answerKey.png",
    video: "",
    component: T6,
    titleQuestion: [
      {
        num: "3",
        title: "Order the words. Say the sentences.",
        color: "#2d408e",
        left: 50,
        // prefix:[{text:"hello",icon:"fas fa-pen"}]
      },
    ],
    // textAlign: "center",
    inputSize: 500,
    styleHint: {
      // border: "none",
      // justifyContent: "center",
      display: "inline-flex",
      gap: "5px",
      paddingInline: 0,
      // borderColor: "#2bbbf1",
      // color: "#57585a",
      styleElementHintBox: {
        background: "#c6eafa",
        padding: "2px 5px",
        borderRadius: "5px",
        margin: 0,
      },
    },
    hintBox: [
      {
        src: ["is", "going", "Where", "to", "she", "go", "?"],
        width: "max-content",
        inline: true,
      },
      {
        src: ["you", "watch", "TV", "going", "Are", "to", "?"],
        width: "max-content",
        inline: true,
      },
      {
        src: ["they", "are", "eat", "going", "What", "to", "?"],
        width: "max-content",
        inline: true,
      },
      {
        src: ["he", "to", "going", "play", "soccer", "Is", "?"],
        width: "max-content",
        inline: true,
      },
    ],
    stylesTextInput: {
      marginTop: 15,
      background: "none",
    },
    // character: "",
    // hai , ba , bốn , năm
    checkUppercase: true,
    questions: [
      {
        title: `
        <div style='width: 27cm; display:grid; grid-template-columns: 5fr 7fr; grid-gap: 5px 20px'>
          <span><b>1. </b><hintbox id=0></hintbox> </span> <u style='margin-top: 15px; color:gray; text-underline-offset: 5px'>&nbsp&nbspWhere is she going to go?&nbsp</u>
          <span><b>2. </b><hintbox id=1></hintbox> </span>#
          <span><b>3. </b><hintbox id=2></hintbox> </span>#
          <span><b>4. </b><hintbox id=3></hintbox> </span>#
        </div>
        `,
        answer: [
          "Are you going to watch TV?",
          "What are they going to eat?",
          "Is he going to play soccer?",
        ],
      },
    ],
  },
};

export default json;
